import _ from 'lodash';
import { getModeratorTypesByInterviewType } from 'helpers/interview/getModeratorTypesByInterviewType';

// sync interviewTypesValues with NewInterviewCreationForm/constants.js
export const interviewTypesValues = {
    videoInterview: 'video-interview',
    videoIdi: 'video-idi',
    vfgd: 'vfgd',
    offlineFocusGroup: 'offline-focus-group',
    offlineInterview: 'offline-interview',
    offlineHome: 'offline-home',
    onlineExternal: 'online-external',
    online: 'online',
};

export const INTERVIEW_FORMATS_OPTIONS = [
    { label: 'interview:text.online', value: 'online' },
    { label: 'interview:text.offline', value: 'offline' },
    { label: 'interview:text.allFormats', value: '' },
];

export const INTERVIEW_FORMATS_TO_TYPES = {
    online: [
        { label: 'interviewTypes.video-idi', value: interviewTypesValues.videoIdi },
        { label: 'interviewTypes.vfgd', value: interviewTypesValues.vfgd },
        { label: 'interviewTypes.online', value: interviewTypesValues.online },
    ],
    offline: [
        { label: 'interviewTypes.offline-focus-group', value: interviewTypesValues.offlineFocusGroup },
        { label: 'interviewTypes.offline-interview', value: interviewTypesValues.offlineInterview },
        { label: 'interviewTypes.offline-home', value: interviewTypesValues.offlineHome },
    ],
};

export const INTERVIEW_TYPES_OPTIONS = [
    { label: 'interviewTypes.video-interview', value: interviewTypesValues.videoInterview },
    { label: 'interviewTypes.video-idi', value: interviewTypesValues.videoIdi },
    { label: 'interviewTypes.vfgd', value: interviewTypesValues.vfgd },
    { label: 'interviewTypes.offline-focus-group', value: interviewTypesValues.offlineFocusGroup },
    { label: 'interviewTypes.offline-interview', value: interviewTypesValues.offlineInterview },
    { label: 'interviewTypes.offline-home', value: interviewTypesValues.offlineHome },
    { label: 'interviewTypes.online', value: interviewTypesValues.online },
];

export const EXTRA_INTERVIEW_TYPES_OPTIONS = [
    { label: 'extraInterviewTypes.video-idi', value: interviewTypesValues.videoIdi },
    { label: 'extraInterviewTypes.vfgd', value: interviewTypesValues.vfgd },
    { label: 'extraInterviewTypes.offline-focus-group', value: interviewTypesValues.offlineFocusGroup },
    { label: 'extraInterviewTypes.offline-interview', value: interviewTypesValues.offlineInterview },
    { label: 'extraInterviewTypes.offline-home', value: interviewTypesValues.offlineHome },
    { label: 'extraInterviewTypes.online', value: interviewTypesValues.online },
];

export const isNoExtraRespondents = (type) =>
    [INTERVIEW_TYPES_OPTIONS[0].value, INTERVIEW_TYPES_OPTIONS[3].value, INTERVIEW_TYPES_OPTIONS[4].value].includes(
        type,
    );
export const isOnlinePlatform = (type) => type === interviewTypesValues.online;
export const isOnlineFocusGroup = (type) => type === INTERVIEW_TYPES_OPTIONS[1].value;
export const isOfflineFocusGroup = (type) => type === INTERVIEW_TYPES_OPTIONS[2].value;
export const isVideoInterview = (type) =>
    [interviewTypesValues.videoIdi, interviewTypesValues.vfgd, interviewTypesValues.videoInterview].includes(type);
export const isVideoExternal = (type) => type === interviewTypesValues.onlineExternal;
export const isOfflineRemoteType = (type) =>
    [interviewTypesValues.offlineFocusGroup, interviewTypesValues.offlineInterview].includes(type);
export const isHomeVisit = (type) => type === interviewTypesValues.offlineHome;
export const isOfflineOneToOne = (type) => type === interviewTypesValues.offlineInterview;
export const isOfflineInterview = (type) =>
    [
        interviewTypesValues.offlineHome,
        interviewTypesValues.offlineFocusGroup,
        interviewTypesValues.offlineInterview,
    ].includes(type);

export const MAX_RESPONDENTS = {
    [interviewTypesValues.videoIdi]: 1,
    [interviewTypesValues.vfgd]: 9,
    [interviewTypesValues.offlineFocusGroup]: Infinity,
    [interviewTypesValues.offlineInterview]: 1,
    [interviewTypesValues.online]: Infinity,
    [interviewTypesValues.offlineHome]: 1,
};

export const INTERVIEW_TYPES_LABELS = INTERVIEW_TYPES_OPTIONS.reduce(
    (acc, type) => ({ ...acc, [type.value]: type.label }),
    {},
);

export const EXTRA_INTERVIEW_TYPES_LABELS = EXTRA_INTERVIEW_TYPES_OPTIONS.reduce(
    (acc, type) => ({ ...acc, [type.value]: type.label }),
    {},
);
export const INTERVIEW_TYPES_FILTERS = [{ label: 'All types', value: '' }, ...INTERVIEW_TYPES_OPTIONS];

export const ONLINE_INTERVIEWS = [
    INTERVIEW_TYPES_OPTIONS[0].value,
    INTERVIEW_TYPES_OPTIONS[1].value,
    INTERVIEW_TYPES_OPTIONS[5].value,
];

export const OFFLINE_INTERVIEWS = [
    INTERVIEW_TYPES_OPTIONS[2].value,
    INTERVIEW_TYPES_OPTIONS[3].value,
    INTERVIEW_TYPES_OPTIONS[4].value,
];

export const INTERVIEWS_CONFIRMATION_TEMPLATES = {
    [interviewTypesValues.offlineFocusGroup]: 'text.offlineTemplateDefault',
    [interviewTypesValues.offlineInterview]: 'text.offlineTemplateDefault',
    [interviewTypesValues.offlineHome]: 'text.offlineTemplateHomeOffice',
    [interviewTypesValues.vfgd]: 'text.videoTemplateVocalViewsPlatform',
    [interviewTypesValues.onlineExternal]: 'text.videoTemplateOtherPlatform',
    [interviewTypesValues.online]: 'text.onlinePlatformTemplate',
    [interviewTypesValues.videoIdi]: 'text.videoTemplateVocalViewsPlatform',
};

export const INTERVIEW_DURATIONS = _.range(15, 300 + 1, 15).map((duration) => ({
    label: duration + ' min',
    value: duration,
}));
export const INTERVIEW_DURATIONS_DAYS = (t, maxDays = 90) =>
    _.range(1, maxDays + 1, 1).map((duration) => ({
        label: duration + ' ' + t(duration > 1 ? 'common.days' : 'common.day'),
        value: duration,
    }));

export const MARKET_SECTOR_TYPES = {
    consumer: 'consumer',
    medical: 'medical',
    business: 'business',
};

export const MARKET_SECTOR = [
    { value: MARKET_SECTOR_TYPES.consumer, label: 'Consumer' },
    { value: MARKET_SECTOR_TYPES.business, label: 'Business' },
    { value: MARKET_SECTOR_TYPES.medical, label: 'Medical' },
];

export function isConsumer(type) {
    return type === MARKET_SECTOR_TYPES.consumer;
}

export function isBusiness(type) {
    return type === MARKET_SECTOR_TYPES.business;
}

export function isMedical(type) {
    return type === MARKET_SECTOR_TYPES.medical;
}

export const INTERVIEW_INSIDE_COMPANY_STATUS_LIST = [
    {
        label: 'interview:tabs.drafts',
        value: 'draft',
    },
    {
        label: 'interview:tabs.paid',
        value: 'paid',
    },
    {
        label: 'interview:tabs.past',
        value: 'past',
    },
    {
        label: 'interview:tabs.cancelled',
        value: 'cancelled',
    },
];

export const INTERVIEW_ADMIN_STATUS_LIST = [
    {
        label: 'admin:interviewDetail.text.live',
        value: 'active',
    },
    {
        label: 'interview:tabs.drafts',
        value: 'draft',
    },
    {
        label: 'interview:tabs.paid',
        value: 'paid',
    },
    {
        label: 'interview:tabs.past',
        value: 'past',
    },
    {
        label: 'interview:tabs.cancelled',
        value: 'cancelled',
    },
];

export const INTERVIEW_DETAIL_TABS = [
    { label: 'interview:tabs.interview', value: 'interview' },
    { label: 'interview:tabs.resources', value: 'resources' },
];

export const INTERVIEW_OUTSIDE_COMPANY_STATUS_LIST = [
    {
        label: 'interview:tabs.invitation',
        value: 'invitation',
    },
    {
        label: 'interview:tabs.application',
        value: 'application',
    },
    {
        label: 'interview:tabs.upcoming',
        value: 'hired',
    },
    {
        label: 'interview:tabs.past',
        value: 'past',
    },
    {
        label: 'interview:tabs.cancelled',
        value: 'cancelled',
    },
];

export const REPORT_TYPES = (t) => [
    {
        value: 'full',
        label: t('interview:text.reports.full'),
    },
    {
        value: 'summary',
        label: t('interview:text.reports.summary'),
    },
];

export const MODERATOR_TYPES = (t, interviewType) => {
    const types = getModeratorTypesByInterviewType(interviewType);
    return types.map((tp) => ({
        value: tp,
        label: t(`interview:form.moderatorType.${tp}.title`),
    }));
};

export const MAX_MB_FILE_SIZE = 100;

export const MAX_FILE_SIZE = MAX_MB_FILE_SIZE * 1024 * 1024;

export const MAX_COVER_MB_FILE_SIZE = 20;

export const MAX_COVER_SIZE = MAX_COVER_MB_FILE_SIZE * 1024 * 1024;

export const MAX_EMAIL_ATTACHMENTS_MB_FILE_SIZE = 30;

export const MAX_EMAIL_ATTACHMENTS_FILE_SIZE = MAX_EMAIL_ATTACHMENTS_MB_FILE_SIZE * 1024 * 1024;

export const MAX_EMAIL_ATTACHMENTS_FILE_COUNT = 10;
