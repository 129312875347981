import INTERVIEWS_STATUSES from './InterviewStatusInformation.js';

export const AdminUsersTabsRoles = [
    {
        value: 'CompanyAdmin',
        label: 'Clients',
        columns: {
            email: 'Email',
            name: 'Name',
            accountType: 'Account type',
            registrationDate: 'Registration Date',
            status: 'Status',
        },
    },
    {
        value: 'Moderator',
        label: 'Moderators',
        columns: {
            email: 'Email',
            name: 'Name',
            accountType: 'Account type',
            registrationDate: 'Registration Date',
            status: 'Status',
        },
    },
    {
        value: 'Translator',
        label: 'Interpreters',
        columns: {
            email: 'Email',
            name: 'Name',
            accountType: 'Account type',
            registrationDate: 'Registration Date',
            status: 'Status',
        },
    },
    {
        value: 'Respondent',
        label: 'Respondents',
        columns: {
            email: 'Email',
            name: 'Name',
            accountType: 'Account type',
            registrationDate: 'Registration Date',
            status: 'Status',
        },
    },
    {
        value: 'Admin',
        label: 'Admins',
        columns: {
            email: 'Email',
            name: 'Name',
            accountType: 'Account type',
            registrationDate: 'Registration Date',
            status: 'Status',
        },
    },
];

export const AdminUsersTableRowNumber = 10;

export const AdminProjectsTabs = [
    {
        label: 'Ongoing',
        value: 'not-completed',
    },
    {
        label: 'Completed',
        value: 'completed',
    },
];

export const AdminProjectsColumns = {
    code: 'Code',
    creator: 'Creator',
    projectName: 'Project name',
    description: 'Description',
};

export const AdminProjectsTableRowNumber = 10;

export const AdminInterviewsTabs = [
    {
        label: 'Live',
        value: INTERVIEWS_STATUSES.started,
    },
    {
        label: 'Drafts',
        value: INTERVIEWS_STATUSES.draft,
    },
    {
        label: 'Published',
        value: INTERVIEWS_STATUSES.published,
    },
    {
        label: 'Paid',
        value: INTERVIEWS_STATUSES.paid,
    },
    {
        label: 'Past',
        value: INTERVIEWS_STATUSES.past,
    },
    {
        label: 'Cancelled',
        value: INTERVIEWS_STATUSES.cancelled,
    },
];

export const AdminInterviewsColumns = {
    title: 'Interview title',
    project: 'Project',
    type: 'Type',
    visibility: 'Visibility',
    creator: 'Creator',
    date: 'Date',
};

export const LiveInterviewsColumns = {
    title: 'Interview title',
    project: 'Project',
    type: 'Type',
    visibility: 'Visibility',
    creator: 'Creator',
    start: 'Start date',
    end: 'End date',
};

export const AdminInterviewsTableRowNumber = 10;

export const transcriptsHeader = {
    interviewTitle: 'Interview title',
    creator: 'Creator',
    dateAndTime: 'Session date and time',
    recordings: 'Recordings',
    interviewLanguage: 'Language',
    status: 'Status',
};

export const translationHeader = {
    interviewTitle: 'Interview title',
    creator: 'Creator',
    dateAndTime: 'Session date and time',
    recordings: 'Recordings',
    languageFrom: 'Language from',
    languageTo: 'Language to',
    status: 'Status',
};

export const AdminOrdersColumns = {
    humanTranscripts: transcriptsHeader,
    humanTranslation: translationHeader,
    machineTranscripts: transcriptsHeader,
};
