import AdminPageLayout from "../../AdminPageLayout/AdminPageLayout";
import Skeleton from "../../Skeleton";
import styles from "./AdminProjectsPage.module.scss";

const AdminProjectsPage = () => {
    return (
        <AdminPageLayout>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Skeleton style={{ height: '2.5rem', width: '8rem' }} />
                    <div />
                </div>
                <Skeleton style={{ height: '2rem', width: '5.6rem' }} containerClassName={styles.tabs} count={2} />
                <div className={styles.filters}>
                    <Skeleton containerClassName={styles.filtersLeft} style={{ height: '3rem', width: '30rem' }} />
                </div>
                <Skeleton count={11} containerClassName={styles.rows} className={styles.rowItem} />
            </div>
        </AdminPageLayout>
    )
}

export default AdminProjectsPage;