import React from 'react';
import style from './Profile.module.scss';

import Skeleton from 'components/common/Skeleton/Skeleton';
import classNames from 'classnames';

const ProfileCard = () => {
    return (
        <div className={style.card}>
            <Skeleton circle className={style.profileCardAvatar} />
            <Skeleton className={style.profileCardName} />
            <Skeleton className={style.profileCardRole} />

            <div className={style.cardInfoRow}>
                <Skeleton className={style.item} style={{width: '6.25rem'}} />
                <Skeleton className={style.item} style={{width: '6.25rem'}} />
            </div>

            <div className={style.cardInfoRow}>
                <Skeleton className={style.item} style={{width: '3.5625rem'}} />
                <Skeleton className={style.item} style={{width: '7.375rem'}} />
            </div>

            <div className={style.cardInfoRow}>
                <Skeleton className={style.item} style={{width: '4.75rem'}} />
                <Skeleton className={style.item} style={{width: '8.5rem'}} />
            </div>

            <Skeleton containerClassName={style.btn} className={style.inner} />
            <Skeleton containerClassName={style.btn} className={style.inner} />
        </div>
    );
};

const ProfileInfo = () => {
    return (
        <div className={style.info}>
            <div className={style.header}>
                <div className={style.tabs}>
                    <Skeleton className={style.tab} />
                    <Skeleton className={style.tab} />
                    <Skeleton className={style.tab} />
                    <Skeleton className={style.tab} />
                    <Skeleton className={style.tab} />
                </div>
                <Skeleton className={style.date} />
            </div>
            <div className={classNames(style.section, style.first)}>
                <Skeleton className={style.title} />
                <div className={style.data}>
                    <Skeleton className={style.value} />
                    <Skeleton className={style.value} />
                    <Skeleton className={classNames(style.value, style.last)} />
                </div>
            </div>
            <div className={style.section}>
                <Skeleton className={style.title} />
                <div className={style.data}>
                    <Skeleton className={style.value} />
                    <Skeleton className={style.value} />
                    <Skeleton className={style.value} />
                    <Skeleton className={style.value} />
                    <Skeleton className={style.value} />
                    <Skeleton className={classNames(style.value, style.last)} />
                </div>
            </div>
        </div>
    );
};

export default function Profile() {
    return (
        <div className={style.profilePage}>
            <ProfileCard />
            <ProfileInfo />
        </div>
    );
}
