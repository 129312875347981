import { COUNTRIES } from 'components/common/listConstants';

const sortByMostPopular = () => {
    const mostPopularCountries = ['GB', 'US', 'FR', 'DE', 'ES', 'IT'];
    const result = [...COUNTRIES];

    for (let i = mostPopularCountries.length - 1; i >= 0; i--) {
        const countryCode = mostPopularCountries[i];
        const idx = result.findIndex((i) => i.value === countryCode);
        if (idx !== -1) {
            const [country] = result.splice(idx, 1);
            result.unshift(country);
        }
    }
    return result;
};

const InterviewTargetMarketInformation = sortByMostPopular();
export default InterviewTargetMarketInformation;
