import {NO_COMPANY_STATUSES} from 'constants/Experience';
import {isCompanyAdmin, isRespondent} from 'constants/UserRoles';
import {interviewTypesValues} from 'components/Interview/interviewConstants';

export const MIN_COMPLETE_FOR_SKIP = 10;

export const calculateCompleteProfile = user => {
    if (!user) return 0;
    let counter = 0;
    let totalCount = 0;
    const stepFields = isRespondent(user.role)
        ? STEPS_FIELDS[user.role]
        : user.accountType
        ? STEPS_FIELDS[user.role][user.accountType]
        : {};

    Object.keys(stepFields).forEach(formKey => {
        stepFields[formKey].forEach(field => {
            totalCount++;
            const isCompanyDataSteps = formKey === 'aboutCompany' || formKey === 'companyAddress';
            const isHourlyRateFields = Object.values(interviewTypesValues).includes(field);
            const isTranslationField = field === 'translateLanguages';
            if (isCompanyDataSteps) {
                if (user.ownCompany[field]) counter++;
            } else if (isHourlyRateFields) {
                if (user.hourlyRate && (user.hourlyRate[field] || user.hourlyRate[field] === 0)) counter++;
            } else if (isTranslationField) {
                if (user[field] && user[field].length) counter++;
            } else if (user[field]) counter++;
        });
    });
    return Math.floor((counter / totalCount) * 100);
};

export const calculateAdditionalCompleteProfile = user => {
    if (!user) return 0;
    let counter = 0;
    let totalCount = 0;
    if (!isRespondent(user.role)) {
        delete ADDITIONAL_STEPS_FIELDS.consumer;
        if (isCompanyAdmin(user.role)) delete ADDITIONAL_STEPS_FIELDS.experience;
    }
    Object.keys(ADDITIONAL_STEPS_FIELDS).forEach(formKey => {
        ADDITIONAL_STEPS_FIELDS[formKey].forEach(field => {
            totalCount++;
            const isCompanyFields = field === 'company' || field === 'occupation';
            const noCompanyEmploymentUser = NO_COMPANY_STATUSES.includes(user.employmentStatus);
            const isArrayField = field === 'newsChannels';

            if (isCompanyFields && noCompanyEmploymentUser) counter++;
            else if (isArrayField) {
                if (user[field] && user[field].length) counter++;
            } else if (user[field]) counter++;
        });
    });
    return Math.floor((counter / totalCount) * 100);
};

export const isSkipQuestionnairePossible = (role, complete) => {
    return complete >= MIN_COMPLETE_FOR_SKIP;
};

export const STEPS_FIELDS = {
    Respondent: {
        personalBasics: ['firstName', 'lastName', 'phone', 'birthday', 'gender', 'nativeCountry', 'languages'],
        personalAddress: ['house', 'street', 'state', 'country', 'city', 'postalCode', 'address'],
        paymentDetails: ['currency', 'costPerHour'],
    },
    CompanyAdmin: {
        individual: {
            accountType: ['accountType'],
            personalBasics: ['firstName', 'lastName', 'phone', 'birthday', 'nativeCountry', 'workIndustry'],
            personalAddress: ['house', 'street', 'state', 'country', 'city', 'postalCode', 'address'],
        },
        company: {
            accountType: ['accountType'],
            aboutCompany: ['name', 'type', 'companyIndustry'],
            companyAddress: ['house', 'street', 'state', 'country', 'city', 'postalCode', 'address'],
            companyRepresentative: ['firstName', 'lastName', 'phone', 'birthday', 'nativeCountry', 'languages'],
        },
    },
    Moderator: {
        individual: {
            accountType: ['accountType'],
            personalBasics: [
                'firstName',
                'lastName',
                'phone',
                'birthday',
                'nativeCountry',
                'languages',
                'workExperience',
            ],
            address: ['house', 'street', 'state', 'country', 'city', 'postalCode', 'address'],
            paymentDetails: [
                'currency',
                'offline-home',
                'vfgd',
                'offline-interview',
                'video-idi',
                'offline-focus-group',
                'online',
                'full',
                'summary',
            ],
        },
        company: {
            accountType: ['accountType'],
            aboutCompany: ['name', 'type'],
            companyAddress: ['house', 'street', 'state', 'country', 'city', 'postalCode', 'address'],
            companyRepresentative: [
                'firstName',
                'lastName',
                'phone',
                'birthday',
                'nativeCountry',
                'languages',
                'workExperience',
            ],
            paymentDetails: [
                'currency',
                'offline-home',
                'vfgd',
                'offline-interview',
                'video-idi',
                'offline-focus-group',
                'online',
                'full',
                'summary',
            ],
        },
    },
    Translator: {
        individual: {
            accountType: ['accountType'],
            personalBasics: ['firstName', 'lastName', 'phone', 'birthday', 'nativeCountry', 'workExperience'],
            address: ['house', 'street', 'state', 'country', 'city', 'postalCode', 'address'],
            paymentDetails: [
                'currency',
                'offline-home',
                'vfgd',
                'offline-interview',
                'video-idi',
                'offline-focus-group',
            ],
            translation: ['translateLanguages'],
        },
        company: {
            accountType: ['accountType'],
            aboutCompany: ['name', 'type'],
            companyAddress: ['house', 'street', 'state', 'country', 'city', 'postalCode', 'address'],
            companyRepresentative: ['firstName', 'lastName', 'phone', 'birthday', 'nativeCountry', 'workExperience'],
            paymentDetails: [
                'currency',
                'offline-home',
                'vfgd',
                'offline-interview',
                'video-idi',
                'offline-focus-group',
            ],
            translation: ['translateLanguages'],
        },
    },
};

export const ADDITIONAL_STEPS_FIELDS = {
    miscellaneous: ['avatar', 'relationshipStatus', 'about'],
    experience: ['employmentStatus', 'education', 'workExperience', 'company', 'occupation'],
    consumer: [
        'smartphoneBrand',
        'smartphoneModel',
        'smartphoneColor',
        'tvBrand',
        'tvModel',
        'cars',
        'carModel',
        'brands',
        'tvShows',
        'newsChannels',
    ],
};
