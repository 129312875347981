import {debounce, noop} from 'lodash';
import React, {createContext, Component} from 'react';
import makeRequest from 'makeRequest';
import uploadFile from 'uploadFile';
import {createRequestHelper} from 'helpers/requestHelper';

import INTERVIEW_STATUSES from 'constants/InterviewStatusInformation';
import INTERVIEW_CLIENTS_FILTERS from 'constants/InterviewClientInformation';
import INTERVIEW_PROJECTS_FILTERS from 'constants/InterviewProjectInformation';
import {INTERVIEW_VISIBILITIES_FILTERS} from 'constants/InterviewVisibilityInformation';
import INTERVIEW_TARGET_MARKETS_FILTERS from 'constants/InterviewTargetMarketInformation';
import {INTERVIEW_TYPES_FILTERS} from 'components/Interview/interviewConstants';
import {UserContextConsumer} from 'context/UserContext';
import BackendSocketController from 'controllers/BackendSocket';
import {withConsumers} from 'helpers/contexts';
import {InterviewStorageContextConsumer} from 'context/Interview/storage/InterviewStorageContext';
import {getToken} from 'helpers/getToken';

export const InterviewContext = createContext({});

const replyToInterviewRequestHelper = createRequestHelper('replyToInterview');
const fetchInterviewersRequestHelper = createRequestHelper('fetchInterviewers');
const createInterviewRequestHelper = createRequestHelper('createInterview');
const fetchBrowseInterviewsRequestHelper = createRequestHelper('browseInterviews');
const adminSearchRequestHelper = createRequestHelper('adminSearch');
const interviewersRequestHelper = createRequestHelper('interviewers');
const hireRequestHelper = createRequestHelper('hire');
const cancelInterviewRequestHelper = createRequestHelper('cancelInterview');
const priceRequestHelper = createRequestHelper('price');
const releaseRequestHelper = createRequestHelper('release');
const createCampaignRequestHelper = createRequestHelper('createCampaign');
const getProjectFilterOptionsRequestHelper = createRequestHelper('getProjectFilterOptions');
const releaseAllRequestHelper = createRequestHelper('releaseAll');
const createExternalInterviewRequestHelper = createRequestHelper('createExternalInterview');
const fetchInterviewForDashboardHelper = createRequestHelper('fetchInterviewForDashboard');
const fetchUpcomingInterviewForDashboardHelper = createRequestHelper(
    'fetchUpcomingInterviewForDashboard',
    upcomingDashboardInterviews => ({upcomingDashboardInterviews})
);
const searchForDashboardHelper = createRequestHelper('searchForDashboard');

const LIST_STATE_COMMANDS = {
    DELETE: 'delete',
    ADD: 'add',
};

const defaultInviteResourcesHelperState = {
    inviteToInterviewProcessingIds: [],
    inviteToInterviewResultIds: [],
    inviteToInterviewErrorIds: [],
    declineManyResultIds: [],
    hireManyResultIds: [],
};

class InterviewContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...replyToInterviewRequestHelper.initialState,
            ...fetchInterviewersRequestHelper.initialState,
            ...createInterviewRequestHelper.initialState,
            ...hireRequestHelper.initialState,
            ...cancelInterviewRequestHelper.initialState,
            ...releaseRequestHelper.initialState,
            ...priceRequestHelper.initialState,
            ...getProjectFilterOptionsRequestHelper.initialState,
            ...createExternalInterviewRequestHelper.initialState,
            ...fetchInterviewForDashboardHelper.initialState,
            ...fetchUpcomingInterviewForDashboardHelper.initialState,
            ...searchForDashboardHelper.initialState,
            ...fetchBrowseInterviewsRequestHelper.initialState,
            interviewers: {list: [], totalCount: 0},
            dashboardDate: new Date(),
            dashboardInterviews: {},
            upcomingDashboardInterviews: {list: [], totalCount: 0},
            price: null,
            adminSearch: {
                filters: {
                    clients: INTERVIEW_CLIENTS_FILTERS,
                    projects: INTERVIEW_PROJECTS_FILTERS,
                },
                page: 0,
                status: INTERVIEW_STATUSES.draft,
                query: '',
                client: INTERVIEW_CLIENTS_FILTERS[0].value,
                project: INTERVIEW_PROJECTS_FILTERS[0].value,
                visibility: INTERVIEW_VISIBILITIES_FILTERS[0].value,
                market: INTERVIEW_TARGET_MARKETS_FILTERS[0].value,
                type: INTERVIEW_TYPES_FILTERS[0].value,
            },
            isAllowedToPay: null,
            interviewersFilter: null,
            pageURLToReturn: null,
            editInterviewURLToReturn: null,
            lastOpenedTab: null,
            recordingsProcessing: [],
            confirmJoinModal: {
                isOpened: false,
                interviewId: null,
            },
            selectedResources: [],
            selectedResourcesToRelease: [],
            ...defaultInviteResourcesHelperState,
            interviewToUpdateInCommonList: {
                counter: 0,
                interview: null,
            },
            lockInterviewsFetch: false,
        };

        BackendSocketController.on(
            'recording::processing',
            this.updateRecordingProcessingSocketHandler.bind(this, false)
        );
        BackendSocketController.on('recording::done', this.updateRecordingProcessingSocketHandler.bind(this, true));
        BackendSocketController.on('user::editProfile', this.editProfileSocketHandler);
        window.ic = this;
    }

    fetchInterviewForDashboard = async ({key}) => {
        const path = 'interviews/fetchInterviewForDashboard';
        const authToken = getToken();
        this.setState(fetchUpcomingInterviewForDashboardHelper.processing());
        try {
            const {totalCount, list} = await makeRequest({path, data: {key, authToken}});
            const {dashboardInterviews} = this.state;
            dashboardInterviews[key] = {totalCount, list};
            this.setState({
                ...fetchInterviewForDashboardHelper.result(true),
                dashboardInterviews,
            });
        } catch (error) {
            fetchInterviewForDashboardHelper.error(error);
        }
    };

    fetchUpcomingInterviewForDashboard = async () => {
        const path = 'interviews/fetchUpcomingInterviewForDashboard';
        const authToken = getToken();
        this.setState(fetchUpcomingInterviewForDashboardHelper.processing());
        try {
            const {status, result, error} = await makeRequest({path, data: {authToken}});
            if (!status) throw Error(error);
            this.setState({
                ...fetchUpcomingInterviewForDashboardHelper.result(result),
                upcomingInterviewsFetched: true,
            });
        } catch (error) {
            fetchUpcomingInterviewForDashboardHelper.error(error);
        }
    };

    searchForDashboard = async query => {
        if (!query) {
            this.clearSearchForDashboard();
            return;
        }
        const path = 'interviews/searchForDashboard';
        const authToken = getToken();
        this.setState(searchForDashboardHelper.processing());
        try {
            const result = await makeRequest({path, data: {query, authToken}});
            this.setState(searchForDashboardHelper.result({...result, query}));
        } catch (error) {
            searchForDashboardHelper.error(error && error.message);
        }
    };

    clearSearchForDashboard = () => this.setState(searchForDashboardHelper.initialState);

    clearExternalInterviewCreated = () => this.setState(createExternalInterviewRequestHelper.clear());

    updateRecordingProcessingSocketHandler = async (isDone, interviewId) => {
        if (isDone) await this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
    };

    editProfileSocketHandler = async userId => {
        const isUserPageOpened = Boolean(window.location.href.match(userId));
        const path = 'getPublicUserInfo';
        const {user} = await makeRequest({path, data: {id: userId, authToken: getToken()}});
        if (isUserPageOpened) {
            this.props.userContext.updatePublicProfile(user);
        } else {
            const interviewers = {
                ...this.state.interviewers,
                list: this.state.interviewers.list.map(this.updateHourlyRateById(userId, user.costPerHour)),
            };
            this.setState({interviewers});
        }
    };

    updateHourlyRateById = (userId, hourlyRate) => res => (res._id === userId ? {...res, hourlyRate} : res);

    changeInterviewListState = (commandType, interview) => {
        const isBrowse = this.__isBrowse();
        const updateField = isBrowse ? 'browseInterviews' : 'interviews';
        const {[updateField]: interviews} = this.state;
        let newList = [];
        let newCount = interviews.totalCount;
        const exist = interviews.list.some(int => int._id === interview._id);
        if (commandType === LIST_STATE_COMMANDS.ADD) {
            newList = exist
                ? interviews.list.map(int => (int._id === interview._id ? interview : int))
                : [...interviews.list, interview];
            if (!exist) newCount++;
        } else if (commandType === LIST_STATE_COMMANDS.DELETE) {
            newList = interviews.list.filter(int => int._id !== interview._id);
            if (exist) newCount--;
        }
        this.setState({[updateField]: {list: newList, totalCount: newCount}});
    };

    releaseMoney = async (interviewId, userId, type) => {
        const path = 'interviews/releaseMoney';
        const data = {
            authToken: getToken(),
            interviewId,
            userId,
            type,
        };
        this.setState(releaseRequestHelper.processing());
        try {
            const result = await makeRequest({path, data});
            if (!result.status) throw Error('Failed');
            await this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
        } catch (error) {
            this.setState(releaseRequestHelper.error(error));
        }
    };

    replyToInterview = async (decision, interviewId, isApplyOrCancelled = false) => {
        const path = 'interviews/reply';
        const normalDecision = ['accepted', 'applied'].includes(decision) ? 'application' : decision;
        const data = {
            authToken: getToken(),
            decision: normalDecision,
            isApplyOrCancelled,
            interviewId,
        };
        this.setState(replyToInterviewRequestHelper.processing());
        try {
            await makeRequest({path, data});
            this.setState(replyToInterviewRequestHelper.clear());
        } catch (error) {
            this.setState(replyToInterviewRequestHelper.error(error));
        }
    };

    resetInviteProcessing = () =>
        this.setState({
            inviteToInterviewProcessingIds: [],
            inviteToInterviewResultIds: [],
            inviteToInterviewErrorIds: [],
        });

    cancelInvitation = async ({userId, interviewId}) => {
        const path = 'interviews/cancelInvitation';
        const data = {
            authToken: getToken(),
            userId,
            interviewId,
        };
        try {
            const wasModified = await makeRequest({path, data});
            if (wasModified) await this.interviewStorageContext.updateInterviewInStorage(interviewId);
        } catch (error) {
            console.log('Error canceling invitation');
        }
    };

    cancelHire = async ({userId, interviewId}) => {
        const path = 'interviews/cancelHire';
        const data = {
            authToken: getToken(),
            userId,
            interviewId,
        };
        try {
            this.setState(hireRequestHelper.processing());
            const {status} = await makeRequest({path, data});
            if (!status) throw Error('Some error on our side. Please, refresh page and try again');
            await this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
            this.setState(hireRequestHelper.result(true));
        } catch (error) {
            this.setState(hireRequestHelper.error(error.message));
        }
    };

    inviteToInterview = async ({userId, interviewId}, cb) => {
        const path = 'interviews/invite';
        const data = {
            authToken: getToken(),
            userId,
            interviewId,
        };
        try {
            this.setState({inviteToInterviewProcessingIds: [...this.state.inviteToInterviewProcessingIds, userId]});
            await makeRequest({path, data});
            this.setState({
                inviteToInterviewProcessingIds: this.state.inviteToInterviewProcessingIds.filter(id => id !== userId),
                inviteToInterviewResultIds: [...this.state.inviteToInterviewResultIds, userId],
                inviteToInterviewErrorIds: this.state.inviteToInterviewErrorIds.filter(id => id !== userId),
            });
            await this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
            cb && cb(null);
        } catch (error) {
            cb && cb(error);
            this.setState({
                inviteToInterviewProcessingIds: this.state.inviteToInterviewProcessingIds.filter(id => id !== userId),
                inviteToInterviewErrorIds: [...this.state.inviteToInterviewErrorIds, userId],
            });
        }
    };

    fetchInterviewers = async (filter, cb) => {
        const path = 'interviews/interviewers';
        const {offset, limit, interviewId, ...restFilter} = filter;
        const data = {
            authToken: getToken(),
            offset: offset || 0,
            limit: limit || 5000,
            filter: {...restFilter},
            interviewType: this.props.interviewStorageContext.interview.type,
            interviewId,
        };

        this.setState({...fetchInterviewersRequestHelper.processing()});
        try {
            const interviewers = await makeRequest({path, data});
            this.setState(fetchInterviewersRequestHelper.result(interviewers));
            this.setState({interviewers});
            cb && cb(interviewers);
        } catch (error) {
            this.setState(fetchInterviewersRequestHelper.error(error));
        }
    };

    clearCreateInterviewResult = () => this.setState(createInterviewRequestHelper.clear());

    fetchAdminSearch = async status => {
        const path = 'interviews/adminSearch';
        const data = {
            authToken: getToken(),
            status,
        };

        this.setState(adminSearchRequestHelper.processing());

        try {
            const result = await makeRequest({path, data});
            this.setState({
                ...adminSearchRequestHelper.result(result),
                adminSearch: {
                    ...this.state.adminSearch,
                    filters: {
                        clients: [...INTERVIEW_CLIENTS_FILTERS, ...this.convertResponseToOptions(result.clients)],
                        projects: [...INTERVIEW_PROJECTS_FILTERS, ...this.convertResponseToOptions(result.projects)],
                    },
                },
            });
        } catch (error) {
            this.setState(adminSearchRequestHelper.error(error));
        }
    };

    convertResponseToOptions = response => response.map(value => ({value: value.id, label: value.name}));

    deleteInterview = async interviewId => {
        const path = 'interviews/delete';
        const data = {
            authToken: getToken(),
            interviewId,
        };

        await makeRequest({path, data});
    };

    hireToInterview = async ({interviewId, userId, clientMessage}) => {
        const path = 'interviews/hire';
        const data = {
            authToken: getToken(),
            interviewId,
            userId,
            clientMessage,
        };
        this.setState(hireRequestHelper.processing());
        try {
            const result = await makeRequest({path, data});
            if (result.status) {
                this.setState(hireRequestHelper.result(result));
                await this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
            } else {
                this.setState(hireRequestHelper.error(result));
            }
        } catch (error) {
            this.setState(hireRequestHelper.error({message: 'Try later'}));
        }
    };
    clearHireRequest = () => this.setState(hireRequestHelper.clear());

    loadMoreInterviewers = async (offset, filter = {}, limit = 15) => {
        const path = 'interviews/interviewers';
        const data = {
            authToken: getToken(),
            offset,
            limit,
            filter,
        };

        this.setState(interviewersRequestHelper.processing());

        try {
            const result = await makeRequest({path, data});
            const {interviewers} = this.state;
            interviewers.list.push(...result.list);
            this.setState({
                ...interviewersRequestHelper.result(result),
                interviewers,
            });
        } catch (error) {
            this.setState(interviewersRequestHelper.error(error));
        }
    };

    clearFetchIsAllowedToPay = () => {
        this.setState({
            isAllowedToPay: null,
        });
    };

    markAsPaid = async interviewId => {
        const path = 'interviews/markAsPaid';
        const data = {
            authToken: getToken(),
            interviewId,
        };

        const result = await makeRequest({path, data});
        this.setState({interview: result, isAllowedToPay: null});
    };

    cancelInterview = async interviewId => {
        const path = 'interviews/cancelInterview';
        const data = {
            authToken: getToken(),
            interviewId,
        };
        this.setState(cancelInterviewRequestHelper.processing());
        try {
            const result = await makeRequest({path, data});
            if (result.status) {
                this.setState(cancelInterviewRequestHelper.result(result));
            } else {
                this.setState(cancelInterviewRequestHelper.error(result));
            }
        } catch (error) {
            this.setState(cancelInterviewRequestHelper.error({message: 'Try later'}));
        }
    };
    clearCancelInterview = () => this.setState(cancelInterviewRequestHelper.clear());
    clearRelease = () => this.setState(releaseRequestHelper.clear());
    setPageURLToReturn = pageURLToReturn => this.setState({pageURLToReturn});

    sendConfirmationEmail = async (interviewId, destinationUserId, cb) => {
        const path = 'interviews/sendConfirmationEmail';
        const authToken = getToken();

        try {
            await makeRequest({path, data: {interviewId, destinationUserId, authToken}});
            cb && cb();
        } catch (e) {
            cb && cb(e);
        }
    };

    sendConfirmationsToAll = async (interviewId, cb) => {
        const path = 'interviews/sendConfirmationEmailsToAll';
        const authToken = getToken();

        try {
            await makeRequest({path, data: {interviewId, authToken}});
            cb && cb();
        } catch (e) {
            cb && cb(e);
        }
    };

    editConfirmationEmail = async (interviewId, resourceId, newConfirmationEmail, cb) => {
        const path = 'interviews/editConfirmationEmail';
        const authToken = getToken();

        try {
            await makeRequest({path, data: {interviewId, authToken, resourceId, newConfirmationEmail}});
            await this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
            cb && cb();
        } catch (e) {
            cb && cb(e);
        }
    };

    storeFilter = filter => {
        this.setState({interviewersFilter: filter});
    };

    clearInterviewers = () => {
        this.setState({interviewers: {list: [], totalCount: 0}});
    };

    createCampaign = async data => {
        const path = 'interviews/createCampaign';

        const authToken = getToken();

        this.setState(createCampaignRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {...data, authToken}});
            this.setState(createCampaignRequestHelper.result(result));
        } catch (e) {
            this.setState(createCampaignRequestHelper.error(e));
        }
    };

    openConfirmJoinModal = interviewId => {
        this.setState({confirmJoinModal: {isOpened: true, interviewId}});
    };

    closeConfirmJoinModal = () => {
        this.setState({confirmJoinModal: {isOpened: false, interviewId: null}});
    };

    setEditInterviewURLToReturn = url => this.setState({editInterviewURLToReturn: url});

    setSelectedResource = resourceId => {
        const {selectedResources} = this.state;
        const exist = Boolean(selectedResources.find(id => id === resourceId));
        const newList = exist ? selectedResources.filter(id => id !== resourceId) : [...selectedResources, resourceId];
        this.setState({selectedResources: newList});
    };

    setSelectedResourceToRelease = resourceId => {
        const {selectedResourcesToRelease} = this.state;
        const exist = Boolean(selectedResourcesToRelease.find(id => id === resourceId));
        const newList = exist
            ? selectedResourcesToRelease.filter(id => id !== resourceId)
            : [...selectedResourcesToRelease, resourceId];
        this.setState({selectedResourcesToRelease: newList});
    };

    clearSelectedResources = tab => {
        if (tab === 'application') this.setState({selectedResources: []});
        else if (tab === 'hired') this.setState({selectedResourcesToRelease: []});
    };

    changeStatusForAll = async (decision, ids, interviewId, clientMessage) => {
        const authToken = getToken();
        const path = `interviews/${decision ? 'hire' : 'decline'}All`;
        this.setState({
            inviteToInterviewProcessingIds: [...this.state.inviteToInterviewProcessingIds, ...ids],
            inviteToInterviewErrorIds: this.state.inviteToInterviewErrorIds.filter(id => !ids.includes(id)),
        });
        try {
            const data = {authToken, ids, decision, interviewId};
            if (clientMessage) data.clientMessage = clientMessage;
            const {result} = await makeRequest({path, data});
            const processingIds = this.state.inviteToInterviewProcessingIds.filter(id => !ids.includes(id));
            const errorIds = result.filter(res => !res.status).map(res => res.userId);
            const successIds = result.filter(res => res.status).map(res => res.userId);
            const {selectedResources} = this.state;
            const newState = {
                inviteToInterviewProcessingIds: processingIds,
                inviteToInterviewErrorIds: [...this.state.inviteToInterviewErrorIds, ...errorIds],
                selectedResources: selectedResources.filter(id => !ids.includes(id)),
            };
            if (decision) {
                newState.hireManyResultIds = [...this.state.hireManyResultIds, ...successIds];
            } else {
                newState.declineManyResultIds = [...this.state.declineManyResultIds, ...successIds];
            }
            this.setState(newState);
            await this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
            return {status: true};
        } catch (e) {
            this.setState({
                inviteToInterviewProcessingIds: this.state.inviteToInterviewProcessingIds.filter(
                    id => !ids.includes(id)
                ),
                inviteToInterviewErrorIds: [
                    ...this.state.inviteToInterviewErrorIds,
                    ...this.state.inviteToInterviewProcessingIds.filter(id => ids.includes(id)),
                ],
            });
            return {status: false, error: e};
        }
    };

    releaseAll = async interviewId => {
        const path = `interviews/releaseAll`;
        const {selectedResourcesToRelease: ids} = this.state;
        const data = {authToken: getToken(), ids, interviewId};
        try {
            this.setState(releaseAllRequestHelper.processing());
            const {result} = await makeRequest({path, data});
            const successIds = result.filter(res => res.status).map(res => res.userId);
            const {selectedResourcesToRelease} = this.state;
            await this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
            this.setState({
                ...releaseAllRequestHelper.result(true),
                selectedResourcesToRelease: selectedResourcesToRelease.filter(id => !successIds.includes(id)),
            });
        } catch (e) {
            this.setState(releaseAllRequestHelper.error(e));
        }
    };

    clearReleaseAll = () => this.setState(releaseAllRequestHelper.clear());

    clearDashboardInterviews = () => this.setState({dashboardInterviews: {}});

    setInitialSelectedResources = selectedResources => this.setState({selectedResources});

    createExternalInterview = async values => {
        const path = 'interviews/createExternalInterview';
        const data = new FormData();
        data.append('authToken', getToken());
        for (const key in values) {
            values[key] && data.append(key, values[key]);
        }
        this.setState(createExternalInterviewRequestHelper.processing());
        try {
            const result = await uploadFile({path, data});
            this.setState(createExternalInterviewRequestHelper.result(result));
            return result;
        } catch (e) {
            this.setState(createExternalInterviewRequestHelper.error(e));
        }
    };

    uploadReport = async (values, cb = noop) => {
        const path = 'interviews/uploadReport';
        const data = new FormData();
        data.append('authToken', getToken());
        const {interviewId, files, filesToUpdate} = values;
        data.append('interviewId', interviewId);
        for (let file of filesToUpdate) {
            data.append('filesToUpdate', file);
        }
        for (let file of files) {
            data.append('files', file);
        }

        try {
            const result = await uploadFile({path, data});
            this.setState({interview: result.interview});
            cb(null, result);
        } catch (e) {
            cb(e);
        }
    };

    setDashboardDate = date => this.setState({dashboardDate: date});

    restartRecording = async interviewId => {
        const path = 'interviews/restartRecording';
        const authToken = getToken();
        const data = {authToken, interviewId};
        await makeRequest({path, data});
    };

    render() {
        const actions = {
            clearCreateInterviewResult: this.clearCreateInterviewResult,
            fetchAdminSearch: this.fetchAdminSearch,
            deleteInterview: this.deleteInterview,
            acceptInterview: this.replyToInterview.bind(this, 'accepted'),
            applyToInterview: this.replyToInterview.bind(this, 'applied'),
            declineInterview: this.replyToInterview.bind(this, 'declined'),
            inviteToInterview: this.inviteToInterview,
            fetchInterviewers: this.fetchInterviewers,
            resetInviteProcessing: this.resetInviteProcessing,
            hireToInterview: this.hireToInterview,
            loadMoreInterviewers: this.loadMoreInterviewers,
            cancelInvitation: this.cancelInvitation,
            cancelHire: this.cancelHire,
            markAsPaid: this.markAsPaid,
            clearFetchIsAllowedToPay: this.clearFetchIsAllowedToPay,
            debouncedFetchInterviewers: debounce(this.fetchInterviewers, 500),
            setPageURLToReturn: this.setPageURLToReturn,
            clearHireRequest: this.clearHireRequest,
            clearCancelInterview: this.clearCancelInterview,
            cancelInterview: this.cancelInterview,
            releaseMoney: this.releaseMoney,
            clearRelease: this.clearRelease,
            sendConfirmationEmail: this.sendConfirmationEmail,
            sendConfirmationsToAll: this.sendConfirmationsToAll,
            editConfirmationEmail: this.editConfirmationEmail,
            storeFilter: this.storeFilter,
            clearInterviewers: this.clearInterviewers,
            changeInterviewListState: this.changeInterviewListState,
            createCampaign: this.createCampaign,
            openConfirmJoinModal: this.openConfirmJoinModal,
            closeConfirmJoinModal: this.closeConfirmJoinModal,
            setEditInterviewURLToReturn: this.setEditInterviewURLToReturn,
            setSelectedResource: this.setSelectedResource,
            setSelectedResourceToRelease: this.setSelectedResourceToRelease,
            declineAll: this.changeStatusForAll.bind(this, false),
            acceptAll: this.changeStatusForAll.bind(this, true),
            setInitialSelectedResources: this.setInitialSelectedResources,
            releaseAll: this.releaseAll,
            clearReleaseAll: this.clearReleaseAll,
            createExternalInterview: this.createExternalInterview,
            clearSelectedResources: this.clearSelectedResources,
            fetchInterviewForDashboard: this.fetchInterviewForDashboard,
            fetchUpcomingInterviewForDashboard: this.fetchUpcomingInterviewForDashboard,
            clearDashboardInterviews: this.clearDashboardInterviews,
            searchForDashboard: debounce(this.searchForDashboard, 500),
            clearSearchForDashboard: this.clearSearchForDashboard,
            uploadReport: this.uploadReport,
            setDashboardDate: this.setDashboardDate,
            clearExternalInterviewCreated: this.clearExternalInterviewCreated,
            restartRecording: this.restartRecording,
        };

        return (
            <InterviewContext.Provider value={{...this.state, ...actions}}>
                {this.props.children}
            </InterviewContext.Provider>
        );
    }
}

export default withConsumers(InterviewContextProvider, [UserContextConsumer, InterviewStorageContextConsumer]);

export const InterviewContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <InterviewContext.Consumer>
                {context => <WrappedComponent interviewContext={context} {...props} />}
            </InterviewContext.Consumer>
        );
    };
};
