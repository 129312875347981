import { PageLayout } from "../../PageLayout/PageLayout"
import { ListSkeleton } from "../../Projects/Projects";
import Skeleton from "../../Skeleton";
import styles from "./InterviewsPage.module.scss"

const InterviewsPage = () => {
    return (
        <PageLayout>
            <div className={styles.content}>
                <Skeleton count={2} containerClassName={styles.tabs} className={styles.tab} />
                <Skeleton className={styles.search} />
                <ListSkeleton count={4} className={styles.list} />
            </div>

        </PageLayout>
    )
}

export default InterviewsPage;