import _ from 'lodash';
import React, { createContext, Component } from 'react';
import makeRequest from 'makeRequest';
import uploadFile from 'uploadFile';

import { AdminProjectsTableRowNumber } from 'constants/Admin';
import { createRequestHelper } from 'helpers/requestHelper';
import { getToken } from 'helpers/getToken';

export const ProjectContext = createContext({});

const DEFAULT_STATE = {
    projects: {
        list: [],
        totalCount: 0,
    },
    adminPage: 0,
    adminSearchQuery: '',
    project: {},
    pageToReturnFromProjectDetails: '',
};
const confirmProjectRequestHelper = createRequestHelper('confirmProject');

export default class ProjectContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = { ...DEFAULT_STATE };
        window.pc = this;
    }

    componentDidMount() {
        this.debouncedFetchProjects = _.debounce(this.fetchProjects, 500);
    }

    createProject = async (values) => {
        const path = 'projects/create';
        const data = new FormData();
        data.append('authToken', getToken());
        for (const key in values) {
            data.append(key, values[key]);
        }

        this.setState({
            createProjectProcessing: true,
        });

        try {
            const result = await uploadFile({ path, data });
            this.setState({
                createProjectProcessing: false,
                createProjectResult: result,
                createProjectError: null,
            });
        } catch (error) {
            this.setState({
                createProjectProcessing: false,
                createProjectError: error,
            });
        }
    };

    clearCreateProjectResult = () => {
        this.setState({
            createProjectResult: null,
            createProjectError: null,
        });
    };
    clearEditProjectResult = () => {
        this.setState({
            editProjectResult: null,
            editProjectError: null,
        });
    };

    updateSearchQuery = async (query, filter) => {
        this.setState({
            adminSearchQuery: query,
        });

        try {
            await this.debouncedFetchProjects({
                adminFetch: true,
                offset: 0,
                limit: AdminProjectsTableRowNumber,
                filter,
                page: 0,
            });
        } catch (err) {
            console.log(err);
        }
    };

    fetchProjects = async ({ adminFetch, offset, limit, filter, page, sort }) => {
        const path = 'projects/list';
        const data = {
            authToken: getToken(),
            adminFetch,
            offset,
            limit,
            filter: filter || {},
            query: this.state.adminSearchQuery,
            sort,
        };

        const adminPage = page !== undefined ? page : this.state.adminPage;

        this.setState({
            projectsProcessing: true,
        });

        try {
            const result = await makeRequest({ path, data });
            this.setState({
                projectsProcessing: false,
                projects: result,
                adminPage,
            });
            if (!this.state.pageLoaded) {
                this.setState({ pageLoaded: true });
            }
            return result;
        } catch (error) {
            this.setState({
                projectsProcessing: false,
            });
        }
    };

    clearFetchProjects = () => {
        this.setState({
            projects: DEFAULT_STATE.projects,
            projectsProcessing: false,
        });
    };

    clearPageLoaded = () => {
        this.setState({ pageLoaded: false });
    };

    getMemberProjects = async () => {
        const path = 'team/getProjects';
        const data = {
            authToken: getToken(),
        };
        try {
            const result = await makeRequest({ path, data });
            return result;
        } catch (error) {
            console.log('getMemberProjects, Error', { error });
        }
    };

    findProject = async (projectId) => {
        const path = 'projects/find';
        const data = {
            authToken: getToken(),
            projectId,
        };

        this.setState({
            findProjectProcessing: true,
        });

        try {
            const result = await makeRequest({ path, data });
            this.setState({
                findProjectProcessing: false,
                project: result,
            });
            return result;
        } catch (error) {
            this.setState({
                findProjectProcessing: false,
                projectError: error,
            });
        }
    };

    clearFindProject = () => {
        this.setState({
            project: null,
            projectError: null,
        });
    };

    deleteProject = async (projectId) => {
        const path = 'projects/delete';
        const data = {
            authToken: getToken(),
            projectId,
        };

        await makeRequest({ path, data });
        this.setState((state) => ({
            projects: {
                ...state.projects,
                list: state.projects.list.filter((p) => p.id !== projectId),
            },
        }));
    };

    confirmProject = async (projectId) => {
        try {
            this.setState(confirmProjectRequestHelper.processing());
            const path = 'projects/confirm';
            const data = {
                authToken: getToken(),
                projectId,
            };
            const response = await makeRequest({ path, data });
            if (!response.status) throw Error(response.message);

            const isProjectExists = Object.keys(this.state.project).length;
            const projectUpdate = isProjectExists ? { ...this.state.project, completed: true } : {};
            const projectsListUpdate = {
                totalCount: this.state.projects.totalCount,
                list: this.state.projects.list.map((p) => (p.id === projectId ? { ...p, completed: true } : p)),
            };
            this.setState({
                ...confirmProjectRequestHelper.result(response.result),
                project: projectUpdate,
                projects: projectsListUpdate,
            });
            return response;
        } catch (error) {
            this.setState(confirmProjectRequestHelper.error(error.message));
        }
    };

    clearConfirmProject = () => this.setState(confirmProjectRequestHelper.clear());

    editProject = async (values) => {
        const path = 'projects/edit';
        const data = new FormData();
        data.append('authToken', getToken());
        for (const key in values) {
            data.append(key, values[key]);
        }
        if (!data.cover) data.cover = null;

        this.setState({
            editProjectProcessing: true,
        });

        try {
            const result = await uploadFile({ path, data });
            this.setState({
                editProjectProcessing: false,
                editProjectResult: result,
                editProjectError: null,
            });
        } catch (error) {
            this.setState({
                editProjectProcessing: false,
                editProjectError: error,
            });
        }
    };

    setPageToReturnFromProjectDetail = (page) => this.setState({ pageToReturnFromProjectDetails: page });

    render() {
        const actions = {
            createProject: this.createProject,
            clearCreateProjectResult: this.clearCreateProjectResult,
            clearEditProjectResult: this.clearEditProjectResult,
            updateSearchQuery: this.updateSearchQuery,
            fetchProjects: this.fetchProjects,
            clearFetchProjects: this.clearFetchProjects,
            findProject: this.findProject,
            clearFindProject: this.clearFindProject,
            deleteProject: this.deleteProject,
            confirmProject: this.confirmProject,
            clearConfirmProject: this.clearConfirmProject,
            editProject: this.editProject,
            debouncedFetchProjects: _.debounce(this.fetchProjects, 500),
            setPageToReturnFromProjectDetail: this.setPageToReturnFromProjectDetail,
            clearPageLoaded: this.clearPageLoaded,
            getMemberProjects: this.getMemberProjects,
        };

        return (
            <ProjectContext.Provider value={{ ...this.state, ...actions }}>
                {this.props.children}
            </ProjectContext.Provider>
        );
    }
}

export const ProjectContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <ProjectContext.Consumer>
                {(context) => <WrappedComponent projectContext={context} {...props} />}
            </ProjectContext.Consumer>
        );
    };
};
