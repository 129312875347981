import { getApiPrefix } from 'components/common/apiPrefix';

const makeRequest = ({ path, data }, cb) => {
    const fullPath = `${getApiPrefix()}/${path}`;
    const params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    };

    return fetch(fullPath, params)
        .then((res) => {
            if (res.status === 401) {
                makeRequest.logout();
            }
            return res.json();
        })
        .then((res) => {
            if (res.error) {
                cb && cb(res.error);
                throw res.error;
            }

            cb && cb(null, res);

            return res;
        });
};

export default makeRequest;
