import React, { createContext, Component } from 'react';
import makeRequest from 'makeRequest';
import { createRequestHelper, requestHelperWithInitialState } from 'helpers/requestHelper';
import uploadFile from 'uploadFile';
import { getToken } from 'helpers/getToken';

export const InterviewPaymentContext = createContext({});

const getPriceRequestHelper = createRequestHelper('getPrice');
const payForInterviewRequestHelper = createRequestHelper('payForInterview');
const getPaidResourcePriceRequestHelper = createRequestHelper('getPaidResourcePrice');
const hireRespondentRequestHelper = requestHelperWithInitialState('hireRespondent', null, false, {});
const getReportPriceRequestHelper = createRequestHelper('getReportPrice');
const getEditInterviewPriceRequestHelper = requestHelperWithInitialState('getEditInterviewPrice', null, false, {});
const editInterviewRequestHelper = requestHelperWithInitialState('editInterview', null, false, {});
const getCancelSessionRequestHelper = requestHelperWithInitialState('getCancelSessionAmount', {});
const getCancelInterviewAmountRequestHelper = requestHelperWithInitialState('getCancelInterviewAmount', {});
const getCancelResourceAmountRequestHelper = requestHelperWithInitialState('getCancelResourceAmount', {});
const getPaidRespondentPriceRequestHelper = requestHelperWithInitialState('getPaidRespondentPrice', null, false, null);
const getDraftPriceRequestHelper = createRequestHelper('getDraftPrice');

class InterviewPaymentContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...getPriceRequestHelper.initialState,
            ...payForInterviewRequestHelper.initialState,
            ...getPaidResourcePriceRequestHelper.initialState,
            ...hireRespondentRequestHelper.initialState,
            ...getReportPriceRequestHelper.initialState,
            ...getEditInterviewPriceRequestHelper.initialState,
            ...editInterviewRequestHelper.initialState,
            ...getCancelSessionRequestHelper.initialState,
            ...getCancelInterviewAmountRequestHelper.initialState,
            ...getCancelResourceAmountRequestHelper.initialState,
            ...getPaidRespondentPriceRequestHelper.initialState,
            ...getDraftPriceRequestHelper.initialState,
        };
    }

    getPrice = async (interviewId, code) => {
        const path = 'interviews/get-price';
        const authToken = getToken();
        const data = { interviewId, authToken, code };
        this.setState(getPriceRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getPriceRequestHelper.result(result));
        } catch (e) {
            this.setState(getPriceRequestHelper.error(e));
        }
    };

    getDraftPrice = async (data) => {
        const path = 'interviews/get-draft-price';
        this.setState(getDraftPriceRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getDraftPriceRequestHelper.result(result));
        } catch (e) {
            this.setState(getDraftPriceRequestHelper.error(e));
        }
    };

    clearPrice = () => this.setState(getDraftPriceRequestHelper.clear());

    payForInterview = async (interviewId, code) => {
        const path = 'interviews/pay';
        const authToken = getToken();
        const data = { interviewId, code, authToken };
        this.setState(payForInterviewRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(payForInterviewRequestHelper.result(result));
            return { result };
        } catch (e) {
            this.setState(payForInterviewRequestHelper.error(e));
            return { error: e };
        }
    };
    clearPayForInterview = () => this.setState(payForInterviewRequestHelper.clear());

    getPaidResourcePrice = async ({ interviewId, resourceId, code }) => {
        const path = 'interviews/getPaidResourcePrice';
        const authToken = getToken();
        const data = { interviewId, resourceId, code, authToken };
        this.setState(getPaidResourcePriceRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getPaidResourcePriceRequestHelper.result(result));
            return { result };
        } catch (e) {
            this.setState(getPaidResourcePriceRequestHelper.error(e));
            return { error: e };
        }
    };
    clearPaidResourcePrice = () => this.setState(getPaidResourcePriceRequestHelper.clear());

    hireRespondent = async ({ interviewId, resourceId, sessionId }) => {
        const path = 'interviews/hireRespondent';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId, sessionId };
        this.setState(hireRespondentRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(hireRespondentRequestHelper.result(result));
            return { result, error: null, status: true };
        } catch (e) {
            this.setState(hireRespondentRequestHelper.error(e));
            return { error: JSON.parse(e), result: null, status: false };
        }
    };

    hirePaidResource = async ({ interviewId, resourceId, code }) => {
        const path = 'interviews/hirePaidResource';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId, code };
        try {
            const result = await makeRequest({ path, data });
            return { result, error: null, status: true };
        } catch (e) {
            return { error: JSON.parse(e), result: null, status: false };
        }
    };

    getTotalHiringCosts = async ({ interview, resources }) => {
        const path = 'interviews/getTotalHiringCosts';
        const authToken = getToken();
        const data = { authToken, interview, resources };
        try {
            const result = await makeRequest({ path, data });
            return { result, error: null, status: true };
        } catch (e) {
            return { error: JSON.parse(e), result: null, status: false };
        }
    };

    getReportPrice = async ({ interviewId, resourceId, code }) => {
        const path = 'interviews/getReportPrice';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId, code };
        this.setState(getReportPriceRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getReportPriceRequestHelper.result(result));
        } catch (e) {
            this.setState(getReportPriceRequestHelper.error(e));
        }
    };

    assignAsReporter = async ({ interviewId, resourceId, code }) => {
        const path = 'interviews/assignAsReporter';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId, code };
        try {
            const result = await makeRequest({ path, data });
            return { result, error: null };
        } catch (e) {
            return { error: e, result: null };
        }
    };

    releaseMoney = async ({ interviewId, resourceId }) => {
        const path = 'interviews/releaseMoney';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId };
        try {
            const result = await makeRequest({ path, data });
            return { result, error: null };
        } catch (e) {
            return { error: e, result: null };
        }
    };

    releaseReportMoney = async ({ interviewId, resourceId }) => {
        const path = 'interviews/releaseReportReward';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId };
        try {
            const result = await makeRequest({ path, data });
            return { result, error: null };
        } catch (e) {
            return { error: e, result: null };
        }
    };

    releaseHomeworkReward = async ({ interviewId, resourceId }) => {
        const path = 'interviews/releaseHomeworkReward';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId };
        try {
            const result = await makeRequest({ path, data });
            return { result, error: null };
        } catch (e) {
            return { error: e, result: null };
        }
    };

    releaseMoneyMultiple = async ({ interviewId, resourcesIds, resources }) => {
        const path = 'interviews/releaseMoneyMultiple';
        const authToken = getToken();
        const data = { authToken, interviewId, resourcesIds, resources };
        try {
            const result = await makeRequest({ path, data });
            return { result, error: null };
        } catch (e) {
            return { error: e, result: null };
        }
    };

    getReleaseMoneyMultiplePrice = async ({ resources }) => {
        const path = 'interviews/getReleaseMoneyMultiplePrice';
        const authToken = getToken();
        const data = { authToken, resources };
        try {
            const result = await makeRequest({ path, data });
            return { result, error: null };
        } catch (e) {
            return { error: e, result: null };
        }
    };

    checkBalanceForHiring = async (total) => {
        const path = 'interviews/checkBalanceForHiring';
        const data = { authToken: getToken(), total };

        const result = await makeRequest({ path, data });
        return result;
    };

    getEditInterviewPrice = async ({ interviewId, update, code }) => {
        const path = 'interviews/getEditInterviewPrice';
        const authToken = getToken();
        const data = { interviewId, update, code, authToken };
        this.setState(getEditInterviewPriceRequestHelper.processing());
        try {
            const { status, result } = await makeRequest({ path, data });
            this.setState(getEditInterviewPriceRequestHelper.result(result));
            return { status, result, error: null };
        } catch (e) {
            const parsedError = JSON.parse(e);
            this.setState(getEditInterviewPriceRequestHelper.error(parsedError));
            return { status: false, result: null, error: e };
        }
    };
    clearGetEditInterviewPrice = () => this.setState(getEditInterviewPriceRequestHelper.clear());

    updateSession = async (sessionUpdate, { interviewId, sessionId }) => {
        const path = 'interviews/updateSession';
        const authToken = getToken();
        const data = {
            authToken,
            update: sessionUpdate,
            sessionId,
            interviewId,
        };
        try {
            const { status } = await makeRequest({ path, data });
        } catch (e) {
            const parsedError = JSON.parse(e);
            this.setState(getEditInterviewPriceRequestHelper.error(parsedError));
            return { status: false, result: null, error: e };
        }
    };

    editInterview = async (args) => {
        const {
            interviewId,
            update,
            code,
            filesToUpload,
            filesToRemove,
            homeworkFilesToRemove,
            homeworkFilesToUpload,
        } = args;
        const path = 'interviews/editInterview';
        const authToken = getToken();
        const data = new FormData();
        data.append('authToken', authToken);
        data.append('interviewId', interviewId);
        data.append('update', JSON.stringify(update));
        data.append('filesToRemove', JSON.stringify(filesToRemove));
        data.append('homeworkFilesToRemove', JSON.stringify(homeworkFilesToRemove));
        if (code) data.append('code', code);
        for (let file of filesToUpload) {
            data.append('files', file);
        }
        for (let file of homeworkFilesToUpload) {
            data.append('homeworkFiles', file);
        }
        this.setState(editInterviewRequestHelper.processing());

        try {
            const { status, handler, result } = await uploadFile({ path, data });
            this.setState(editInterviewRequestHelper.result(result));
            return { status, handler, result, error: null };
        } catch (e) {
            const parsedError = JSON.parse(e);
            this.setState(editInterviewRequestHelper.error(parsedError));
            return { status: false, result: null, error: e };
        }
    };
    clearEditInterview = () => this.setState(editInterviewRequestHelper.clear());

    cancelSession = async ({ interviewId, sessionId }) => {
        const path = 'interviews/cancelSession';
        const authToken = getToken();
        const data = { authToken, interviewId, sessionId };
        try {
            await makeRequest({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    cancelInterview = async ({ interviewId }) => {
        const path = 'interviews/cancelInterview';
        const authToken = getToken();
        const data = { authToken, interviewId };
        try {
            await makeRequest({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    cancelResource = async ({ interviewId, resourceId }) => {
        const path = 'interviews/cancelResource';
        const authToken = getToken();
        const data = { interviewId, resourceId, authToken };
        try {
            await makeRequest({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    getCancelSessionAmount = async ({ interviewId, sessionId }) => {
        const path = 'interviews/getCancelSessionAmount';
        const authToken = getToken();
        const data = { authToken, interviewId, sessionId };
        this.setState(getCancelSessionRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getCancelSessionRequestHelper.result(result));
        } catch (e) {
            this.setState(getCancelSessionRequestHelper.error(e));
        }
    };
    clearGetCancelSessionAmount = () => this.setState(getCancelSessionRequestHelper.clear());

    getCancelInterviewAmount = async ({ interviewId }) => {
        const path = 'interviews/getCancelInterviewAmount';
        const authToken = getToken();
        const data = { authToken, interviewId };
        this.setState(getCancelInterviewAmountRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getCancelInterviewAmountRequestHelper.result(result));
        } catch (e) {
            this.setState(getCancelInterviewAmountRequestHelper.error(e));
        }
    };
    clearGetCancelInterviewAmount = () => this.setState(getCancelInterviewAmountRequestHelper.clear());

    getCancelResourceAmount = async ({ interviewId, resourceId }) => {
        const path = 'interviews/getCancelResourceAmount';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId };
        this.setState(getCancelResourceAmountRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getCancelResourceAmountRequestHelper.result(result));
        } catch (e) {
            this.setState(getCancelResourceAmountRequestHelper.error(e));
        }
    };
    clearGetCancelResourceAmount = () => this.setState(getCancelResourceAmountRequestHelper.clear());

    getPaidRespondentPrice = async ({ interviewId }) => {
        const path = 'interviews/getPaidRespondentPrice';
        const authToken = getToken();
        const data = { authToken, interviewId };
        this.setState(getPaidRespondentPriceRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getPaidRespondentPriceRequestHelper.result(result));
        } catch (e) {
            this.setState(getPaidRespondentPriceRequestHelper.error(e));
        }
    };
    clearGetPaidRespondentPrice = () => this.setState(getPaidRespondentPriceRequestHelper.clear());

    render() {
        const actions = {
            getPrice: this.getPrice,
            clearPrice: this.clearPrice,
            payForInterview: this.payForInterview,
            clearPayForInterview: this.clearPayForInterview,
            getPaidResourcePrice: this.getPaidResourcePrice,
            hireRespondent: this.hireRespondent,
            clearPaidResourcePrice: this.clearPaidResourcePrice,
            hirePaidResource: this.hirePaidResource,
            getReportPrice: this.getReportPrice,
            assignAsReporter: this.assignAsReporter,
            releaseMoney: this.releaseMoney,
            releaseMoneyMultiple: this.releaseMoneyMultiple,
            getEditInterviewPrice: this.getEditInterviewPrice,
            editInterview: this.editInterview,
            clearGetEditInterviewPrice: this.clearGetEditInterviewPrice,
            clearEditInterview: this.clearEditInterview,
            cancelSession: this.cancelSession,
            releaseReportMoney: this.releaseReportMoney,
            cancelInterview: this.cancelInterview,
            getCancelSessionAmount: this.getCancelSessionAmount,
            clearGetCancelSessionAmount: this.clearGetCancelSessionAmount,
            getCancelInterviewAmount: this.getCancelInterviewAmount,
            clearGetCancelInterviewAmount: this.clearGetCancelInterviewAmount,
            cancelResource: this.cancelResource,
            getCancelResourceAmount: this.getCancelResourceAmount,
            clearGetCancelResourceAmount: this.clearGetCancelResourceAmount,
            releaseHomeworkReward: this.releaseHomeworkReward,
            getPaidRespondentPrice: this.getPaidRespondentPrice,
            clearGetPaidRespondentPrice: this.clearGetPaidRespondentPrice,
            getTotalHiringCosts: this.getTotalHiringCosts,
            getReleaseMoneyMultiplePrice: this.getReleaseMoneyMultiplePrice,
            updateSession: this.updateSession,
            getDraftPrice: this.getDraftPrice,
            checkBalanceForHiring: this.checkBalanceForHiring,
        };

        return (
            <InterviewPaymentContext.Provider value={{ ...this.state, ...actions }}>
                {this.props.children}
            </InterviewPaymentContext.Provider>
        );
    }
}

export default InterviewPaymentContextProvider;
