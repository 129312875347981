import React from 'react';
import styles from './LoadingSpinner.module.scss';
import cn from 'classnames';

import {BounceLoader} from 'react-spinners';
import {mainGreen} from 'styles/variables';

const LoadingSpinner = ({spinnerProps, className, style}) => {
    return (
        <div className={cn(styles.wrapper, className)} style={style}>
            <BounceLoader color={mainGreen} {...spinnerProps} />
        </div>
    );
};

export default LoadingSpinner;
