import Routes from 'constants/Routes';
import BrowsePage from 'components/common/Skeleton/Pages/BrowsePage/BrowsePage';
import LoginPage from 'components/common/Skeleton/Pages/LoginPage/LoginPage';
import Register from 'components/common/Skeleton/Pages/Register/Register';
import ProjectsPage from 'components/common/Skeleton/Pages/ProjectsPage/ProjectsPage';
import DashboardPage from 'components/common/Skeleton/Pages/DashboardPage/DashboardPage';
import ChatPage from 'components/common/Skeleton/Pages/ChatPage/ChatPage';
import NotificationsPage from 'components/common/Skeleton/Pages/NotificationsPage/NotificationsPage';
import ProfilePage from 'components/common/Skeleton/Pages/ProfilePage/ProfilePage';
import ProfileEditPage from 'components/common/Skeleton/Pages/ProfileEditPage/ProfileEditPage';
import InterviewsPage from 'components/common/Skeleton/Pages/InterviewsPage/InterviewsPage';
import ScreenersPage from 'components/common/Skeleton/Pages/ScreenersPage/ScreenersPage';
import NewInterviewCreationFormPage from 'components/common/Skeleton/Pages/NewInterviewCreationFormPage/NewInterviewCreationFormPage';
import InterviewDetailPage from 'components/common/Skeleton/Pages/InterviewDetailPage/InterviewDetailPage';
import CreateScreenerPage from 'components/common/Skeleton/Pages/CreateScreenerPage/CreateScreenerPage';
import AdminPage from './Pages/AdminPage/AdminPage';
import AdminPlansPage from './Pages/AdminPlansPage/AdminPlansPage';
import AdminProjectsPage from './Pages/AdminProjectsPage/AdminProjectsPage';
import AdminOrdersPage from './Pages/AdminOrdersPage/AdminOrdersPage';
import VouchersPage from './Pages/VouchersPage/VouchersPage';
import PromoCodesPage from './Pages/PromoCodesPage/PromoCodesPage';
import DisputesPage from './Pages/DisputesPage/DisputesPage';
import ProjectDetailPage from './Pages/ProjectDetailPage/ProjectDetailPage';
import EditProjectPage from './Pages/EditProjectPage/EditProjectPage';
import NewProjectPage from './Pages/NewProjectPage/NewProjectPage';

const LoadersToRoute = {
    [Routes.browseInterviews.path]: BrowsePage,
    [Routes.signUp.path]: Register,
    [Routes.signIn.path]: LoginPage,
    [Routes.projects.path]: ProjectsPage,
    [Routes.interviews.path]: InterviewsPage,
    [Routes.interviewDetail.path]: InterviewDetailPage,
    [Routes.profile.path]: ProfilePage,
    [Routes.profileEdit.path]: ProfileEditPage,
    [Routes.screeners.path]: ScreenersPage,
    [Routes.createScreener.path]: CreateScreenerPage,
    [Routes.chat.path]: ChatPage,
    [Routes.notifications.path]: NotificationsPage,
    [Routes.dashboard.path]: DashboardPage,
    [Routes.newInterviewCreation.path]: NewInterviewCreationFormPage,
    [Routes.admin.path]: AdminPage,
    [Routes.plans.path]: AdminPlansPage,
    [Routes.adminProjects.path]: AdminProjectsPage,
    [Routes.adminOrders.path]: AdminOrdersPage,
    [Routes.vouchers.path]: VouchersPage,
    [Routes.promoCodes.path]: PromoCodesPage,
    [Routes.disputes.path]: DisputesPage,
    [Routes.projectDetail.path]: ProjectDetailPage,
    [Routes.editProject.path]: EditProjectPage,
    [Routes.newProject.path]: NewProjectPage,
};

export default LoadersToRoute;
