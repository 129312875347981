import React from 'react';
import Skeleton from 'components/common/Skeleton/Skeleton';

import style from './Projects.module.scss';
import { PaginationSkeleton } from 'components/common/Skeleton/Pagination/Pagination';
import classNames from 'classnames';

export const TabsSkeleton = () => {
    return (
        <div className={style.container}>
            <Skeleton count={2} containerClassName={style.row} className={style.item} />
            <Skeleton className={style.line} />
        </div>
    );
};

export const ListSkeleton = ({ count = 5, className }) => {
    return <Skeleton count={count} containerClassName={style.list} className={classNames(style.item, className)} />;
};

export const NoTabListSkeleton = () => {
    return (
        <>
            <ListSkeleton />
            <PaginationSkeleton />
        </>
    );
};

export const ProjectsSkeleton = () => {
    return (
        <>
            <TabsSkeleton />
            <ListSkeleton />
            <PaginationSkeleton />
        </>
    );
};
