import Skeleton from "../Skeleton"
import styles from "./Footer.module.scss"

export const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.logoWrapper}>
                <Skeleton className={styles.logo} />
                <Skeleton className={styles.text} />
            </div>
            <div className={styles.left}>
                <Skeleton className={styles.text} />
                <Skeleton className={styles.text} />
                <Skeleton className={styles.text} />
                <Skeleton className={styles.text} />
            </div>
            <div className={styles.right}>
                <Skeleton className={styles.langs} />
            </div>
        </div>
    )
}
