import React, {Component} from 'react';
import {detect} from 'detect-browser';

export const BrowserContext = React.createContext({});

class BrowserProvider extends Component {
    constructor(props) {
        super(props);
        this.browser = detect();
        // https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
        // const isIpad = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
        this.browser.isMobile = ['iOS', 'Android OS', 'BlackBerry OS'].includes(this.browser.os);
        const isBrowserSupported = this.checkBrowserSupport();
        this.state = {
            browser: this.browser,
            unsupportedBrowserError: isBrowserSupported,
        };
    }

    checkBrowserSupport() {
        const hasWebRTCSupport =
            navigator.mediaDevices &&
            navigator.mediaDevices.getUserMedia &&
            (window.webkitRTCPeerConnection || window.mozRTCPeerConnection || window.RTCPeerConnection);

        const browser = this.browser;

        const isIeOrEdge = browser && ['ie', 'edge'].includes(browser.name);
        const isOldSafari = browser && browser.name === 'safari' && parseFloat(browser.version) < 12;
        const isFF = browser && browser.name === 'firefox';
        const noBrowserSupport = isIeOrEdge || isOldSafari || isFF;
        if (!noBrowserSupport && !hasWebRTCSupport) {
            return 'interview:text.unsupportedChromeText';
        }

        return (!hasWebRTCSupport || noBrowserSupport) && 'interview:text.unsupportedBrowserText';
    }

    render() {
        return <BrowserContext.Provider value={this.state}>{this.props.children}</BrowserContext.Provider>;
    }
}

export default BrowserProvider;

export const BrowserContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <BrowserContext.Consumer>
                {context => <WrappedComponent browserContext={context} {...props} />}
            </BrowserContext.Consumer>
        );
    };
};
