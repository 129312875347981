import React, { createContext } from 'react';
import _ from 'lodash';

import AsyncStateComponent from 'context/AsyncStateComponent';

import { UserContextConsumer } from 'context/UserContext';

import { getBackendSocketPath, getVideoServerURL } from 'components/common/apiPrefix';
import makeRequest from 'makeRequest';
import VideoSocketController from 'controllers/VideoSocket';
import { withConsumers } from 'helpers/contexts';

import CheckConnectionStates from 'constants/CheckConnectionStates';
import Routes from 'constants/Routes';
import { isObserver } from 'constants/UserRoles';
import InterviewConnectionErrors from 'constants/InterviewConnectionErrors';
import { InterviewStorageContextConsumer } from 'context/Interview/storage/InterviewStorageContext';
import TabsValues from 'components/Interview/OnlineInterview/RightPanel/InterviewTabs/tabsValues';
import { getToken } from 'helpers/getToken';

export const OnlineInterviewContext = createContext({});

const DEFAULT_STATE = {
    iceServers: [],
    isConnected: false,
    initialVideoChatState: null,
    initialRecordingState: null,
    interviewState: null,
    usersState: null,
    interviewUser: null,
    isFinished: false,
    isOwner: false,
    isKicked: false,
    kickModal: {
        isOpened: false,
        user: null,
    },
    error: null,
    checkConnectionState: CheckConnectionStates.init,

    isChatOpen: false,
    unreadMessages: 0,
    messages: [],
    newMessageForScroll: null,
    languageToListen: null,
    listenToTranslatorOnly: false,
    editingName: {
        user: null,
        value: '',
    },
    videoServer: '',
    videoServerPort: '',
    isMovedToWaitingRoom: false,
    instanceNotReady: false,
    timeout: [],
    interviewTab: TabsValues.participants,
    showWelcome: false,
    sidebarOpened: true,
};

class OnlineInterviewContextProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);

        this.state = DEFAULT_STATE;
        // this.prevDevicePermissionsState = undefined;
        window.oic = this;
    }

    componentWillUnmount() {
        VideoSocketController.disconnect();
    }

    listenEvents() {
        VideoSocketController.on('interview:finished', () => {
            this.setState({ isFinished: true });
        });
        VideoSocketController.on('users:state', (data) => this.setState({ usersState: data.usersState }));
        VideoSocketController.on('users:currentUserState', (data) => {
            this.setState({ interviewUser: data.user });
        });
        VideoSocketController.on('users:kicked', () => this.setKicked(true));
        VideoSocketController.on('chat:message', this.receiveNewMessage);
        VideoSocketController.on('users:toWaitingRoom', () => this.setState({ isMovedToWaitingRoom: true }));
        VideoSocketController.on('users:timeout', ({ timeout }) => this.setState({ timeout }));
    }

    finishInterview = async () => {
        await VideoSocketController.emit('interview:finish', {});
        this.setState({ isFinished: true });
    };

    toggleSidebar = (value) => {
        this.setState({ sidebarOpened: value });
    };

    joinInterview = async ({ interviewId, sessionId }, history) => {
        const { userContext } = this.props;

        const authToken = getToken();
        const path = 'interviews/join';

        const data = {
            authToken,
            interviewId,
            sessionId,
        };

        try {
            const { isSuperAdmin } = userContext.user;
            const { isOwner, videoServerUrl, videoServerPort, serverStatus } = await makeRequest({ path, data });
            if (serverStatus !== 'ready') {
                this.setState({ instanceNotReady: true });
                return;
            }
            const queryParams = {
                token: authToken,
                videoServer: videoServerUrl,
                videoServerPort,
            };
            await VideoSocketController.connect(getVideoServerURL(), queryParams, getBackendSocketPath());
            this.listenEvents();

            const memberId = userContext.user.team && userContext.user.team.memberId;

            const { error, response } = await VideoSocketController.emit('interview:join', {
                interviewId,
                userId: memberId || userContext.user.id,
                isOwner,
                signalUserToken: localStorage.getItem('signalUserToken'),
            });

            const roleAllows = !isOwner && !isObserver(userContext.user.role) && !isSuperAdmin;
            if (error && error.message === InterviewConnectionErrors.waitingRoom && roleAllows) {
                const waitingRoomLink = Routes.waitingRoom.path
                    .replace(':interviewId', interviewId)
                    .replace(':sessionId', sessionId);
                return history.push(waitingRoomLink);
            }

            if (error && error.message === InterviewConnectionErrors.moderatorConnected && isOwner) {
                const moderatorWaitingRoomLink = Routes.moderatorWaitingRoom.path
                    .replace(':interviewId', interviewId)
                    .replace(':sessionId', sessionId);
                return history.push(moderatorWaitingRoomLink);
            }

            if (error) {
                this.setState({ error: error.message });
                return;
            }

            const messages = await this.getMessagesFromChat();
            const unreadMessages = await this.countUnreadMessages(messages);

            const checkConnectionState = response.user.isSetDevices
                ? CheckConnectionStates.ready
                : CheckConnectionStates.ask;

            this.setState({
                initialVideoChatState: response.videoChat,
                initialRecordingState: response.recording,
                interviewState: response.interview,
                isConnected: true,
                isOwner,
                usersState: response.usersState,
                interviewUser: response.user,
                isChatOpen: response.user.isRecordingUser,
                initialIceServers: response.iceServers,
                unreadMessages,
                messages,
                checkConnectionState,
                videoServer: videoServerUrl,
                videoServerPort,
                timeout: response.timeout,
            });
            console.log('Updating interivew after join');
            this.props.interviewStorageContext.updateInterviewInStorage(interviewId);
        } catch (e) {
            this.setState({ error: e });
        }
    };

    leaveInterview = () => {
        VideoSocketController.disconnect();
        this.setState(DEFAULT_STATE);
    };

    changeCapabilities = async (userId, capabilities) => {
        await VideoSocketController.emit('users:changeCapabilities', { userId, capabilities });
    };

    openKickModal = (user) => {
        this.setState({ kickModal: { isOpened: true, user } });
    };

    closeKickModal = () => {
        this.setState({ kickModal: { isOpened: false, user: null } });
    };

    moveToWaitingRoom = (userId) => {
        VideoSocketController.emit('users:moveToWaitingRoom', { userId });
    };

    kickUser = async (userId) => {
        await VideoSocketController.emit('users:kick', { userId });
        this.closeKickModal();
    };

    closeWelcome = () => {
        this.setState({ showWelcome: false });
    };

    openWelcome = () => {
        this.setState({ showWelcome: true });
    };

    setKicked = (isKicked) => this.setState({ isKicked });

    toConnectionSettings = () => {
        // this.prevDevicePermissionsState = _.get(this.state, 'interviewUser.devicePermissions', undefined);
        this.setState({ checkConnectionState: CheckConnectionStates.inProgress });
    };

    skipConnectionSettings = async () => await this.setDevices({ video: null, audio: null });

    setDevices = async (devices) => {
        await VideoSocketController.emit('users:setDevices', {
            devices,
            // devicePermissions: this.prevDevicePermissionsState,
        });
        this.setState({ checkConnectionState: CheckConnectionStates.ready });
    };

    changeSoundEnabled = async (userId, isSoundEnabled) => {
        const eventName = isSoundEnabled ? 'users:unMute' : 'users:mute';
        await VideoSocketController.emit(eventName, { userId });
    };

    openChat = async () => {
        await this.setStatePromise({ isChatOpen: true, unreadMessages: 0, newMessageForScroll: null });
        await this.sendNotificationOnReadAllMessages();
    };
    closeChat = () => this.setState({ isChatOpen: false, newMessageForScroll: null });

    getMessagesFromChat = async () => {
        const { response } = await VideoSocketController.emit('chat:get');
        return response.messages;
    };

    getReceiversId = () => {
        const {
            usersState: { users },
        } = this.state;
        return users.map((user) => user.id);
    };

    publishMessageToChat = async ({ message, file, echo, privateDestination }) => {
        const receiversId = this.getReceiversId();
        await VideoSocketController.emit('chat:publish', { message, receiversId, file, echo, privateDestination });
        await this.setStatePromise({ newMessageForScroll: { sender: { id: this.props.userContext.user.id } } });
    };

    sendNotificationOnReadMessage = async ({ messageId }) => {
        await VideoSocketController.emit('chat:readMessage', { messageId });
    };

    sendNotificationOnReadAllMessages = async () => {
        await VideoSocketController.emit('chat:readAllMessages');
    };

    receiveNewMessage = async ({ message }) => {
        const userId = this.props.userContext.user.id;
        const { isChatOpen, unreadMessages, messages } = this.state;

        await this.setStatePromise({
            messages: [...messages, message],
            ...(!isChatOpen && { unreadMessages: unreadMessages + 1 }),
            newMessageForScroll: message,
        });

        if (isChatOpen && userId !== message.sender.id) {
            await this.sendNotificationOnReadMessage({ messageId: message.id });
        }
    };

    countMessage = (message) => {
        const userId = this.props.userContext.user.id;
        const {
            sender: { id: senderId },
            readBy,
        } = message;
        return userId === senderId || readBy.includes(userId) ? 0 : 1;
    };

    countUnreadMessages = async (messages) =>
        messages.reduce((accumulator, current) => accumulator + this.countMessage(current), 0);

    setLanguageToListen = (languageToListen, listenToTranslatorOnly) =>
        this.setState({ languageToListen, listenToTranslatorOnly });

    createMessage = (message, cb = _.noop) =>
        this.setState({ messages: [...this.state.messages, message] }, () => cb());
    onFileReady = (fileId, path) => {
        const updatedMessages = this.state.messages.map((message) => {
            if (message.file && message.file.id === fileId) {
                message.file.path = path;
            }
            return message;
        });

        this.setState({ messages: updatedMessages });
    };
    setEditingName = (userId, value) => {
        this.setState({ editingName: { user: userId, value: value } });
    };

    setName = async (userId, value) => {
        const { usersState } = this.state;
        const updatedUsers = usersState.users.map((user) => {
            if (user.id === userId) {
                user.displayName = value;
            }
            return user;
        });

        this.setState(
            { editingName: { user: null, value: '' }, usersState: { ...usersState, users: updatedUsers } },
            () => {
                VideoSocketController.emit('users:setName', { userId, value });
            },
        );
    };

    setInterviewTab = (tab) => this.setState({ interviewTab: tab });

    render() {
        const actions = {
            joinInterview: this.joinInterview,
            leaveInterview: this.leaveInterview,
            finishInterview: this.finishInterview,
            changeCapabilities: this.changeCapabilities,
            kickUser: this.kickUser,
            openKickModal: this.openKickModal,
            closeKickModal: this.closeKickModal,
            setKicked: this.setKicked,
            setDevices: this.setDevices,
            toConnectionSettings: this.toConnectionSettings,
            skipConnectionSettings: this.skipConnectionSettings,
            changeSoundEnabled: this.changeSoundEnabled,
            publishMessageToChat: this.publishMessageToChat,
            openChat: this.openChat,
            closeChat: this.closeChat,
            setLanguageToListen: this.setLanguageToListen,
            createMessage: this.createMessage,
            onFileReady: this.onFileReady,
            setEditingName: this.setEditingName,
            setName: this.setName,
            moveToWaitingRoom: this.moveToWaitingRoom,
            setInterviewTab: this.setInterviewTab,
            closeWelcome: this.closeWelcome,
            openWelcome: this.openWelcome,
            toggleSidebar: this.toggleSidebar,
        };

        return (
            <OnlineInterviewContext.Provider value={{ ...this.state, ...actions }}>
                {this.props.children}
            </OnlineInterviewContext.Provider>
        );
    }
}

export default withConsumers(OnlineInterviewContextProvider, [UserContextConsumer, InterviewStorageContextConsumer]);

export const OnlineInterviewContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <OnlineInterviewContext.Consumer>
                {(context) => <WrappedComponent onlineInterviewContext={context} {...props} />}
            </OnlineInterviewContext.Consumer>
        );
    };
};
