import { debounce } from 'lodash';
import React, { createContext, Component } from 'react';
import makeRequest from 'makeRequest';
import { createRequestHelper } from 'helpers/requestHelper';
import { getToken } from 'helpers/getToken';

export const DashboardContext = createContext({});

const fetchInterviewForDashboardHelper = createRequestHelper('fetchInterviewForDashboard');
const fetchUpcomingInterviewForDashboardHelper = createRequestHelper(
    'fetchUpcomingInterviewForDashboard',
    (upcomingDashboardInterviews) => ({ upcomingDashboardInterviews }),
);
const searchForDashboardHelper = createRequestHelper('searchForDashboard');

class DashboardContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...fetchInterviewForDashboardHelper.initialState,
            ...fetchUpcomingInterviewForDashboardHelper.initialState,
            ...searchForDashboardHelper.initialState,
            dashboardDate: new Date(),
            dashboardInterviews: {},
            upcomingDashboardInterviews: { list: [], totalCount: 0 },
            dashboardInterviewFetched: false,
            upcomingInterviewsFetched: false,
            displaySearchBar: false,
        };

        window.dashc = this;
    }

    fetchInterviewForDashboard = async ({ key }) => {
        const path = 'interviews/fetchInterviewForDashboard';
        const authToken = getToken();
        this.setState(fetchUpcomingInterviewForDashboardHelper.processing());
        this.setState({ dashboardInterviewFetched: false });
        try {
            const { totalCount, list } = await makeRequest({ path, data: { key, authToken } });
            const { dashboardInterviews } = this.state;
            dashboardInterviews[key] = { totalCount, list };
            this.setState({
                ...fetchInterviewForDashboardHelper.result(true),
                dashboardInterviews,
                dashboardInterviewFetched: true,
                displaySearchBar: true,
            });
        } catch (error) {
            fetchInterviewForDashboardHelper.error(error);
            this.setState({
                dashboardInterviewFetched: true,
            });
        }
    };

    fetchUpcomingInterviewForDashboard = async () => {
        const path = 'interviews/fetchUpcomingInterviewForDashboard';
        const authToken = getToken();
        this.setState(fetchUpcomingInterviewForDashboardHelper.processing());
        this.setState({ upcomingInterviewsFetched: false });
        try {
            const { status, result, error } = await makeRequest({ path, data: { authToken } });
            if (!status) throw Error(error);
            this.setState({
                ...fetchUpcomingInterviewForDashboardHelper.result(result),
                upcomingInterviewsFetched: true,
            });
        } catch (error) {
            fetchUpcomingInterviewForDashboardHelper.error(error);
            this.setState({ upcomingInterviewsFetched: true });
        }
    };

    searchForDashboard = async (query) => {
        if (!query) {
            this.clearSearchForDashboard();
            return;
        }
        const path = 'interviews/searchForDashboard';
        const authToken = getToken();
        this.setState(searchForDashboardHelper.processing());
        try {
            const result = await makeRequest({ path, data: { query, authToken } });
            this.setState(searchForDashboardHelper.result({ ...result, query }));
        } catch (error) {
            searchForDashboardHelper.error(error && error.message);
        }
    };

    clearSearchForDashboard = () => this.setState(searchForDashboardHelper.initialState);
    clearDashboardInterviews = () => this.setState({ dashboardInterviews: {} });
    clearDashboardSearchTab = () => this.setState({ displaySearchBar: false });
    setDashboardDate = (date) => this.setState({ dashboardDate: date });

    render() {
        const actions = {
            fetchInterviewForDashboard: this.fetchInterviewForDashboard,
            fetchUpcomingInterviewForDashboard: this.fetchUpcomingInterviewForDashboard,
            clearDashboardInterviews: this.clearDashboardInterviews,
            searchForDashboard: debounce(this.searchForDashboard, 500),
            clearSearchForDashboard: this.clearSearchForDashboard,
            setDashboardDate: this.setDashboardDate,
            clearDashboardSearchTab: this.clearDashboardSearchTab,
        };

        return (
            <DashboardContext.Provider value={{ ...this.state, ...actions }}>
                {this.props.children}
            </DashboardContext.Provider>
        );
    }
}

export default DashboardContextProvider;

export const DashboardContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <DashboardContext.Consumer>
                {(context) => <WrappedComponent interviewContext={context} {...props} />}
            </DashboardContext.Consumer>
        );
    };
};
