import { Footer } from "../../Footer/Footer";
import Skeleton from "../../Skeleton";
import styles from "./LoginPage.module.scss";

const LoginPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Skeleton className={styles.logo} />
            </div>
            <div className={styles.content}>
                <Skeleton className={styles.form} />
            </div>
            <Footer />
        </div>
    );
}

export default LoginPage;