import styles from "./ProfileEditPage.module.scss"
import { PageLayout } from "../../PageLayout/PageLayout"
import Skeleton from "../../Skeleton";

const Input = () => {
    return <div className={styles.inputWrapper}>
        <Skeleton style={{ width: '5rem', height: '1rem' }} />
        <Skeleton style={{ width: '100%', height: '3rem' }} />
    </div>
}

const ProfileEditPage = () => {
    return (<PageLayout>
        <div className={styles.container}>
            <div className={styles.section} style={{ maxWidth: "23rem" }}>
                <Skeleton count={6} containerClassName={styles.menuItems} className={styles.menuItem} />
            </div>
            <div className={styles.section} style={{ maxWidth: "60rem", gap: '3rem' }}>
                <div className={styles.header}>
                    <Skeleton count={6} containerClassName={styles.headerItems} className={styles.headerItem} />
                    <Skeleton className={styles.headerBtn} />
                </div>
                <div className={styles.content}>
                    <div className={styles.contentTitle}>
                        <Skeleton style={{ width: '5rem', height: '1rem' }} />
                    </div>
                    <div className={styles.contentBody}>
                        <div className={styles.contentRaw}>
                            <Input />
                            <Input />
                        </div>
                        <div className={styles.contentRaw}>
                            <Input />
                            <Input />
                        </div>
                        <div className={styles.contentRaw}>
                            <Input />
                            <Input />
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.contentTitle}>
                        <Skeleton style={{ width: '5rem', height: '1rem' }} />
                    </div>
                    <div className={styles.contentBody}>
                        <div className={styles.contentRaw}>
                            <Input />
                        </div>
                        <div className={styles.contentRaw}>
                            <Input />
                            <Input />
                        </div>
                        <div className={styles.contentRaw}>
                            <Input />
                            <Input />
                        </div>
                        <div className={styles.contentRaw}>
                            <Input />
                            <Input />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>)
}

export default ProfileEditPage;