export function getApiPrefix() {
    return process.env.REACT_APP_BACKEND_URL || '/api';
}

export function getVideoServerURL() {
    if (process.env.REACT_APP_IS_LOCAL) return 'http://localhost:8000';
    return getBackendSignalURL();
}

export function getBackendSignalURL() {
    return process.env.REACT_APP_BACKEND_URL;
}

export function getChatSignalURL() {
    return process.env.REACT_APP_CHAT_SIGNAL_URL || 'http://localhost:3002';
}

export function getWatcherSignalURL() {
    return process.env.REACT_APP_WATCHER_SIGNAL_URL || 'http://localhost:3003';
}

export function getChatSignalPath() {
    return process.env.REACT_APP_CHAT_SIGNAL_PATH || '/socket.io';
}

export function makeURL(path) {
    if (!path) return '/defaultAvatar.svg';
    return path;
}

export function makeCoverURL(path) {
    if (!path) return '/defProjectPicture.svg';
    return path;
}

export function getDefaultCoverUrl() {
    return (
        process.env.DEFAULT_PROJECT_IMAGE_URL ||
        'https://vov-develop.s3.eu-west-2.amazonaws.com/project/defaultProjectCover.png'
    );
}

export function getWaitingRoomSignalPath() {
    return process.env.REACT_APP_WAITING_ROOM_SIGNAL_PATH || '/waitingRoom';
}

export function getBackendSocketPath() {
    return process.env.REACT_APP_BACKEND_SIGNAL_PATH;
}
