class EventEmitter {
    constructor(name) {
        this.listeners = {};
        if (name) {
            window[name] = this;
        }
    }

    on(event, listener) {
        if (this.listeners[event]) {
            this.listeners[event].push(listener);
            return;
        }
        this.listeners[event] = [listener];
    }

    emit(event, data) {
        if (!this.listeners[event]) return;
        this.listeners[event].forEach(listener => listener(data));
    }

    off(event, listener) {
        if (listener) {
            this.listeners[event] = this.listeners[event].filter(l => l !== listener);
            if (!this.listeners[event].length) delete this.listeners[event];
            return;
        }
        delete this.listeners[event];
    }
}

export default EventEmitter;
