const ROLES = {
    companyAdmin: 'CompanyAdmin',
    companyAdminAuth: 'company-admin',
    respondent: 'Respondent',
    moderator: 'Moderator',
    translator: 'Translator',
    admin: 'Admin',
    superAdmin: 'SuperAdmin',
    observer: 'Observer',
    external: 'ExternalResource',
    externalModerator: 'ExternalModerator',
    externalTranslator: 'ExternalTranslator',
};

export default ROLES;

export const RESOURCE_ROLES = {
    respondent: 'Respondent',
    moderator: 'Moderator',
    translator: 'Translator',
};

export const RESOURCE_TYPES_URL = {
    participant: 'participant',
    moderator: 'moderator',
    interpreter: 'interpreter',
};

export const outsideCompanyUserRoles = [ROLES.respondent, ROLES.moderator, ROLES.translator];

function roleResolver(role1, role2) {
    return Boolean(role1 && role2 && role1.toLowerCase() === role2.toLowerCase());
}

export function isUser(userRole) {
    switch (true) {
        case isCompanyAdmin(userRole):
        case isRespondent(userRole):
        case isModerator(userRole):
        case isTranslator(userRole):
        case isObserver(userRole):
            return true;
        default:
            return false;
    }
}

export function isObserver(userRole) {
    return roleResolver(userRole, ROLES.observer);
}

export function isCompanyAdmin(userRole) {
    return roleResolver(userRole, ROLES.companyAdmin);
}

export function isTeamAdmin(user) {
    return user && user.team && user.team.isAdmin;
}

export function isTeamMember(user) {
    return user && user.team && user.team.role && !user.team.isAdmin;
}

export function isRespondent(userRole) {
    return roleResolver(userRole, ROLES.respondent);
}

export function isModerator(userRole) {
    return roleResolver(userRole, ROLES.moderator);
}

export function isTranslator(userRole) {
    return roleResolver(userRole, ROLES.translator);
}

export function isAdmin(userRole) {
    return roleResolver(userRole, ROLES.admin);
}

export function isSuperAdmin(userRole, isSuperAdmin) {
    return isSuperAdmin && roleResolver(userRole, ROLES.admin);
}

export function isCompanyType(role, accountType) {
    return isCompanyAdmin(role) && accountType !== 'individual';
}

export function isModeratorCompanyType(role, accountType) {
    return isModerator(role) && accountType !== 'individual';
}

export function isTranslatorCompanyType(role, accountType) {
    return isTranslator(role) && accountType !== 'individual';
}

export function isExternal(role) {
    return role === ROLES.external;
}

export function isExternalModerator(role) {
    return role === ROLES.externalModerator;
}

export function isExternalTranslator(role) {
    return role === ROLES.externalTranslator;
}

export function isAnyCompanyType(role, accountType) {
    return (isCompanyAdmin(role) || isTranslator(role) || isModerator(role)) && accountType !== 'individual';
}

export function isResource(role) {
    const resourceRoles = [RESOURCE_ROLES.moderator, RESOURCE_ROLES.respondent, RESOURCE_ROLES.translator];
    return resourceRoles.includes(role);
}
