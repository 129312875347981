import { INTERVIEW_TYPE, MODERATOR_TYPES } from '../../components/Interview/NewInterviewCreationForm/constants';

export const getModeratorTypesByInterviewType = (interviewType) => {
    if (interviewType === INTERVIEW_TYPE.VIDEO_INTERVIEW) {
        return [MODERATOR_TYPES.TEAM_MEMBER_MODERATOR, MODERATOR_TYPES.EXTERNAL_MODERATOR];
    }
    if (interviewType === INTERVIEW_TYPE.VIDEO_IDI) {
        return [
            MODERATOR_TYPES.MODERATOR_NEEDED,
            MODERATOR_TYPES.TEAM_MEMBER_MODERATOR,
            MODERATOR_TYPES.EXTERNAL_MODERATOR,
        ];
    }
    if (interviewType === INTERVIEW_TYPE.VFGD) {
        return [
            MODERATOR_TYPES.MODERATOR_NEEDED,
            MODERATOR_TYPES.TEAM_MEMBER_MODERATOR,
            MODERATOR_TYPES.EXTERNAL_MODERATOR,
        ];
    }
    if (interviewType === INTERVIEW_TYPE.ONLINE_EXTERNAL) {
        return [
            MODERATOR_TYPES.MODERATOR_NEEDED,
            MODERATOR_TYPES.TEAM_MEMBER_MODERATOR,
            MODERATOR_TYPES.EXTERNAL_MODERATOR,
        ];
    }
    if (interviewType === INTERVIEW_TYPE.ONLINE_DIARY) {
        return [MODERATOR_TYPES.MODERATOR_NEEDED, MODERATOR_TYPES.TEAM_MEMBER_MODERATOR, MODERATOR_TYPES.NOT_REQUIRED];
    }
    if (interviewType === INTERVIEW_TYPE.OFFLINE) {
        return [MODERATOR_TYPES.MODERATOR_NEEDED, MODERATOR_TYPES.NOT_REQUIRED];
    }
    if (interviewType === INTERVIEW_TYPE.OFFLINE_FOCUS) {
        return [MODERATOR_TYPES.MODERATOR_NEEDED, MODERATOR_TYPES.NOT_REQUIRED];
    }
    if (interviewType === INTERVIEW_TYPE.OFFLINE_HOME) {
        return [MODERATOR_TYPES.MODERATOR_NEEDED, MODERATOR_TYPES.NOT_REQUIRED];
    }
    return [];
};
