export const DISPUTE_STATES = {
    open: 'open',
    closed: 'closed',
    waitingResource: 'waitingResource',
    waitingClient: 'waitingClient',
    supportRequired: 'supportRequired',
};

export const DISPUTE_STATES_LABELS = {
    open: 'Open',
    closed: 'Closed',
    waitingResource: 'Waiting for resource',
    waitingClient: 'Waiting client',
    supportRequired: 'Support required',
};
