import {createRequestHelper} from 'helpers/requestHelper';
import methodFactory from 'helpers/methodFactory';
import {capitalizeFirstLetter as capitalize} from 'helpers/formatter';

export function insertStateAndMethods() {
    const helpers = {
        addUBORequestHelper: createRequestHelper('addUBO'),
        editUBORequestHelper: createRequestHelper('editUBO'),
        removeUBORequestHelper: createRequestHelper('removeUBO'),
        confirmDeclarationHelper: createRequestHelper('confirmDeclaration'),
        addAccountRequestHelper: createRequestHelper('addBankAccount'),
        deleteAccountRequestHelper: createRequestHelper('deleteBankAccount'),
        wiretransferRequestHelper: createRequestHelper('wiretransfer'),
        addCardRequestHelper: createRequestHelper('addCard'),
        getCardsRequestHelper: createRequestHelper('getCards', result => ({cards: result})),
        getDeclarationRequestHelper: createRequestHelper('getDeclaration', result => ({declaration: result})),
        getPayInRequestHelper: createRequestHelper('getPayIn'),
        deleteCardRequestHelper: createRequestHelper('deleteCard'),
        requestPayoutHelper: createRequestHelper('requestPayout'),
        getPlansHelper: createRequestHelper('getPlans', result => ({plans: result})),
        updatePlansHelper: createRequestHelper('updatePlans'),
        changePlanHelper: createRequestHelper('changePlan'),
        renewPlanHelper: createRequestHelper('renewPlan'),
        changeDefaultPaymentHelper: createRequestHelper('changeDefaultPayment'),
        activatePlanHelper: createRequestHelper('activatePlan'),
        getHistoryHelper: createRequestHelper('getHistory', updateHistory.bind(this)),
        payInWebRequestHelper: createRequestHelper('payInWeb'),
        cancelDowngrade: createRequestHelper('cancelDowngrade'),
    };

    const defaultPaymentState = {
        ...Object.values(helpers).reduce((acc, el) => ({...acc, ...el.initialState}), {}),
        creditsToBuy: null,
        buyCreditsError: null,
        balance: 0,
        balanceById: 0,
        frozen: 0,
        bankAccounts: [],
        payments: [],
        declaration: null,
        cards: [],
        paymentHistory: {count: 0, list: [], type: null},
        plans: [],
        locked: false,
    };

    const methods = [
        {name: 'getDeclaration', path: 'payment/getDeclaration', helper: helpers.getDeclarationRequestHelper},
        {
            name: 'addUBO',
            path: 'payment/addUBO',
            helper: helpers.addUBORequestHelper,
            callback: this.props.userContext.updateDeclarationState,
        },
        {
            name: 'editUBO',
            path: 'payment/editUBO',
            helper: helpers.editUBORequestHelper,
            callback: this.props.userContext.updateDeclarationState,
        },
        {name: 'wiretransfer', path: 'payment/wiretransfer', helper: helpers.wiretransferRequestHelper},
        {name: 'getCards', path: 'payment/getCards', helper: helpers.getCardsRequestHelper},
        {
            name: 'deleteCard',
            path: 'payment/deleteCard',
            helper: helpers.deleteCardRequestHelper,
            callback: cards => this.setState({cards}),
        },
        {name: 'requestPayout', path: 'payment/requestPayout', helper: helpers.requestPayoutHelper},
        {name: 'getHistory', path: 'payment/getHistory', helper: helpers.getHistoryHelper},
        {name: 'getPlans', path: 'payment/getPlans', helper: helpers.getPlansHelper},
        {name: 'updatePlans', path: 'payment/updatePlans', helper: helpers.updatePlansHelper},
        {
            name: 'activatePlan',
            path: 'payment/activatePlan',
            helper: helpers.activatePlanHelper,
            callback: updatePlanStatus.bind(this),
        },
        {
            name: 'changeDefaultPayment',
            path: 'payment/changeDefaultPayment',
            helper: helpers.changeDefaultPaymentHelper,
            callback: this.props.userContext.getUserInfo,
        },
        {
            name: 'renewPlan',
            path: 'payment/renewPlan',
            helper: helpers.renewPlanHelper,
            callback: this.props.userContext.getUserInfo,
        },
        {
            name: 'changePlan',
            path: 'payment/changePlan',
            helper: helpers.changePlanHelper,
            callback: this.props.userContext.getUserInfo,
        },
        {
            name: 'removeUBO',
            path: 'payment/removeUBO',
            helper: helpers.removeUBORequestHelper,
            callback: this.props.userContext.updateDeclarationState,
        },
        {
            name: 'confirmDeclaration',
            path: 'payment/submitDeclaration',
            helper: helpers.confirmDeclarationHelper,
            callback: this.props.userContext.updateDeclarationState,
        },
        {
            name: 'addBankAccount',
            path: 'payment/addAccount',
            helper: helpers.addAccountRequestHelper,
            callback: this.getBankAccounts,
        },
        {
            name: 'deleteBankAccount',
            path: 'payment/deleteAccount',
            helper: helpers.deleteAccountRequestHelper,
            callback: this.getBankAccounts,
        },
        {
            name: 'cancelDowngrade',
            path: 'payment/cancelDowngrade',
            helper: helpers.cancelDowngrade,
            callback: this.props.userContext.getUserInfo,
        },
    ];

    this.factoryActions = {};

    for (let method of methods) {
        let methodVar, clearMethodVar;
        [methodVar, clearMethodVar] = methodFactory.call(this, method);
        const clearMethodName = `clear${capitalize(method.name)}`;

        this[method.name] = methodVar;
        this[clearMethodName] = clearMethodVar;
        this.factoryActions[method.name] = methodVar;
        this.factoryActions[clearMethodName] = clearMethodVar;
    }

    this.state = defaultPaymentState;
}

function updatePlanStatus(planId) {
    const updatedPlans = this.state.plans.map(plan => (plan.id === planId ? {...plan, active: !plan.active} : plan));
    this.setState({plans: updatedPlans});
}

function updateHistory(result) {
    if (result.type !== this.state.paymentHistory.type) return {paymentHistory: result};
    return {
        paymentHistory: {
            count: result.count,
            type: result.type,
            list: [...this.state.paymentHistory.list, ...result.list],
        },
    };
}
