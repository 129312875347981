import { Footer } from '../../Footer/Footer';
import Header from '../../Header/Header';
import Skeleton from '../../Skeleton';
import styles from './DashboardPage.module.scss';

const DashboardPage = () => {
    return <div className={styles.container}>
        <Header />
        <div className={styles.content}>
            <Skeleton containerClassName={styles.calendarContainer} className={styles.calendar} />
        </div>
        <Footer />
    </div>
}

export default DashboardPage;