import { Footer } from '../../Footer/Footer';
import Header from '../../Header/Header';
import Skeleton from '../../Skeleton';
import styles from './ChatPage.module.scss';

const ChatPage = () => {
    return <div className={styles.container}>
        <Header />
        <div className={styles.content}>
            <Skeleton containerClassName={styles.chatContainer} className={styles.chat} />
        </div>
        <Footer />
    </div>
}

export default ChatPage;