import React, { createContext, Component } from 'react';

import uploadFile from 'uploadFile';
import makeRequest from 'makeRequest';
import { UserContextConsumer } from './UserContext';
import { createRequestHelper, requestHelperWithInitialState } from 'helpers/requestHelper';
import { isOrderHasStatus } from 'constants/OrderStatuses';
import { getApiPrefix } from '../components/common/apiPrefix';
import { getToken } from 'helpers/getToken';

const fetchOrdersRequestHelper = createRequestHelper('fetchOrders');
const changeOrderStatusRequestHelper = createRequestHelper('changeOrderStatus');
const fetchFilesRequestHelper = createRequestHelper('fetchFiles');
const uploadFileToOrderRequestHelper = createRequestHelper('uploadFileToOrder');
const removeFileRequestHelper = createRequestHelper('removeFile');
const fetchFilesForInterviewRequestHelper = requestHelperWithInitialState('fetchFilesForInterview', []);

export const OrderContext = createContext({});

class OrderContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...fetchOrdersRequestHelper.initialState,
            ...changeOrderStatusRequestHelper.initialState,
            ...fetchFilesRequestHelper.initialState,
            ...uploadFileToOrderRequestHelper.initialState,
            ...removeFileRequestHelper.initialState,
            ...fetchFilesForInterviewRequestHelper.initialState,
        };
    }

    componentDidMount() {}

    fetchOrders = async ({ description, status }) => {
        const path = 'order/list';
        const data = {
            authToken: getToken(),
            description,
            ...(isOrderHasStatus(status) && { status }),
        };

        this.setState({ ...fetchOrdersRequestHelper.processing(), fetchOrdersResult: this.state.fetchOrdersResult });

        try {
            const result = await makeRequest({ path, data });
            this.setState({ ...fetchOrdersRequestHelper.result(result) });
        } catch (err) {
            this.setState({ ...fetchOrdersRequestHelper.error(err) });
        }
    };

    clearFetchOrders = () => this.setState({ ...fetchOrdersRequestHelper.clear() });

    changeOrderStatus = async ({ id, status }) => {
        const path = 'order/changeStatus';
        const data = { authToken: getToken(), id, status };

        this.setState({ ...changeOrderStatusRequestHelper.processing() });

        try {
            const result = await makeRequest({ path, data });
            this.setState({ ...changeOrderStatusRequestHelper.result(result) });
        } catch (err) {
            this.setState({ ...changeOrderStatusRequestHelper.error(err) });
        }
    };

    fetchFiles = async (orderId) => {
        const path = 'order/fetchFiles';
        const data = { authToken: getToken(), orderId };

        this.setState({ ...fetchFilesRequestHelper.processing(), fetchFilesResult: this.state.fetchFilesResult });

        try {
            const result = await makeRequest({ path, data });
            this.setState({ ...fetchFilesRequestHelper.result(result) });
        } catch (err) {
            this.setState({ ...fetchFilesRequestHelper.error(err) });
        }
    };

    fetchFilesForInterview = async ({ interviewId, sessionsIds }) => {
        const path = 'order/fetchFilesForInterview';
        const data = { authToken: getToken(), interviewId, sessionsIds };

        this.setState(fetchFilesForInterviewRequestHelper.processing());

        try {
            const result = await makeRequest({ path, data });
            this.setState(fetchFilesForInterviewRequestHelper.result(result));
        } catch (err) {
            this.setState(fetchFilesForInterviewRequestHelper.error(err));
        }
    };
    clearFetchFilesForInterview = () => this.setState(fetchFilesForInterviewRequestHelper.clear());

    clearFetchFiles = () => this.setState({ ...fetchFilesRequestHelper.clear() });

    uploadFileToOrder = async ({ orderId, file }) => {
        const path = 'order/uploadFile';
        const data = new FormData();
        data.append('authToken', getToken());
        data.append('orderId', orderId);
        data.append('file', file);

        this.setState(uploadFileToOrderRequestHelper.processing());

        try {
            const result = await uploadFile({ path, data });
            this.setState(uploadFileToOrderRequestHelper.result(result));
        } catch (err) {
            this.setState(uploadFileToOrderRequestHelper.error(err));
        }
    };

    removeFile = async ({ fileId, orderId }) => {
        const path = 'order/removeFile';
        const authToken = getToken();

        this.setState(removeFileRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { fileId, orderId, authToken } });
            this.setState({
                ...removeFileRequestHelper.result(result),
                ...fetchFilesRequestHelper.result(result),
            });
        } catch (e) {
            this.setState(removeFileRequestHelper.error(e));
        }
    };

    clearRemoveFile = () => this.setState(removeFileRequestHelper.clear());

    getFileLink = (key, name) => {
        const authToken = getToken();
        const nameParam = name ? `&name=${name}` : '';

        return `${getApiPrefix()}/order/downloadFile?key=${key}&authToken=${authToken}` + nameParam;
    };

    downloadFile = (key, name) => {
        document.location.href = this.getFileLink(key, name);
    };

    render() {
        const actions = {
            fetchOrders: this.fetchOrders,
            clearFetchOrders: this.clearFetchOrders,
            changeOrderStatus: this.changeOrderStatus,
            fetchFiles: this.fetchFiles,
            clearFetchFiles: this.clearFetchFiles,
            uploadFileToOrder: this.uploadFileToOrder,
            fetchFilesForInterview: this.fetchFilesForInterview,
            removeFile: this.removeFile,
            clearRemoveFile: this.clearRemoveFile,
            downloadFile: this.downloadFile,
            getFileLink: this.getFileLink,
            clearFetchFilesForInterview: this.clearFetchFilesForInterview,
        };

        return (
            <OrderContext.Provider value={{ ...this.state, ...actions }}>{this.props.children}</OrderContext.Provider>
        );
    }
}

export default UserContextConsumer(OrderContextProvider);
