import React from 'react';
import UserContextProvider from 'context/UserContext';
import ProjectContextProvider from 'context/ProjectContext';
import PaymentContextProvider from 'context/Payment/PaymentContext';
import ScreenerContextProvider from 'context/ScreenerContext';
import OnlineInterviewProvider from 'context/OnlineInterview/OnlineInterviewContext';
import ChatContextProvider from 'context/ChatContext';
import DisputesContextProvider from 'context/DisputesContext';
import OrderContextProvider from 'context/OrderContext';
import NotificationsContextProvider from 'context/NotificationsContext';
import VouchersContextProvider from 'context/VouchersContext';
import TeamContextProvider from 'context/TeamContext';
import PromoCodesContextProvider from 'context/PromoCodesContext';
import BrowserContextProvider from 'context/OnlineInterview/BrowserContext';
import WaitingRoomContextProvider from 'context/OnlineInterview/WaitingRoomContext';
import LiveInterviewContextProvider from 'context/Interview/LiveInterviewContext';
import InterviewStorageContextProvider from 'context/Interview/storage/InterviewStorageContext';
import InterviewActionsContextProvider from 'context/Interview/InterviewActionContext';
import InterviewContextProvider from 'context/Interview/InterviewContext';
import InterviewPaymentContextProvider from 'context/Interview/InterviewPaymentContext';
import DashboardContext from 'context/Interview/DashboardContext';

import Router from 'components/Router/Router';
import { BrowserRouter } from 'react-router-dom';

function App() {
    const providers = [
        UserContextProvider,
        ChatContextProvider,
        ProjectContextProvider,
        TeamContextProvider,
        InterviewStorageContextProvider,
        InterviewContextProvider,
        InterviewActionsContextProvider,
        InterviewPaymentContextProvider,
        PaymentContextProvider,
        ScreenerContextProvider,
        OnlineInterviewProvider,
        DisputesContextProvider,
        OrderContextProvider,
        NotificationsContextProvider,
        VouchersContextProvider,
        PromoCodesContextProvider,
        BrowserContextProvider,
        WaitingRoomContextProvider,
        LiveInterviewContextProvider,
        DashboardContext,
    ];

    return renderContextProviderList(providers, <Router />);
}

function renderContextProviderList(providers, children) {
    if (providers.length === 0) {
        return children;
    }

    const ConcreteContextProvider = providers[0];
    return (
        <BrowserRouter>
            <ConcreteContextProvider>{renderContextProviderList(providers.slice(1), children)}</ConcreteContextProvider>
        </BrowserRouter>
    );
}

export default App;
