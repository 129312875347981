import LoadingSpinner from "components/common/LoadingSpinner/LoadingSpinner";
import LoadersToRoute from "components/common/Skeleton/constants";
import React, { Suspense, useMemo } from "react";

const DefaultFallback = () => <LoadingSpinner style={{ position: 'fixed', top: '0', left: '50%' }} />;

export function withRouteSuspense(
    Component,
    route,
) {
    const WrappedComponent = (props) => {
        const Fallback = useMemo(() => LoadersToRoute[route] ?? DefaultFallback, [route])
        return (
            <Suspense fallback={<Fallback />}>
                <Component {...props} />
            </Suspense>
        )
    }
    WrappedComponent.displayName = `withRouteSuspense(${Component.displayName || Component.name || 'Component'})`;

    return WrappedComponent;
}
