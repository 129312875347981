import React from 'react';
import Skeleton from 'components/common/Skeleton/Skeleton';

import styles from './Featured.module.scss';
import { useTranslation } from 'react-i18next';

export const Featured = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.featuredInterviews}>
            <Skeleton containerClassName={styles.title} />
            <div className={styles.gridRow}>
                <Skeleton className={styles.item} />
                <Skeleton className={styles.item} />
                <Skeleton className={styles.item} />
            </div>
        </div>
    );
};
