const SUPPORTED_LANGUAGES = [
    'af',
    'bg',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'es_MX',
    'fi',
    'fr',
    'fr_BE',
    'hi',
    'hr',
    'hu',
    'is',
    'it',
    'ja',
    'ko',
    'nb_NO',
    'nl',
    'pl',
    'pt_BR',
    'pt_PT',
    'ro',
    'ru',
    'sv',
    'tr',
    'zu',
];
export default SUPPORTED_LANGUAGES;

export const LanguageSelfName = {
    en: 'English',
    af: 'Afrikaans',
    bg: 'български',
    cs: 'čeština',
    da: 'dansk',
    de: 'Deutsch',
    el: 'Ελληνικά',
    es: 'español',
    es_MX: 'español',
    fr: 'Français',
    fr_BE: 'Français',
    hi: 'हिंदी',
    hr: 'hrvatski',
    hu: 'Magyar',
    is: 'Íslensku',
    it: 'Italiano',
    ja: '日本人',
    nb_NO: 'Norsk nynorsk',
    nl: 'Nederlands',
    pl: 'Polskie',
    pt_BR: 'português',
    pt_PT: 'português',
    ro: 'Română',
    ru: 'Русский',
    sv: 'Svenska',
    tr: 'Türk',
    zu: 'IsiZulu',
    ko: '한국어',
    fi: 'Suomi',
};
