import AdminHeader from "../AdminHeader/AdminHeader";
import styles from "./AdminPageLayout.module.scss";

const AdminPageLayout = ({ children }) => {
    return <div className={styles.container}>
        <AdminHeader />
        <div className={styles.content}>
            {children}
        </div>
    </div>
}

export default AdminPageLayout;