export default function collectBrowserData() {
    return {
        AcceptHeader: "*/*",
        JavaEnabled: navigator.javaEnabled(),
        Language: navigator.language,
        ColorDepth: window.screen.colorDepth,
        ScreenHeight: window.screen.height,
        ScreenWidth: window.screen.width,
        TimeZoneOffset: new Date().getTimezoneOffset().toString(),
        UserAgent: navigator.userAgent,
        JavascriptEnabled: true
    }
}
