const statuses = {
    deleted: 'Deleted',
    requested: 'Requested',
    approved: 'Approved',
};

export const isDeleted = status => status === statuses.deleted;
export const isRequested = status => status === statuses.requested;
export const isApproved = status => status === statuses.approved;

export default statuses;
