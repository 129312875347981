export const ACCOUNT_TYPES_COMPANY_ADMIN = {
    individual: 'Individual',
    company: 'Company',
};

export const ACCOUNT_TYPES_COMPANY_ADMIN_FILTERS = {
    all: 'All types',
    ...ACCOUNT_TYPES_COMPANY_ADMIN,
};

export const ACCOUNT_TYPES_RESPONDENT = {
    consumer: 'Consumer',
    b2b: 'B2B',
    medical: 'Medical',
};

export const ACCOUNT_TYPES_RESPONDENT_FILTERS = {
    all: 'All types',
    ...ACCOUNT_TYPES_RESPONDENT,
};
