import AdminPageLayout from "../../AdminPageLayout/AdminPageLayout";
import Skeleton from "../../Skeleton";
import styles from "./AdminPlansPage.module.scss";

const AdminPlansPage = () => {
    return (
        <AdminPageLayout>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Skeleton style={{ height: '2.5rem', width: '6rem' }} />
                    <div />
                </div>
                <Skeleton count={15} containerClassName={styles.rows} className={styles.rowItem} />
            </div>
        </AdminPageLayout>
    )
}

export default AdminPlansPage;