import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton from "../../Skeleton";
import styles from "./Register.module.scss";
import { Footer } from "../../Footer/Footer";

const Register = ({ match }) => {
    const { role } = match.params;
    if (role) {
        return <div className={styles.container}>
            <div className={styles.header}>
                <Skeleton className={styles.logo} />
            </div>
            <div className={styles.content}>
                <Skeleton className={styles.form} />
            </div>
            <Footer />
        </div>
    }

    return <div className={styles.container}>
        <div className={styles.header}>
            <Skeleton className={styles.logo} />
        </div>

        <div className={styles.content}>
            <div className={styles.contentWrapper}>
                {/* <div className={styles.title}> */}
                <Skeleton className={styles.title} />
                {/* </div> */}
                <div className={styles.roles}>
                    <Skeleton className={styles.role} />
                    <Skeleton className={styles.role} />
                    <Skeleton className={styles.role} />
                </div>
                <div className={styles.loginWrapper}>
                    <div className={styles.login}>
                        <Skeleton className={styles.loginText} />
                        <Skeleton className={styles.loginBtn} />
                    </div>
                    <div className={styles.login}>
                        <Skeleton className={styles.loginText} />
                        <Skeleton className={styles.loginBtn} />
                    </div>
                </div>

            </div>
        </div>

        <Footer />
    </div>

}

export default withRouter(Register)