export const titles = {
    members: [{label: 'Name', value: 'name'}, {label: 'Email', value: 'email'}, {label: 'Role', value: 'role'}],
    invitations: [{label: 'Email', value: 'email'}, {label: 'Role', value: 'role'}, {label: 'Status', value: 'status'}],
};

export const statuses = {
    expired: {
        label: 'profile:status.expired',
        value: 'expired',
    },
    pending: {
        label: 'profile:status.pending',
        value: 'pending',
    },
};

export const tabs = {
    members: {
        label: 'profile:text.teamHeader',
        value: 'members',
    },
    invitations: {
        label: 'profile:text.invitations',
        value: 'invitations',
    },
};
