import React from 'react';
import Skeleton from 'components/common/Skeleton/Skeleton';

import styles from './Public.module.scss';
import classNames from 'classnames';

export const Public = () => {
    return (
        <div className={styles.publicInterviewsList}>
            {[...Array(6)].map((item, index) => (
                <Skeleton key={index} className={styles.item} />
            ))}
        </div>
    );
};

export const VerticalPublic = () => {
    return (
        <div className={classNames(styles.publicInterviewsList, styles.verticalView)}>
            {[...Array(9)].map((item, index) => (
                <Skeleton key={index} containerClassName={styles.card} />
            ))}
        </div>
    );
};
