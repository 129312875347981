import React, {createContext, Component} from 'react';

import {withConsumers} from 'helpers/contexts';
import {UserContextConsumer} from 'context/UserContext';

import LiveInterviewsSocket from 'controllers/LiveInterviewsSocket';
import {getBackendSignalURL} from 'components/common/apiPrefix';
import {InterviewStorageContextConsumer} from 'context/Interview/storage/InterviewStorageContext';
import {isAdmin} from 'constants/UserRoles';
import {getToken} from 'helpers/getToken';

export const LiveInterviewContext = createContext({});

const DEFAULT_STATE = {
    liveInterviews: {},
};

class LiveInterviewContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = DEFAULT_STATE;
        window.lic = this;
    }

    componentDidUpdate() {
        const {user} = this.props.userContext;
        if (!user) return;
        if (isAdmin(user.role) && !LiveInterviewsSocket.socket) {
            this.connectToWatcher();
        }
        if (!isAdmin(user.role)) {
            this.disconnectFromWatcher();
        }
    }

    async connectToWatcher() {
        const {user} = this.props.userContext;
        const query = {authToken: getToken(), userId: user.id};
        await LiveInterviewsSocket.connect(getBackendSignalURL(), query, '/watcher');
        this.applyListeners();
    }

    disconnectFromWatcher() {
        LiveInterviewsSocket.removeAllListeners();
        LiveInterviewsSocket.disconnect();
    }

    applyListeners() {
        LiveInterviewsSocket.on('watcher::state', this.updateState.bind(this));
    }

    updateState(state) {
        this.setState({liveInterviews: state});
        const {updateInterviewsFilterAndFetch} = this.props.interviewStorageContext;
        updateInterviewsFilterAndFetch({});
    }

    render() {
        const actions = {};

        return (
            <LiveInterviewContext.Provider value={{...this.state, ...actions}}>
                {this.props.children}
            </LiveInterviewContext.Provider>
        );
    }
}

export default withConsumers(LiveInterviewContextProvider, [UserContextConsumer, InterviewStorageContextConsumer]);
