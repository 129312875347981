import AdminPageLayout from "../../AdminPageLayout/AdminPageLayout";
import Skeleton from "../../Skeleton";
import styles from "./DisputesPage.module.scss";

const DisputesPage = () => {
    return (
        <AdminPageLayout>
            <div className={styles.container}>
                <Skeleton style={{ height: '2rem', width: '4rem' }} containerClassName={styles.tabs} count={3} />
                <Skeleton count={11} containerClassName={styles.rows} className={styles.rowItem} />
            </div>
        </AdminPageLayout>
    )
}

export default DisputesPage;