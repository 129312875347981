import {camelCase} from 'lodash';

export const Education = {
    ukDoctor: 'Doctoral degree',
    ukMaster: "Master's degree",
    ukBachelor: "Bachelor's degree",
    ukBachelorWHonours: "Bachelor's degree with honours",
    ukHND: 'Higher National Diploma',
    ukA: 'A-level',
    ukGCSEac: 'GCSE (Majority grades A*-C)',
    ugGCSEdg: 'GCSE (Majority grades D-G)',
    ukLife: 'Skills for Life',
    usaHaventGradHS: "Haven't graduated high school",
    usaGED: 'GED',
    usaHSGrad: 'High school graduate',
    usaFirstCollege: 'First year of college',
    usaSecondCollege: 'Second year of college',
    usaThirdCollege: 'Third year of college',
    usaFourthCollege: 'Fourth year of college',
};

export const TranslateEducation = t =>
    Object.keys(Education).reduce(
        (acc, key) => ({
            ...acc,
            [key]: t(`education.${key}`),
        }),
        {}
    );

export const EmploymentValues = [
    'Employed full-time',
    'Employed part-time, 30 hours or less per week',
    'Self-employed',
    'Full-time homemaker',
    'Full-time student',
    'Part-time student',
    'Retired',
    'Unemployed/On disability/Other',
];

export const Employment = EmploymentValues.reduce((memo, key) => ({...memo, [key]: key}), {});

export const TranslateEmployment = t =>
    Object.keys(Employment).reduce(
        (acc, item) => ({
            ...acc,
            [item]: t(`employment.${camelCase(item)}`),
        }),
        {}
    );

export const EmploymentReverse = Employment;

export const NO_COMPANY_STATUSES = [
    'Unemployed/On disability/Other',
    'Retired',
    '',
    'Full-time homemaker',
    'Part-time student',
    'Full-time student',
    'Self-employed',
];
