import { PageLayout } from "../../PageLayout/PageLayout"
import Skeleton from "../../Skeleton"
import styles from  "./NotificationsPage.module.scss"

const NotificationsPage = () => {
    return (
        <PageLayout>
            <div className={styles.container}>
                <Skeleton containerClassName={styles.content} />
            </div>
        </PageLayout>
    )
}

export default NotificationsPage;