import UserRoles, {
    isUser,
    isObserver,
    isCompanyAdmin,
    isResource,
    isExternal,
    isExternalModerator,
    isExternalTranslator,
    isRespondent,
} from './UserRoles';
import { calculateCompleteProfile, isSkipQuestionnairePossible } from 'constants/UserProfileComplete';

const ROUTE_ROLES = {
    authNone: [],
    authOnlyUser: [UserRoles.companyAdmin, UserRoles.respondent, UserRoles.moderator, UserRoles.translator],
    authOnlyCompanyAdmin: [UserRoles.companyAdmin],
    authOnlyAdmin: [UserRoles.admin, UserRoles.superAdmin],
    authOnlySuperAdmin: [UserRoles.superAdmin],
    authClientAndAdmin: [UserRoles.companyAdmin, UserRoles.admin, UserRoles.superAdmin],
    interviewUsers: [UserRoles.observer, UserRoles.externalModerator, UserRoles.externalTranslator, UserRoles.external],
    activeInterviewUsers: [UserRoles.externalModerator, UserRoles.externalTranslator, UserRoles.external],
    authAll: [
        UserRoles.companyAdmin,
        UserRoles.respondent,
        UserRoles.moderator,
        UserRoles.translator,
        UserRoles.admin,
        UserRoles.superAdmin,
    ],
};

const routes = {
    home: { path: '/', routeRole: ['nobody'], category: '/' },
    admin: { path: '/admin', routeRole: ROUTE_ROLES.authOnlyAdmin, category: '/admin' },
    adminUserInfo: { path: '/admin/user-info/:userId', routeRole: ROUTE_ROLES.authOnlyAdmin, category: '/admin' },
    adminCreateAdmin: { path: '/admin/create-admin', routeRole: ROUTE_ROLES.authOnlySuperAdmin, category: '/admin' },
    adminCreateClient: { path: '/admin/create/client', routeRole: ROUTE_ROLES.authOnlyAdmin, category: '/admin' },
    adminCreateModerator: { path: '/admin/create/moderator', routeRole: ROUTE_ROLES.authOnlyAdmin, category: '/admin' },
    adminCreateRespondent: {
        path: '/admin/create/respondent',
        routeRole: ROUTE_ROLES.authOnlyAdmin,
        category: '/admin',
    },
    adminCreateTranslator: {
        path: '/admin/create/translator',
        routeRole: ROUTE_ROLES.authOnlyAdmin,
        category: '/admin',
    },
    adminProjects: { path: '/admin/projects', routeRole: ROUTE_ROLES.authOnlyAdmin, category: '/admin/projects' },
    adminProjectDetail: {
        path: '/admin/projects-detail/:projectId',
        routeRole: ROUTE_ROLES.authOnlyAdmin,
        category: '/admin/projects',
    },
    adminOrders: { path: '/admin/orders', routeRole: ROUTE_ROLES.authOnlyAdmin },
    signUp: { path: '/sign-up', routeRole: ROUTE_ROLES.authNone },
    signUpTeam: { path: '/sign-up-team', routeRole: ROUTE_ROLES.authNone },
    expiredLink: { path: '/expired-invitation', routeRole: ROUTE_ROLES.authNone },
    signIn: { path: '/sign-in', routeRole: ROUTE_ROLES.authNone },
    confirm: { path: '/confirm' },
    resetPassword: { path: '/reset-password' },
    projects: { path: '/projects', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin, category: '/projects' },
    newProject: { path: '/projects/new', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin, category: '/projects' },
    editProject: { path: '/projects/edit/:id', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin, category: '/projects' },
    projectDetail: { path: '/projects/:id', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin, category: '/projects' },
    interviews: { path: '/interviews', routeRole: ROUTE_ROLES.authAll, category: '/interviews' },
    inviteToInterviews: {
        path: '/interviews/:id/invite',
        routeRole: ROUTE_ROLES.authOnlyCompanyAdmin,
        category: '/interviews',
    },
    interviewInvitations: {
        path: '/interviews/:id/invitations',
        routeRole: ROUTE_ROLES.authClientAndAdmin,
        category: '/interviews',
    },
    interviewApplications: {
        path: '/interviews/:id/applications',
        routeRole: ROUTE_ROLES.authClientAndAdmin,
        category: '/interviews',
    },
    newInterview: { path: '/interviews/new', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin, category: '/interviews' },
    copyInterview: {
        path: '/interviews/copy/:id',
        routeRole: ROUTE_ROLES.authOnlyCompanyAdmin,
        category: '/interviews',
    },
    interviewDetail: { path: '/interviews/:id', routeRole: ['everybody'], category: '/interviews' },
    profileOrWelcome: { path: '/profile-or-welcome', routeRole: ROUTE_ROLES.authOnlyUser },
    welcome: { path: '/welcome', routeRole: ROUTE_ROLES.authOnlyUser },
    profile: { path: '/profile', routeRole: ROUTE_ROLES.authOnlyUser, category: '/profile' },
    profileEdit: { path: '/profile/edit', routeRole: ROUTE_ROLES.authOnlyUser, category: '/profile' },
    roleCreation: { path: '/role', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin },
    role: { path: '/role/:id', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin },
    duplicateRole: { path: '/duplicate-role/:id', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin },
    viewRole: { path: '/view-role', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin },
    viewRoleId: { path: '/view-role/:id', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin },
    browseInterviews: { path: '/browse/interview', routeRole: ['everybody'], category: '/browse/interview' },
    checkout: { path: '/checkout', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin, category: '/profile' },
    payInResult: { path: '/pay-in-result', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin, category: '/profile' },
    createScreener: {
        path: '/screener/create',
        routeRole: ROUTE_ROLES.authOnlyCompanyAdmin,
        category: '/screener/list',
    },
    editScreener: {
        path: '/screener/edit/:id',
        routeRole: ROUTE_ROLES.authOnlyCompanyAdmin,
        category: '/screener/list',
    },
    screeners: { path: '/screener/list', routeRole: ROUTE_ROLES.authOnlyCompanyAdmin, category: '/screener/list' },
    confirmRegistration: { path: '/confirm-sign-up', routeRole: ROUTE_ROLES.authNone },
    publicProfile: {
        path: '/profile/:interviewId/:userId',
        routeRole: ROUTE_ROLES.authOnlyCompanyAdmin,
        category: '/interviews',
    },
    chat: { path: '/chat', routeRole: ROUTE_ROLES.authOnlyUser, category: '/chat' },
    projectIdChat: { path: '/chat/:projectId', routeRole: ROUTE_ROLES.authOnlyUser, category: '/chat' },
    defaultChat: { path: '/chat', routeRole: ROUTE_ROLES.authOnlyUser, category: '/chat' },
    interviewOnline: {
        path: '/interviews/:interviewId/:sessionId/online',
        routeRole: [...ROUTE_ROLES.authOnlyUser, ...ROUTE_ROLES.interviewUsers, ...ROUTE_ROLES.authOnlyAdmin],
        category: '/interviews',
    },
    plans: { path: '/admin/plans', routeRole: ROUTE_ROLES.authOnlyAdmin, category: '/admin/plans' },
    observer: {
        path: '/join-interview/observer/:interviewId/:observerToken',
        deprecatedPath: '/join-interview/:interviewId/:observerToken',
    },
    external: {
        path: '/join-interview-external/:resourceType/:interviewId/:resourceToken',
        deprecatedPath: '/join-interview-external/:interviewId/:resourceToken',
    },
    signalUser: { path: '/join-interview/:interviewId/:observerToken/:signalUserToken' },
    disputes: {
        path: '/disputes',
        routeRole: [...ROUTE_ROLES.authOnlyUser, ...ROUTE_ROLES.authOnlyAdmin],
        category: '/disputes',
    },
    notifications: {
        path: '/profile/notifications',
        routeRole: ROUTE_ROLES.authOnlyUser,
        category: '/profile',
    },
    vouchers: { path: '/vouchers', routeRole: ROUTE_ROLES.authOnlyAdmin, category: '/vouchers' },
    promoCodes: { path: '/promoCodes', routeRole: ROUTE_ROLES.authOnlyAdmin, category: '/promoCodes' },
    dashboard: { path: '/dashboard', routeRole: ROUTE_ROLES.authOnlyUser, category: '/dashboard' },
    waitingRoom: {
        path: '/interviews/:interviewId/:sessionId/waiting',
        routeRole: [...ROUTE_ROLES.authOnlyUser, ...ROUTE_ROLES.activeInterviewUsers],
        category: '/interviews',
    },
    instanceWaitingRoom: {
        path: '/interviews/:interviewId/:sessionId/instance-waiting-room',
        routeRole: [...ROUTE_ROLES.authOnlyUser, ...ROUTE_ROLES.interviewUsers, ...ROUTE_ROLES.authOnlyAdmin],
        category: '/interviews',
    },
    moderatorWaitingRoom: {
        path: '/interviews/:interviewId/:sessionId/moderatorWaitingRoom',
        routeRole: [UserRoles.moderator, UserRoles.externalModerator, UserRoles.companyAdmin],
    },
    newInterviewsSearch: {
        path: '/new-search',
        routeRole: ROUTE_ROLES.authOnlyUser,
        category: '/interviews',
    },
    newInterviewCreation: {
        path: '/new-interview-form/:projectId/:interviewId',
        routeRole: ROUTE_ROLES.authOnlyCompanyAdmin,
        category: '/projects',
    },
    recording: {
        path: '/recording/:token',
        routeRole: ['everybody'],
    },
};

export default routes;

export const isWelcomePath = (path) => path === routes.welcome.path;

const REDIRECT_PATHS = {
    [UserRoles.companyAdmin]: routes.profile.path,
    [UserRoles.respondent]: routes.profile.path,
    [UserRoles.moderator]: routes.profile.path,
    [UserRoles.translator]: routes.profile.path,
    [UserRoles.admin]: routes.admin.path,
    [UserRoles.superAdmin]: routes.admin.path,
};

export const getRedirectPath = (user, route) => {
    if (!user) {
        return routes.browseInterviews.path;
    }

    if (isUser(user.role) && !user.welcomeFilled) {
        if (isSkipQuestionnairePossible(user.role, calculateCompleteProfile(user))) {
            if (isRespondent(user.role)) {
                return routes.browseInterviews.path;
            }
            if (isCompanyAdmin(user.role)) {
                return routes.projects.path;
            }
            return routes.profile.path;
        }
        return routes.welcome.path;
    }

    if (user.active && isCompanyAdmin(user.role)) {
        return routes.projects.path;
    }

    if (user.active && isResource(user.role)) {
        return routes.browseInterviews.path;
    }

    const isExternalResource =
        isExternal(user.role) ||
        isExternalModerator(user.role) ||
        isExternalTranslator(user.role) ||
        isObserver(user.role);
    if (route && route.path === '/' && isExternalResource) {
        return routes.signIn.path;
    }

    if (isObserver(user.role) || isExternalResource) {
        return routes.interviewOnline.path.replace(':interviewId', user.interview).replace(':sessionId', user.session);
    }

    return REDIRECT_PATHS[user.role];
};

const ALLOWED_PATHS_FOR_INACTIVE = {
    [UserRoles.companyAdmin]: [routes.welcome.path, routes.profile.path, routes.profileEdit.path],
    [UserRoles.respondent]: [routes.welcome.path, routes.profile.path, routes.profileEdit.path],
    [UserRoles.moderator]: [routes.welcome.path, routes.profile.path, routes.profileEdit.path],
    [UserRoles.translator]: [routes.welcome.path, routes.profile.path, routes.profileEdit.path],
    [UserRoles.admin]: [routes.admin.path],
};

export const NavLinksOrder = {
    [routes.projects.path]: 0,
    [routes.interviews.path]: 1,
    [routes.browseInterviews.path]: 2,
    [routes.chat.path]: 3,
    [routes.screeners.path]: 4,
    [routes.profile.path]: 5,
    [routes.disputes.path]: 6,
};

export const navLinksPaths = [
    routes.projects.path,
    routes.interviews.path,
    routes.chat.path,
    routes.screeners.path,
    routes.browseInterviews.path,
    routes.plans.path,
    routes.admin.path,
    routes.profile.path,
    routes.profileEdit.path,
    routes.adminProjects.path,
    routes.disputes.path,
];

export const getNavCategory = (route) => {
    return navLinksPaths.find((path) => new RegExp(`^\\${path}$`).test(route));
};

export const getFallbackNavCategory = (path) => {
    return Object.values(routes).find((route) => route.path === path).category;
};

export const getAllowedRoutesForInactive = (role) => (role ? ALLOWED_PATHS_FOR_INACTIVE[role] : []);
