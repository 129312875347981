import { Footer } from "../Footer/Footer";
import Header from "../Header/Header";
import styles from "./PageLayout.module.scss";

export const PageLayout = ({ children }) => {
    return <div className={styles.container}>
        <Header />
        <div className={styles.content}>
            {children}
        </div>
        <Footer />
    </div>
}