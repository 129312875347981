import _ from 'lodash';
import countriesAndTimezones from 'countries-and-timezones';
import languageList from 'language-list';
import countryCodes from 'country-codes-list';

const UTC_OFFSETS_NORMAL = countriesAndTimezones.getAllTimezones();
export const UTC_OFFSETS = {
    ...UTC_OFFSETS_NORMAL,
};
window.zones = UTC_OFFSETS;
const alterNames = {
    GB: 'United Kingdom',
};

export const COUNTRIES = _(countriesAndTimezones.getAllCountries())
    .map(({ id, name }) => ({
        label: fixName(repairCharacters(name), id),
        value: id,
    }))
    .sortBy('label')
    .value();

export const TRANSLATE_COUNTRIES = (list, t) =>
    list.map((country) => ({ ...country, label: t(`countries.${country.value}`) }));

export const TRANSLATE_AWS_REGIONS = (list, t) =>
    list.map((region) => ({ value: region, label: t(`awsRegions.${region}`) }));

export const getCountryLabel = (countryValue) => {
    const country = COUNTRIES.find((el) => el.value === countryValue);
    return country ? country.label : '';
};

export const NATIONALITIES = [
    'AD',
    'AE',
    'AF',
    'AI',
    'AM',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AU',
    'AW',
    'BA',
    'BB',
    'BE',
    'BH',
    'BM',
    'BO',
    'BR',
    'BS',
    'BT',
    'BG',
    'BY',
    'BZ',
    'CA',
    'CG',
    'CH',
    'CL',
    'CM',
    'CO',
    'CR',
    'CU',
    'CY',
    'DE',
    'DK',
    'DM',
    'EC',
    'EE',
    'EG',
    'ET',
    'FI',
    'FJ',
    'FR',
    'GB',
    'GE',
    'GH',
    'GN',
    'GR',
    'GY',
    'HK',
    'HR',
    'HU',
    'ID',
    'IE',
    'IN',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JM',
    'JO',
    'JP',
    'KE',
    'KP',
    'KR',
    'KW',
    'KZ',
    'LB',
    'LK',
    'LT',
    'LU',
    'MA',
    'MC',
    'ME',
    'MM',
    'MN',
    'MO',
    'MU',
    'MV',
    'MY',
    'NA',
    'NG',
    'NL',
    'NO',
    'NP',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PH',
    'PK',
    'PL',
    'PT',
    'PY',
    'QA',
    'RO',
    'RU',
    'SA',
    'SC',
    'SE',
    'SG',
    'SK',
    'SN',
    'SO',
    'ES',
    'TH',
    'TN',
    'TR',
    'TW',
    'TZ',
    'UA',
    'UG',
    'US',
    'UY',
    'UZ',
    'VE',
    'VN',
    'YE',
    'ZA',
    'ZM',
    'ZW',
];

export const TRANSLATE_NATIONALITIES = (list, t) => {
    return list
        .map((nat) => ({ value: nat, label: t(`nationality.${nat}`) }))
        .sort((x, y) => {
            if (x.value === 'GB') return -1;
            return x.value === 'US' && y.value !== 'GB' ? -1 : 0;
        });
};

const MANGO_PAY_BANNED_COUNTRIES = [
    'AF',
    'BS',
    'BA',
    'BW',
    'KH',
    'KP',
    'ET',
    'GH',
    'GY',
    'IR',
    'IQ',
    'LA',
    'UG',
    'PK',
    'RS',
    'LK',
    'SY',
    'TT',
    'TN',
    'VU',
    'YE',
];

export const NOT_SELECTED_VALUE = { label: 'Not selected', value: null };
export const ALL_VALUE = { label: 'All', value: null };

export const MANGO_PAY_COUNTRIES = COUNTRIES.filter(({ value }) => {
    return !MANGO_PAY_BANNED_COUNTRIES.includes(value.toUpperCase());
});

export const MANGO_PAY_COUNTRIES_OPTIONS = MANGO_PAY_COUNTRIES.map((x) => ({ value: x.value, label: x.label }));

function repairCharacters(s) {
    return s.replace('Å', 'A');
}

function fixName(name, id) {
    return alterNames[id] || name;
}

export const LANGUAGES = _(languageList().getData())
    .map(({ code, language }) => ({
        label: language,
        value: code,
    }))
    .sortBy('label')
    .value();

export const AWS_SPEECH_TO_TEXT_LANGUAGES = [
    'en',
    'fr',
    'de',
    'pt',
    'it',
    'ko',
    'es',
    'hi',
    'ar',
    'ru',
    'nl',
    'id',
    'ta',
    'fa',
    'te',
    'tr',
    'ms',
    'ja',
    'zh',
];

export const TRANSLATE_LANGUAGES = (t) =>
    LANGUAGES.map((language) => ({ ...language, label: t(`languages.${language.value}`) })).sort((x, y) => {
        if (x.value === 'en') return -1;
        return y.value !== 'en' && (x.value === 'es' || x.value === 'zh' || x.value === 'fr') ? -1 : 0;
    });

export const TRANSLATE_AWS_STT_LANGUAGES = (t) => {
    return TRANSLATE_LANGUAGES(t).filter((l) => AWS_SPEECH_TO_TEXT_LANGUAGES.includes(l.value));
};

export const TIMEZONES = _(countriesAndTimezones.getAllTimezones())
    .values()
    .map(({ name, utcOffsetStr }) => {
        return {
            label: `(UTC${utcOffsetStr}) ${name}`,
            value: name,
        };
    })
    .sortBy('label')
    .value();

export const londonTimeZone = { label: '(UTC+00:00) Europe/London', value: 'Europe/London' };

export const getLocalTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getCountryByTimezone = (timezone) => {
    const result = countriesAndTimezones.getCountriesForTimezone(timezone);
    if (result) return result.map((c) => c.id);
    return [];
};

const EXTRA_CODES = {
    BQ: 599,
    BV: 47,
    AQ: 672,
    CG: 242,
    CV: 238,
    CI: 225,
    EH: 212,
    FM: 691,
    IR: 98,
    LA: 856,
    MD: 373,
    MF: 590,
    MO: 853,
    PN: 64,
    SH: 290,
    SJ: 47,
    ST: 239,
    SX: 599,
    SY: 963,
    TL: 670,
    TW: 886,
    TZ: 255,
    VG: 1284,
    VI: 1,
    VN: 84,
};

const RAW_LIST = countryCodes.customList('countryCode', '+{countryCallingCode}');

const fixCode = (key) => {
    if (RAW_LIST[key] === '+') {
        return `+${EXTRA_CODES[key]}`;
    }
    return RAW_LIST[key];
};

export const COUNTRY_PHONE_CODES = _(Object.keys(RAW_LIST))
    .sort()
    .reduce(
        (acc, key) => ({
            ...acc,
            [key]: fixCode(key),
        }),
        {},
    );

export const COUNTRY_PHONE_CODES_WITH_COUNTRY = (t) =>
    _(Object.keys(COUNTRY_PHONE_CODES))
        .sort()
        .reduce(
            (acc, key) => ({
                ...acc,
                [key]: `${t(`countries.${key}`)} ${COUNTRY_PHONE_CODES[key]}`,
            }),
            {},
        );

export const getTimezoneOffset = (timezoneName) => UTC_OFFSETS[timezoneName] || UTC_OFFSETS['Europe/London'];
export const getTimezoneLabel = (timezoneValue) => {
    const timezone = _.find(TIMEZONES, { value: timezoneValue });
    return timezone ? timezone.label : '';
};

export function getLanguageName(languageValue) {
    const language = _.find(LANGUAGES, { value: languageValue });
    return language ? language.label : '';
}

export function getTranslatedName(value, t) {
    const language = _.find(TRANSLATE_LANGUAGES(t), { value });
    return language ? language.label : '';
}

export const PLATFORM_LOCATION = {
    VOCAL_VIEWS: 'vocalViews',
    OTHER: 'other',
};

export const PLATFORM_LOCATION_LIST = (t) =>
    Object.values(PLATFORM_LOCATION)
        .sort()
        .map((name) => ({ value: name, label: t(`platformLocation.${name}`) }));

export const SERVER_REGIONS = [
    'eu-west-2',
    'us-east-2',
    'us-east-1',
    'us-west-1',
    'us-west-2',
    'af-south-1',
    'ap-east-1',
    'ap-south-2',
    'ap-southeast-3',
    'ap-south-1',
    'ap-northeast-3',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ca-central-1',
    'eu-central-1',
    'eu-west-1',
    'eu-south-1',
    'eu-west-3',
    'eu-south-2',
    'eu-north-1',
    'eu-central-2',
    'me-south-1',
    'me-central-1',
    'sa-east-1',
];
