import React from 'react';
import Skeleton from 'components/common/Skeleton/Skeleton';

import styles from './Filter.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const Filter = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.publicInterviewsFilters}>
            <Skeleton containerClassName={styles.input} className={classNames(styles.item, styles.input)} />
            <Skeleton containerClassName={styles.format} className={classNames(styles.item, styles.format)} />
            <Skeleton containerClassName={styles.type} className={classNames(styles.item, styles.type)} />
            <Skeleton containerClassName={styles.input} className={classNames(styles.item, styles.input)} />
            <Skeleton containerClassName={styles.duration} className={classNames(styles.item, styles.duration)} />
            <Skeleton containerClassName={styles.sorter} className={classNames(styles.item, styles.sorter)} />
            <Skeleton containerClassName={styles.resetButton} className={classNames(styles.item, styles.resetButton)} />
            <Skeleton containerClassName={styles.view} className={classNames(styles.item, styles.view)} />
        </div>
    );
};
