import React from 'react';

export const mainGreen = '#6d62ef';
export const mainPurple = '#9853f6';
export const ROOT_SIZE = parseFloat(document.documentElement.style.getPropertyValue('--main-font')) || 16;
export const svgGradient = (
    <defs>
        <linearGradient id='mainGradient' x1='.25%' x2='99.75%' y1='0%' y2='100%'>
            <stop offset='0%' stopColor={mainGreen} />
            <stop offset='100%' stopColor={mainPurple} />
        </linearGradient>
    </defs>
);
