const config = {
    mangoPayUserName: process.env.REACT_APP_MANGOPAYID,
    mangoPayUrl: process.env.REACT_APP_MANGOPAYURL || 'https://api.sandbox.mangopay.com',
    localesFolder: process.env.REACT_APP_LOCALES_FOLDER || 'locales',
    googleClientId:
        process.env.REACT_APP_GOOGLE_CLIENT_ID ||
        '662753209077-c50ojlmn6m4uemqnj95if9aj4ebeco8u.apps.googleusercontent.com',
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || '237680424895732',
};

export default config;
