import ResourceStatusInformation from 'constants/ResourceStatusInformation';
import { lists } from 'constants/InterviewStorageConstants';

//New interview should appear in invitations tab
//Interview should appear in Invitations tab - if Invitations tab is not full - update list without filter change
//Interview should update in browse list - if interview is in browseList - update browse list without filter change
//Interview should update on the page - if interview matches - update
export async function handleNewInvitation(interviewId) {
    const isInvitationsTab = ResourceStatusInformation.isInvitation(this.state.filter.status);
    const isTabFull = this.state.interviews.list.length >= this.state.limit;
    if (isInvitationsTab && !isTabFull) {
        await this.updateFilterAndFetch(lists.interviews, {});
    }

    await updateBrowseAndSingle.call(this, interviewId);
}

//Interview should disappear from any tab that is not Upcoming - if tab is not Upcoming and interview in list - update list without filter change
//Interview should appear on Upcoming tab - if tab is not full - update list without filter change
export async function handleHiredToInterview(interviewId) {
    const isUpcomingTab = ResourceStatusInformation.isHired(this.state.filter.status);
    const isTabFull = this.state.interviews.list.length >= this.state.limit;
    const isInterviewInList = this.state.interviews.list.some((interview) => interview._id === interviewId);
    if (isUpcomingTab && !isTabFull) {
        await this.updateFilterAndFetch(lists.interviews, {});
    }

    if (!isUpcomingTab && isInterviewInList) {
        await this.updateFilterAndFetch(lists.interviews, {});
    }

    await updateBrowseAndSingle.call(this, interviewId);
}

//Interview should disappear from Upcoming tab - if this tab and interview in list - update list without filter change
//Interview should appear on cancelled tab - if this tab is not full - update list without filter change
export async function handleCancelHire(interviewId) {
    const isUpcomingTab = ResourceStatusInformation.isHired(this.state.filter.status);
    const isCancelledTab = ResourceStatusInformation.isCancelled(this.state.filter.status);
    const isInList = this.state.interviews.list.some((interview) => interview._id === interviewId);
    const isTabFull = this.state.interviews.list.length >= this.state.limit;
    if (isUpcomingTab && isInList) {
        await this.updateFilterAndFetch(lists.interviews, {});
    }

    if (isCancelledTab && !isTabFull) {
        await this.updateFilterAndFetch(lists.interviews, {});
    }

    await updateBrowseAndSingle.call(this, interviewId);
}

//Interview should disappear from any tab that is not Cancelled - if tab is not Cancelled and interview in list - update list without filter change
//Interview should appear on Cancelled tab - if tab is not full - update list without filter change
export async function handleInterviewCancelled(interviewId) {
    const isCancelledTab = ResourceStatusInformation.isCancelled(this.state.filter.status);
    const isTabFull = this.state.interviews.list.length >= this.state.limit;
    const isInterviewInList = this.state.interviews.list.some((interview) => interview._id === interviewId);
    if (isCancelledTab && !isTabFull) {
        await this.updateFilterAndFetch(lists.interviews, {});
    }

    if (!isCancelledTab && isInterviewInList) {
        await this.updateFilterAndFetch(lists.interviews, {});
    }

    await updateBrowseAndSingle.call(this, interviewId);
}

//Interview should appear in browse interviews - if browse list is not full - update browse list without change
export async function handleNewInterview(interviewId) {
    const isBrowseFull = this.state.browseInterviews.list.length >= this.state.browseLimit;
    if (!isBrowseFull) await this.updateFilterAndFetch(lists.browseInterviews, {});
}

//Interview should disappear from application tab - if in this tab - update list without filter change
export async function handleApplicationDeclined(interviewId) {
    const isApplicationTab = ResourceStatusInformation.isApplication(this.state.filter.status);
    const isInList = this.state.interviews.list.some((interview) => interview._id === interviewId);
    if (isApplicationTab && isInList) await this.updateFilterAndFetch(lists.interviews, {});
    await updateBrowseAndSingle.call(this, interviewId);
}

//Interview should disappear from invitations tab - if in this tab - update list without filter change
export async function handleInvitationCancelled(interviewId) {
    const isInvitationsTab = ResourceStatusInformation.isInvitation(this.state.filter.status);
    const isInList = this.state.interviews.list.some((interview) => interview._id === interviewId);
    if (isInvitationsTab && isInList) await this.updateFilterAndFetch(lists.interviews, {});
    await updateBrowseAndSingle.call(this, interviewId);
}

async function updateBrowseAndSingle(interviewId) {
    const isInBrowseList = this.state.browseInterviews.list.some((interview) => interview._id === interviewId);
    if (isInBrowseList) {
        await this.updateFilterAndFetch(lists.browseInterviews, {});
    }

    if (this.state.interview && this.state.interview._id === interviewId)
        await this.fetchInterview({ identifier: interviewId });
}
