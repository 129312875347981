/**
 * @param {String} string
 * @return {String}
 */
export function upperFirstLetter(string) {
    const tmp = string.toString();
    return `${tmp[0].toUpperCase()}${tmp.slice(-tmp.length + 1)}`;
}

export function isObject(obj) {
    const t = typeof obj;
    return null != obj && (t === 'object' || t === 'function');
}
