import React, {Component, createContext} from 'react';
import makeRequest from 'makeRequest';
import {createRequestHelper, requestHelperWithInitialState} from 'helpers/requestHelper';
import {noop} from 'lodash';
import {getToken} from 'helpers/getToken';

export const VouchersContext = createContext({});

const createVoucherRequestHelper = createRequestHelper('createVoucher');
const deleteVoucherRequestHelper = createRequestHelper('deleteVoucher');
const getVouchersRequestHelper = requestHelperWithInitialState('getVouchers', {list: [], count: 0});
const redeemVoucherRequestHelper = createRequestHelper('redeemVoucher');

class VouchersContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...createVoucherRequestHelper.initialState,
            ...deleteVoucherRequestHelper.initialState,
            ...getVouchersRequestHelper.initialState,
            ...redeemVoucherRequestHelper.initialState,
            balanceThreshold: {admin: 0, vouchersTotal: 0},
            attemptsTimestamp: 0,
        };
    }

    createVoucher = async (data, cb = noop) => {
        const authToken = getToken();
        const path = 'vouchers/create';

        this.setState(createVoucherRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState(createVoucherRequestHelper.result(result));
            cb(null, result);
        } catch (e) {
            this.setState(createVoucherRequestHelper.error(JSON.parse(e)));
            cb(JSON.parse(e));
        }
    };

    deleteVoucher = async data => {
        const authToken = getToken();
        const path = 'vouchers/delete';

        this.setState(deleteVoucherRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState(deleteVoucherRequestHelper.result(result));
            return result;
        } catch (e) {
            this.setState(deleteVoucherRequestHelper.error(e));
            return e;
        }
    };

    getVouchers = async data => {
        const authToken = getToken();
        const path = 'vouchers/get';

        this.setState(getVouchersRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState(getVouchersRequestHelper.result(result));
            return result;
        } catch (e) {
            this.setState(getVouchersRequestHelper.error(e));
            return e;
        }
    };

    checkBalance = async () => {
        const authToken = getToken();
        const path = 'vouchers/checkBalance';

        try {
            const result = await makeRequest({path, data: {authToken}});
            this.setState({balanceThreshold: result});
        } catch (e) {
            this.setState({balanceThreshold: {admin: 0, vouchersTotal: 0}});
        }
    };

    redeemVoucher = async (code, cb = noop) => {
        const authToken = getToken();
        const path = 'vouchers/redeem';

        this.setState(redeemVoucherRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, code}});
            this.setState(redeemVoucherRequestHelper.result(result));
            cb(null, result);
        } catch (e) {
            this.setState(redeemVoucherRequestHelper.error(e));
            cb(e);
        }
    };

    setAttemptsTimestamp = timestamp => this.setState({attemptsTimestamp: timestamp});

    render() {
        const actions = {
            createVoucher: this.createVoucher,
            deleteVoucher: this.deleteVoucher,
            getVouchers: this.getVouchers,
            checkBalance: this.checkBalance,
            redeemVoucher: this.redeemVoucher,
            setAttemptsTimestamp: this.setAttemptsTimestamp,
        };

        return (
            <VouchersContext.Provider value={{...this.state, ...actions}}>
                {this.props.children}
            </VouchersContext.Provider>
        );
    }
}

export default VouchersContextProvider;
