import React, { createContext, Component } from 'react';
import { UserContextConsumer } from './UserContext';
import makeRequest from 'makeRequest';
import { getToken } from 'helpers/getToken';
import { v4 as uuidv4 } from 'uuid';
import isEmail from 'validator/lib/isEmail';
import { tabs } from 'constants/team';
import { isTeamAdmin, isTeamMember } from 'constants/UserRoles';

const getBaseInvitationState = () => ({
    role: '',
    email: '',
    id: uuidv4(),
});

const initialErrorsState = {
    invitations: {},
};

export const TeamContext = createContext({});

const defaultOptions = {
    limit: 10,
    offset: 0,
};

const getInitialState = () => ({
    permissions: [],
    currentUser: {
        isAdmin: false,
        actions: [],
    },
    members: {
        list: [],
        totalCount: 0,
    },
    invitations: {
        list: [],
        totalCount: 0,
    },
    newMembers: [getBaseInvitationState()], // for adding new members,

    //modal states
    errors: initialErrorsState,
    tab: tabs.members,

    isInviteModalOpened: false,
    isDeleteMemberModalOpened: false,
    isDeleteRoleModalOpened: false,
    isDeleteInvitationModalOpened: false,
    isChangeRoleModalOpened: false,

    selectedUserId: null,
    selectedRoleId: null,
    loading: {
        invitations: false,
        members: false,
    },
    rolesLoaded: false,
    options: {
        team: defaultOptions,
        invitations: defaultOptions,
        members: defaultOptions,
    },
    roles: [],
    usersByRoles: [],
});

class TeamContextProvider extends Component {
    constructor(props) {
        super(props);
        window.tcp = this;
        this.state = getInitialState();
    }

    getTeam = async () => {
        const path = `team/getTeam`;
        const authToken = getToken();
        const data = { authToken };
        const result = await makeRequest({ path, data });
        this.setState({ currentUser: result });
    };

    getRoles = async () => {
        const path = `team/getRoles`;
        const authToken = getToken();
        const data = { authToken };
        this.setState({ rolesLoaded: false });
        try {
            const result = await makeRequest({ path, data });
            this.setState({ roles: result.sortedRoles, usersByRoles: result.totalCountByRole });
        } catch (e) {
            console.log('getRoles, Error', e);
        } finally {
            this.setState({ rolesLoaded: true });
        }
    };

    getInitInfo = () => {
        const { user } = this.props.userContext;
        if (!user && !(isTeamAdmin(user) || isTeamMember(user))) return;

        this.getTeam();
        this.getTeamLists();
        this.getRoles();
    };

    componentDidMount() {
        this.getInitInfo();
    }

    componentDidUpdate(prevProps) {
        const prevUser = prevProps.userContext.user;
        const currentUser = this.props.userContext.user;
        const isUserChanged = this.props.userContext.isUserChanged(prevUser, currentUser);
        if (isUserChanged) {
            this.getInitInfo();
        }
    }

    getTeamLists = async () => {
        const initialOptions = { limit: 1000, offset: 0 };
        await this.getMembersList(initialOptions);
        await this.getInvitationsList(initialOptions);
    };

    getMembersForNewProject = async (projectId) => {
        const path = `team/getMembersForNewProject`;
        const authToken = getToken();
        const data = { authToken, limit: 1000, offset: 0, projectId: projectId || '' };
        try {
            const result = await makeRequest({ path, data });
            return result;
        } catch (e) {
            console.log(e);
            return [];
        }
    };

    getProjectMembers = async (projectId) => {
        const path = `team/getProjectMembers`;
        const authToken = getToken();
        const data = { authToken, projectId };
        try {
            const result = await makeRequest({ path, data });
            return result;
        } catch (e) {
            console.log(e);
            return [];
        }
    };

    updateInvitationById = (id) => {
        this.setState({
            invitations: {
                list: this.state.invitations.list.map((invitation) => {
                    if (invitation._id === id) {
                        invitation.status = 'pending';
                    }
                    return invitation;
                }),
                totalCount: this.state.invitations.totalCount,
            },
        });
    };

    getMembersList = async (
        { limit = this.state.options.members.limit, offset = this.state.options.members.offset },
        clearState = true,
    ) => {
        this.setState({ loading: { ...this.state.loading, members: true } });
        const path = `team/getMembersList`;
        const authToken = getToken();
        const data = { authToken, limit, offset };
        const result = await makeRequest({ path, data });
        const prevState = clearState ? [] : [...this.state.members.list];
        this.setState({
            members: { list: [...prevState, ...result.list], totalCount: result.totalCount },
            options: {
                ...this.state.options,
                members: {
                    limit,
                    offset,
                },
            },
            loading: { ...this.state.loading, members: false },
        });
    };

    getInvitationsList = async (
        { limit = this.state.options.invitations.limit, offset = this.state.options.invitations.offset },
        clearState = true,
    ) => {
        this.setState({ loading: { ...this.state.loading, invitations: true } });
        const path = `team/getInvitationsList`;
        const authToken = getToken();
        const data = { authToken, limit, offset };
        const result = await makeRequest({ path, data });
        const prevState = clearState ? [] : [...this.state.invitations.list];
        this.setState({
            invitations: { list: [...prevState, ...result.list], totalCount: result.totalCount },
            options: {
                ...this.state.options,
                invitations: {
                    limit,
                    offset,
                },
            },
            loading: { ...this.state.loading, invitations: false },
        });
    };

    updateLists = async ({ extraMembers = 0, extraInvitations = 0 }) => {
        const count = {
            members: this.state.members.list.length,
            invitations: this.state.invitations.list.length,
        };
        if (count.members % 10) {
            count.members = count.members + extraMembers;
        }
        if (count.invitations % 10) {
            count.invitations = count.invitations + extraInvitations;
        }
        const path = `team/updateLists`;
        const authToken = getToken();
        const data = { authToken, count };

        const result = await makeRequest({ path, data });
        this.setState({
            invitations: { list: result.invitations.list, totalCount: result.invitations.totalCount },
            members: { list: result.members.list, totalCount: result.members.totalCount },
        });
    };

    checkRoleTitle = async ({ title, roleId }) => {
        const path = `team/checkRoleTitle`;
        const authToken = getToken();
        const data = { authToken, title, roleId };

        const result = await makeRequest({ path, data });

        return result;
    };

    validateRoleCreation = async ({ title, roleId }) => {
        let error = '';
        const trimmedTitle = title.trim();
        if (!trimmedTitle || trimmedTitle.length > 20) {
            error = 'role:errors.title';
            return error;
        }

        const errorText = await this.checkRoleTitle({ title: trimmedTitle, roleId });

        if (errorText) {
            error = errorText.error;
            return error;
        }

        return error;
    };

    getRoleById = async ({ roleId }) => {
        const path = `team/getRoleById`;
        const authToken = getToken();
        const data = { authToken, id: roleId };
        return await makeRequest({ path, data });
    };

    createRole = async (values) => {
        const path = `team/createRole`;
        const authToken = getToken();
        const data = { authToken, info: values };
        await makeRequest({ path, data });
    };

    updateRole = async (values, roleId) => {
        const path = `team/updateRole`;
        const authToken = getToken();
        const data = { authToken, info: values, roleId };
        await makeRequest({ path, data });
    };

    validateInvitation = async ({ token }) => {
        const path = 'team/validateInvitation';
        const data = { token };
        return await makeRequest({ path, data });
    };

    setTab = (tab) => {
        this.setState({ tab });
    };

    setSelectedUserId = (id) => {
        this.setState({ selectedUserId: id });
    };

    setSelectedRoleId = (id) => {
        this.setState({ selectedRoleId: id });
    };

    //Members actions

    async deleteMember(id) {
        const path = 'team/deleteMember';
        const authToken = getToken();
        await makeRequest({ path, data: { id, authToken } });
    }

    deleteRole = async ({ roleId }) => {
        const path = 'team/deleteRole';
        const authToken = getToken();
        await makeRequest({ path, data: { roleId, authToken } });
    };

    async setRoleForUser({ memberId, role }) {
        const path = 'team/setRoleForUser';
        const authToken = getToken();
        await makeRequest({ path, data: { memberId, role, authToken } });
    }

    //Invitations actions

    resendInvitation = async (userData) => {
        const path = 'team/resendInvitation';
        const authToken = getToken();
        await makeRequest({ path, data: { invitation: userData, authToken } });
    };

    sendInvitation = async () => {
        if (this.validateInvitations()) {
            return;
        }

        const path = 'team/sendInvitation';
        const authToken = getToken();
        const result = await makeRequest({ path, data: { invitations: this.state.newMembers, authToken } });

        if (result.errors) {
            this.setState({ errors: { ...this.errors, invitations: result.errors } });
        } else {
            this.toggleInviteModal(false);
            await this.getTeamLists();
        }
    };

    async cancelInvitation(id) {
        const path = 'team/cancelInvitation';
        const authToken = getToken();
        await makeRequest({ path, data: { id, authToken } });
    }

    //Modal actions

    toggleInviteModal = (value) => {
        this.setState({ isInviteModalOpened: value });
    };

    toggleDeleteRoleModal = (value) => {
        this.setState({ isDeleteRoleModalOpened: value });
    };

    toggleDeleteInvitationModal = (value) => {
        this.setState({ isDeleteInvitationModalOpened: value });
    };

    toggleChangeRoleModal = (value) => {
        this.setState({ isChangeRoleModalOpened: value });
    };

    // Invitation modal actions

    addInvitation = () => {
        this.setState({ newMembers: [...this.state.newMembers, getBaseInvitationState()] });
    };

    deleteInvitation = ({ id }) => {
        this.setState({ newMembers: [...this.state.newMembers.filter((invitation) => invitation.id !== id)] });
    };

    setValueForInvitation = ({ id, key, value }) => {
        this.setState({
            newMembers: [
                ...this.state.newMembers.map((invitation) => {
                    if (invitation.id === id) {
                        invitation[key] = value;
                    }
                    return invitation;
                }),
            ],
        });
    };

    setRoleForInvitation = ({ id, role }) => {
        this.setValueForInvitation({ id, key: 'role', value: role });
    };

    setEmailForInvitation = ({ id, email }) => {
        this.setValueForInvitation({ id, key: 'email', value: email });
    };

    clearInvitations = () => {
        this.setState({ newMembers: [getBaseInvitationState()] });
    };

    clearErrors = () => {
        this.setState({ errors: initialErrorsState });
    };

    clearSelectedUser = () => {
        this.setState({ selectedUserId: '' });
    };

    clearSelectedRole = () => {
        this.setState({ selectedRoleId: '' });
    };

    cleanup = () => {
        this.clearErrors();
        this.clearInvitations();
        this.clearSelectedUser();
        this.clearSelectedRole();
    };

    clearState = () => {
        this.setState(getInitialState());
    };

    clearLists = (lists = ['members', 'invitations']) => {
        lists.forEach((list) => {
            this.setState({ [list]: { list: [], totalCount: this.state[list].totalCount } });
        });
    };

    validateInvitations = () => {
        this.clearErrors();
        const counts = {};
        const errors = {};

        const emails = this.state.members.list.map((member) => member.email);
        emails.forEach((email) => {
            counts[email] = (counts[email] || 0) + 1;
        });

        this.state.newMembers.forEach((invitation) => {
            let error = '';

            if (!invitation.role) {
                error = 'profile:text.noRole';
            }
            if (counts[invitation.email] > 1) {
                error = 'profile:text.existingUser';
            }
            if (!isEmail(invitation.email)) {
                error = 'profile:text.invalidEmailFormat';
            }
            if (!invitation.email) {
                error = 'profile:text.emptyEmail';
            }
            if (error) {
                errors[invitation.id] = error;
            }
        });

        this.setState({
            errors: {
                ...this.state.errors,
                invitations: errors,
            },
        });

        return Object.keys(errors).length !== 0;
    };

    // Remove user actions

    toggleDeleteMemberModal = (value) => {
        this.setState({ isDeleteMemberModalOpened: value });
    };

    render() {
        const actions = {
            getTeamLists: this.getTeamLists,
            deleteMember: this.deleteMember,
            createRole: this.createRole,
            deleteRole: this.deleteRole,
            setRoleForUser: this.setRoleForUser,
            sendInvitation: this.sendInvitation,
            cancelInvitation: this.cancelInvitation,

            toggleInviteModal: this.toggleInviteModal,
            toggleDeleteMemberModal: this.toggleDeleteMemberModal,
            setTab: this.setTab,
            setSelectedUserId: this.setSelectedUserId,
            getMembersList: this.getMembersList,
            getInvitationsList: this.getInvitationsList,
            clearState: this.clearState,
            clearLists: this.clearLists,
            getRoles: this.getRoles,
            updateLists: this.updateLists,
            validateRoleCreation: this.validateRoleCreation,
            getRoleById: this.getRoleById,
            updateRole: this.updateRole,
            toggleDeleteRoleModal: this.toggleDeleteRoleModal,
            toggleDeleteInvitationModal: this.toggleDeleteInvitationModal,
            setSelectedRoleId: this.setSelectedRoleId,
            updateInvitationById: this.updateInvitationById,
            toggleChangeRoleModal: this.toggleChangeRoleModal,
        };

        const invitationActions = {
            addInvitation: this.addInvitation,
            deleteInvitation: this.deleteInvitation,
            setRoleForInvitation: this.setRoleForInvitation,
            setEmailForInvitation: this.setEmailForInvitation,
            cleanup: this.cleanup,
            validateInvitations: this.validateInvitations,
            validateInvitation: this.validateInvitation,
            resendInvitation: this.resendInvitation,
        };

        const memberActions = {
            deleteMember: this.deleteMember,
            getMembersForNewProject: this.getMembersForNewProject,
            getProjectMembers: this.getProjectMembers,
        };

        return (
            <TeamContext.Provider value={{ ...this.state, ...actions, invitationActions, memberActions }}>
                {this.props.children}
            </TeamContext.Provider>
        );
    }
}

export default UserContextConsumer(TeamContextProvider);

export const TeamContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <TeamContext.Consumer>
                {(context) => <WrappedComponent teamContext={context} {...props} />}
            </TeamContext.Consumer>
        );
    };
};
