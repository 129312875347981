import { PageLayout } from "../../PageLayout/PageLayout"
import Skeleton from "../../Skeleton";
import styles from "./InterviewDetailPage.module.scss"

const InterviewDetailPage = () => {
    return (
        <PageLayout>
            <div className={styles.content}>
                <div className={styles.section} style={{ height: '4rem', alignItems: 'center', padding: "1rem 2.5rem .25rem" }}>
                    <Skeleton count={3} containerClassName={styles.headerItems} className={styles.headerItem} />
                </div>
                <div className={styles.section} style={{ height: '37.5rem' }} />
            </div>
        </PageLayout>
    )
}

export default InterviewDetailPage;