import React from 'react';

import Skeleton from 'components/common/Skeleton/Skeleton';
import style from './AdminHeader.module.scss';
import classNames from 'classnames';

function HeaderSkeleton(props) {
    return (
        <Skeleton
            style={{opacity: '0.15'}}
            baseColor='#1e1d20'
            className={classNames(props.className, style.skeleton)}
            {...props}
        />
    );
}

export default function AdminHeader() {
    return (
        <div className={style.wrapper}>
            <div className={style.logo}>
                <HeaderSkeleton className={style.icon} />
            </div>
            <div className={style.right}>
                <div className={style.nav}>
                    <HeaderSkeleton className={style.link} />
                    <HeaderSkeleton className={style.link} />
                    <HeaderSkeleton className={style.link} />
                    <HeaderSkeleton className={style.link} />
                    <HeaderSkeleton className={style.link} />
                    <HeaderSkeleton className={style.link} />
                    <HeaderSkeleton className={style.link} />
                    <HeaderSkeleton className={classNames(style.link, style.last)} />
                </div>
                <Skeleton style={{height: '1.4rem', width: '2px'}} className={style.devider}/>
                <div className={style.user}>
                    <HeaderSkeleton className={style.name} />
                    <HeaderSkeleton circle className={style.avatar} />
                </div>
            </div>
        </div>
    );
}
