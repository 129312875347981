import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { UserContext } from 'context/UserContext';

import UserRoles, {
    isUser,
    isAdmin,
    isSuperAdmin,
    isExternal,
    isExternalModerator,
    isExternalTranslator,
    isObserver,
} from 'constants/UserRoles';
import { getRedirectPath, getAllowedRoutesForInactive, isWelcomePath } from 'constants/Routes';
import { calculateCompleteProfile, isSkipQuestionnairePossible } from 'constants/UserProfileComplete';

const CheckAuth = ({ component: Component, route, match, ...rest }) => {
    const { path, routeRole = [] } = route;
    const { user, setPreviousRoute } = useContext(UserContext);

    useEffect(() => {
        setPreviousRoute(match.path);
    }, []);

    if (isRouteAllowed(path, routeRole, user, user && user.active)) {
        return <Component {...rest} match={match} />;
    }

    return <Redirect to={getRedirectPath(user, route)} />;
};

export default CheckAuth;

const isRouteAllowed = (routePath, routeRole, user, isUserActive) => {
    let userRole = user ? user.role : null;
    const isSuperAdminBool = user && user.isSuperAdmin;
    const welcomeFilled = user && user.welcomeFilled;
    const isSkipped = user && isSkipQuestionnairePossible(userRole, calculateCompleteProfile(user));

    if (routeRole.includes('everybody')) {
        return true;
    }

    if (isAdmin(userRole) && isSuperAdmin(userRole, isSuperAdminBool)) {
        userRole = UserRoles.superAdmin;
    }

    const isNeedAuth = !!routeRole.length;
    const isAuthorized = !!user;

    const isExternalUser =
        user &&
        (isExternal(user.role) ||
            isExternalModerator(user.role) ||
            isExternalTranslator(user.role) ||
            isObserver(user.role));
    if (!isNeedAuth && isExternalUser) {
        return true;
    }

    if (!isNeedAuth) {
        return !isAuthorized;
    }

    const hasAccessToRoute = routeRole.includes(userRole);
    const isAllowedByActive = isUserActive || getAllowedRoutesForInactive(userRole).includes(routePath);
    const isAllowedByWelcomeFilled =
        (welcomeFilled && !isWelcomePath(routePath)) ||
        (!welcomeFilled && isWelcomePath(routePath)) ||
        (!welcomeFilled && isSkipped && !isWelcomePath(routePath));
    const isAllowedByWelcomeFilledForUser = !isUser(userRole) || isAllowedByWelcomeFilled;
    return hasAccessToRoute && isAllowedByActive && isAllowedByWelcomeFilledForUser;
};
