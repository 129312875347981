import AdminPageLayout from "../../AdminPageLayout/AdminPageLayout";
import Skeleton from "../../Skeleton";
import styles from "./PromoCodesPage.module.scss";

const PromoCodesPage = () => {
    return (
        <AdminPageLayout>
            <div className={styles.container}>
                <div className={styles.header}>
                <Skeleton containerClassName={styles.headerTitle} count={2} style={{ height: '2rem', width: '7rem' }} />
                <Skeleton style={{ height: '2.5rem', width: '2.5rem' }} />
                </div>
                <Skeleton count={15} containerClassName={styles.rows} className={styles.rowItem} />
            </div>
        </AdminPageLayout>
    )
}

export default PromoCodesPage;