import dayjs from 'dayjs';
import { isCompanyAdmin } from 'constants/UserRoles';
import { getLanguageName, getTimezoneOffset } from 'components/common/listConstants';
import { get, replace, capitalize, words } from 'lodash';

export function formatUserFullname(user) {
    return user.firstName + ' ' + user.lastName;
}

export function formatPublicPrivate(arg) {
    return arg ? 'Public' : 'Private';
}

export function formatDatetime(timestamp) {
    return dayjs(timestamp).format('DD MMM, YYYY · HH:mm');
}
export function formatDate(timestamp) {
    return dayjs(timestamp).format('DD MMM, YYYY');
}
export function formatTime(timestamp) {
    return dayjs(timestamp).format('HH:mm');
}

export function firstNameAndInitial(user) {
    return `${user.firstName} ${user.lastName ? user.lastName[0] : ''}.`;
}

export function valueToLabel(value, list, defaultValue = '') {
    const listItem = list.find((e) => e.value === value);
    if (!listItem) {
        return defaultValue;
    }

    return listItem.label;
}

export const formatBooleanAsYesNo = (value) => (value ? 'common.yes' : 'common.no');

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const UTCLabel = (role, timezone) => {
    if (!isCompanyAdmin(role)) return false;
    const offset = getTimezoneOffset(timezone).utcOffset * 60 * 1000;
    const sign = offset >= 0 ? '+' : '-';
    const hours = Math.floor(Math.abs(offset / 1000 / 60 / 60))
        .toString()
        .padStart(2, '0');
    const minutes = Math.abs((offset / 1000 / 60) % 60)
        .toString()
        .padStart(2, '0');
    return ` (UTC${sign}${hours}:${minutes})`;
};

export function capitalizeAndReturnFirstLetter(string) {
    return string.charAt(0).toUpperCase();
}

export const formatDateToUTC = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export function formatSecondsToMinutes(time, withLeadingHourHoursZeroes = false) {
    const hours = Math.floor(time / (60 * 60));
    const hoursString = hours ? ('0' + hours).slice(-2) + ':' : withLeadingHourHoursZeroes ? '00:' : '';

    const minutes = Math.floor((time - hours * 60 * 60) / 60);
    const minutesString = minutes ? ('0' + minutes).slice(-2) + ':' : '00:';

    const seconds = Math.floor(time - hours * 60 * 60 - minutes * 60);
    const secondsString = ('0' + seconds).slice(-2);

    return `${hoursString}${minutesString}${secondsString}`;
}

export function formatMStoMinutes(ms = 0) {
    return formatSecondsToMinutes(Math.floor(ms / 1000));
}

export const interpolateTranslationString = (string, values) => {
    let result = string;

    for (let key in values) {
        result = result.replace(`%${key}%`, values[key]);
    }
    return result;
};

// eslint-disable-next-line no-useless-escape
export const removeQuerySymbols = (string) => string.replace(/[\\#%@$+,:;&?~^\[\]{}|/*→]/gi, '');

export const getRecordingFilename = (type, interview, session) => {
    const project = interview.project && interview.project.name;
    const interviewTitle = removeQuerySymbols(interview.title).replace(/\s/gi, '-');
    const projectParam = project ? removeQuerySymbols(project).replace(/\s/gi, '-') + '-' : '';
    const dateFormat = dayjs(session.mergedDate).format('DD-MM-HHmm');

    if (type === 'translator') {
        const simultaneousTranslationLanguage = get(interview, 'translation.realTime.language');
        const translationLanguageName = getLanguageName(simultaneousTranslationLanguage);
        return `${projectParam}${interviewTitle}-${translationLanguageName}-${dateFormat}.mp4`;
    } else {
        const interviewLanguage = interview.language;
        const interviewLanguageLabel = getLanguageName(interviewLanguage);

        return `${projectParam}${interviewTitle}-${interviewLanguageLabel}-${dateFormat}.mp4`;
    }
};

export const formatDecimals = (number) => {
    return parseFloat(number).toFixed(2);
};

export const stripPhoneNumber = (phoneNumber) => replace(phoneNumber, /[^\w0-9+]/g, '');

export const stripHTML = (string = '') => string.replace(/<\/?\s*[^>]*>/gi, '');
export const stripHTMLSpecialChars = (string = '') => string.replace(/&[a-z]+;/gi, '');
export const replaceEncodedHTMLSpecialChars = (string = '') =>
    string
        .replaceAll('&amp;', '&')
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>');
export const replaceHTMLSpecialChars = (string = '') => string.replace(/&nbsp;/gi, ' ');
export const capitalizeMany = (string) =>
    words(string)
        .map((word) => capitalize(word))
        .join(' ');
