import {upperFirstLetter} from 'helpers/LodashUtils';

const InterviewStatusInformation = {
    cancelled: 'cancelled',
    draft: 'draft',
    published: 'published',
    paid: 'paid',
    past: 'past',
    started: 'started',

    isCancelled: function(status) {
        return status === this.cancelled;
    },
    isDraft: function(status) {
        return status === this.draft;
    },
    isPublished: function(status) {
        return status === this.published;
    },
    isPaid: function(status) {
        return status === this.paid;
    },
    isPast: function(status) {
        return status === this.past;
    },
    isStarted: function(status) {
        return status === this.started;
    },
    getInterviewStatusText: function(draft, status) {
        if (draft) {
            return upperFirstLetter(this.draft);
        }

        return upperFirstLetter(this[status]);
    },
};

export default InterviewStatusInformation;
