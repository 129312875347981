export function createRequestHelper(targetKey, normalizeResultFunction = null) {
    const processingKey = targetKey + 'Processing';
    const resultKey = targetKey + 'Result';
    const errorKey = targetKey + 'Error';

    const initialState = {
        [processingKey]: false,
        [errorKey]: null,
    };
    if (!normalizeResultFunction) initialState[resultKey] = null;

    return {
        initialState,
        processing() {
            const obj = {
                [processingKey]: true,
                [errorKey]: null,
            };
            if (!normalizeResultFunction) obj[resultKey] = null;
            return obj;
        },

        result(resultObject) {
            const obj = {
                [processingKey]: false,
                [errorKey]: null,
            };
            if (normalizeResultFunction) {
                return { ...obj, ...normalizeResultFunction(resultObject) };
            }
            return { ...obj, [resultKey]: resultObject };
        },

        error(errorObject) {
            const obj = {
                [processingKey]: false,
                [errorKey]: errorObject,
            };
            if (!normalizeResultFunction) obj[resultKey] = null;

            return obj;
        },

        clear() {
            return initialState;
        },
    };
}

export const requestHelperWithInitialState = (targetKey, state, processingState = false, errorState = null) => {
    const processingKey = targetKey + 'Processing';
    const resultKey = targetKey + 'Result';
    const errorKey = targetKey + 'Error';

    const initialState = {
        [processingKey]: processingState,
        [errorKey]: errorState,
        [resultKey]: state,
    };

    return {
        initialState,
        processing() {
            return {
                [processingKey]: true,
                [errorKey]: errorState,
            };
        },

        result(resultObject) {
            return {
                [processingKey]: false,
                [errorKey]: errorState,
                [resultKey]: resultObject,
            };
        },

        error(errorObject) {
            return {
                [processingKey]: false,
                [errorKey]: errorObject,
            };
        },

        clear() {
            return initialState;
        },
    };
};
