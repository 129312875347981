import React, {useContext} from 'react';
import {UserContext} from 'context/UserContext';
import {CurrencySigns} from 'constants/Finances';

export default function CurrencyRow({
    amount,
    className,
    perMinute = false,
    noExchange = false,
    defaultCurrency = 'GBP',
}) {
    const {currencyCoef, user} = useContext(UserContext);
    let displayCurrency;
    if (!user) {
        displayCurrency = 'GBP';
    } else {
        displayCurrency = user.displayCurrency || user.currency;
    }
    const parsedValue = Number.parseFloat(amount);
    const normalValue = Number.isNaN(parsedValue) ? 0 : parsedValue;

    const gbpAmount = Math.abs(normalValue * (1 / currencyCoef[defaultCurrency.toUpperCase()]));
    const displayCurrencyRow = getCurrencyString(
        displayCurrency,
        gbpAmount,
        currencyCoef[displayCurrency.toUpperCase()]
    );
    const defaultCurrencyRow = getCurrencyString(defaultCurrency, normalValue);

    return (
        <div className={className}>
            {defaultCurrencyRow}
            {!noExchange &&
                defaultCurrency.toUpperCase() !== displayCurrency.toUpperCase() &&
                ' / ~ ' + displayCurrencyRow}
            {perMinute && '/min'}
        </div>
    );
}

export function getCurrencyString(currency, amount, course = 1) {
    const absAmount = Math.abs(amount * course);
    const minus = amount * course >= 0 ? '' : '-';
    const sum = parseFloat(absAmount.toFixed(2));
    const result = sum
        .toFixed(2)
        .toString()
        .replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ');
    return `${minus}${CurrencySigns[currency] || currency}${result}`;
}
