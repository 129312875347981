import { Footer } from "../../Footer/Footer";
import Header from "../../Header/Header";
import { ListSkeleton } from "../../Projects/Projects";
import Skeleton from "../../Skeleton";
import styles from "./ProjectsPage.module.scss";

const ProjectsPage = () => {
    return <div className={styles.container}>
        <Header />
        <div className={styles.content}>
            <Skeleton count={2} containerClassName={styles.tabs} className={styles.tab} />
            <Skeleton className={styles.search} />
            <ListSkeleton count={4} className={styles.list} />
        </div>
        <Footer />
    </div>
}

export default ProjectsPage;