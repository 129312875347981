const { PageLayout } = require("../../PageLayout/PageLayout")
const { default: Profile } = require("../Profile/Profile")

const ProfilePage = () => {
    return (
        <PageLayout>
            <Profile  />
        </PageLayout>
    )
}

export default ProfilePage;