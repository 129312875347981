import React, { createContext, Component } from 'react';

import uploadFile from 'uploadFile';
import makeRequest from 'makeRequest';
import { createRequestHelper } from 'helpers/requestHelper';
import { initDisputesStateAndMethods } from 'helpers/disputesHelpers';

import { DISPUTE_STATES } from 'constants/Disputes';
import { InterviewStorageContextConsumer } from 'context/Interview/storage/InterviewStorageContext';
import { getToken } from 'helpers/getToken';

export const DisputesContext = createContext({});

const openDisputeRequestHelper = createRequestHelper('openDispute');
const respondToDisputeRequestHelper = createRequestHelper('respondToDispute');
const getDisputeDetailsRequestHelper = createRequestHelper('getDisputeDetails');
const getDisputesListRequestHelper = createRequestHelper('disputesList');
const cancelDisputeRequestHelper = createRequestHelper('cancelDispute');
const resolveDisputeRequestHelper = createRequestHelper('resolveDispute');
const requestSupportRequestHelper = createRequestHelper('requestSupport');

class DisputesContextProvider extends Component {
    constructor(props) {
        super(props);
        initDisputesStateAndMethods.call(this);
    }

    openDispute = async (values) => {
        const path = 'disputes/openDispute';
        const data = new FormData();
        data.append('authToken', getToken());
        const { files } = values;

        if (files && files.length) {
            files.forEach((file) => data.append('files', file));
        }
        for (const key in values) {
            if (key !== 'files') {
                data.append(key, values[key]);
            }
        }

        this.setState(openDisputeRequestHelper.processing());

        try {
            const result = await uploadFile({ path, data });
            this.setState(openDisputeRequestHelper.result(result));
            await this.props.interviewStorageContext.updateInterviewInStorage(values.interviewId);
        } catch (e) {
            this.setState(openDisputeRequestHelper.error(e));
        }
    };

    clearOpenDisputeState = () => this.setState(openDisputeRequestHelper.clear());

    respondToDispute = async (values) => {
        const path = 'disputes/respondToDispute';
        const data = new FormData();
        data.append('authToken', getToken());
        const { files } = values;

        if (files && files.length) {
            files.forEach((file) => data.append('files', file));
        }
        for (const key in values) {
            if (key !== 'files') {
                data.append(key, values[key]);
            }
        }

        this.setState(respondToDisputeRequestHelper.processing());

        try {
            const result = await uploadFile({ path, data });
            const updatedList = {
                list: this.state.disputesListResult.list.map((dispute) => {
                    if (dispute._id === result.result._id) dispute.state = result.result.state;
                    return dispute;
                }),
                count: this.state.disputesListResult.count,
            };
            this.setState({
                ...respondToDisputeRequestHelper.result(result),
                ...getDisputeDetailsRequestHelper.result(result),
                ...getDisputesListRequestHelper.result(updatedList),
            });
        } catch (e) {
            this.setState(respondToDisputeRequestHelper.error(e));
        }
    };

    clearRespondToDispute = () => this.setState(respondToDisputeRequestHelper.clear());

    cancelDispute = async (disputeId) => {
        const path = 'disputes/cancelDispute';
        const authToken = getToken();

        this.setState(cancelDisputeRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken, disputeId } });

            const updatedList = {
                list: this.state.disputesListResult.list.filter((dispute) => dispute._id !== disputeId),
                count: this.state.disputesListResult.count - 1,
            };
            this.setState({
                ...cancelDisputeRequestHelper.result(result),
                ...getDisputesListRequestHelper.result(updatedList),
            });
        } catch (e) {
            this.setState(cancelDisputeRequestHelper.error(e));
        }
    };

    resolveDispute = async (disputeId, amountForResource) => {
        const path = 'disputes/resolveDispute';
        const authToken = getToken();

        this.setState(resolveDisputeRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken, disputeId, amountForResource } });
            const updatedList = {
                list: this.state.disputesListResult.list.filter((dispute) => dispute._id !== disputeId),
                count: this.state.disputesListResult.count - 1,
            };
            this.setState({
                ...resolveDisputeRequestHelper.result(result),
                ...getDisputeDetailsRequestHelper.clear(),
                ...getDisputesListRequestHelper.result(updatedList),
            });
        } catch (e) {
            this.setState(resolveDisputeRequestHelper.error(e));
        }
    };

    requestSupport = async (disputeId) => {
        const path = 'disputes/requestSupport';
        const authToken = getToken();

        this.setState(requestSupportRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken, disputeId } });
            const updatedList = {
                list: this.state.disputesListResult.list.map((dispute) => {
                    if (dispute._id === disputeId) {
                        dispute.state = DISPUTE_STATES.supportRequired;
                    }
                    return dispute;
                }),
                count: this.state.disputesListResult.count,
            };

            this.setState({
                ...requestSupportRequestHelper.result(result),
                ...getDisputesListRequestHelper.result(updatedList),
                ...getDisputeDetailsRequestHelper.result(result),
            });
        } catch (e) {
            this.setState(requestSupportRequestHelper.error(e));
        }
    };

    loadMoreDisputes = async (data) => {
        const path = 'disputes/getDisputesList';
        const authToken = getToken();
        this.setState({
            disputesListProcessing: true,
            disputesListError: null,
        });
        try {
            const { result } = await makeRequest({ path, data: { authToken, data } });

            this.setState({
                disputesListProcessing: false,
                disputesListResult: {
                    list: result.list,
                    count: result.count,
                },
            });
        } catch (e) {
            this.setState(getDisputesListRequestHelper.error(e));
        }
    };

    render() {
        const actions = {
            openDispute: this.openDispute,
            clearOpenDisputeState: this.clearOpenDisputeState,
            respondToDispute: this.respondToDispute,
            clearRespondToDispute: this.clearRespondToDispute,
            cancelDispute: this.cancelDispute,
            resolveDispute: this.resolveDispute,
            requestSupport: this.requestSupport,
            loadMoreDisputes: this.loadMoreDisputes,
        };

        return (
            <DisputesContext.Provider value={{ ...this.state, ...actions, ...this.factoryActions }}>
                {this.props.children}
            </DisputesContext.Provider>
        );
    }
}

export default InterviewStorageContextConsumer(DisputesContextProvider);
