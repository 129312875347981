import SocketController from './Socket';

class LiveInterviewSocket extends SocketController {
    disconnect() {
        super.disconnect();
        delete this.socket;
    }
    removeAllListeners(method) {
        if (!this.socket) return;
        super.removeAllListeners(method);
    }
}

export default new LiveInterviewSocket('live');
