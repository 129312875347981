import makeRequest from 'makeRequest';
import {getToken} from 'helpers/getToken';

export default function methodFactory({path, helper, stateField, callback}) {
    const method = async data => {
        const dataWithToken = {
            authToken: getToken(),
            data,
        };
        try {
            this.setState(helper.processing());
            const {status, message, result} = await makeRequest({path, data: dataWithToken});
            if (message && message.status === 'error') {
                this.setState(helper.error(message));
                return;
            }
            if (!status) throw Error(message);
            this.setState(helper.result(result || true));
            if (stateField) this.setState({[stateField]: result});
            if (callback) callback(result);
        } catch (e) {
            this.setState(helper.error(e.message));
        }
    };
    const clearMethod = () => this.setState(helper.clear());
    return [method, clearMethod];
}
