import { Featured } from "../../Browse/Featured/Featured";
import { Filter } from "../../Browse/Public/Filter/Filter";
import { Public } from "../../Browse/Public/Public";
import Header from "../../Header/Header";
import styles from "./BrowsePage.module.scss";

const BrowsePage = () => {
    return (
        <div className={styles.container}>
            <Header />
            <div className={styles.content}>
                <Featured />
                <Filter />
                <Public />
            </div>
        </div>
    );
}

export default BrowsePage;