import UserRoles from 'constants/UserRoles';

export const defaultProjectOptions = {list: []};
export const defaultInterviewFilter = {
    query: '',
    project: '',
    targetMarket: [],
    type: [],
    visibility: '',
    status: '',
    user: '',
    duration: [],
    format: '',
};
export const defaultBrowseFilter = {
    type: [],
    targetMarket: [],
    language: [],
    query: '',
    duration: [],
    format: '',
};
export const defaultProjectInterviewsFilter = {
    project: '',
    status: '',
    type: [],
    targetMarket: [],
    duration: [],
    format: '',
    query: '',
};

export const defaultPublicInterviewsFilter = {
    status: '',
    type: [],
    targetMarket: [],
    duration: [],
    format: '',
    query: '',
};

export const lists = {
    interviews: {
        result: 'interviews',
        processing: 'interviewsProcessing',
        error: 'interviewsError',
        filter: 'filter',
        offset: 'offset',
        limit: 'limit',
        sort: 'sort',
        defaultFilter: defaultInterviewFilter,
        defaultSort: {key: 'minSessionDate', value: 1},
        defaultLimit: 5,
    },
    browseInterviews: {
        result: 'browseInterviews',
        processing: 'browseInterviewsProcessing',
        error: 'browseInterviewsError',
        filter: 'browseFilter',
        offset: 'browseOffset',
        limit: 'browseLimit',
        sort: 'browseSort',
        defaultFilter: defaultBrowseFilter,
        defaultSort: {key: 'minSessionDate', value: 1},
        defaultLimit: 10,
    },
    projectInterviews: {
        result: 'projectInterviews',
        processing: 'projectInterviewsProcessing',
        error: 'projectInterviewsError',
        filter: 'projectInterviewsFilter',
        offset: 'projectInterviewsOffset',
        limit: 'projectInterviewsLimit',
        sort: 'projectInterviewsSort',
        defaultFilter: defaultProjectInterviewsFilter,
        defaultSort: {key: null, value: 0},
        defaultLimit: 5,
    },
    publicInterviews: {
        result: 'publicInterviews',
        processing: 'publicInterviewsProcessing',
        error: 'publicInterviewsError',
        filter: 'publicInterviewsFilter',
        offset: 'publicInterviewsOffset',
        limit: 'publicInterviewsLimit',
        sort: 'publicInterviewsSort',
        defaultFilter: defaultPublicInterviewsFilter,
        defaultSort: {key: 'minSessionDate', value: 1},
        defaultLimit: 10,
    },
    featuredInterviews: {
        result: 'featuredInterviews',
        processing: 'featuredInterviewsProcessing',
        error: 'featuredInterviewsError',
        filter: 'featuredInterviewsFilter',
        offset: 'featuredInterviewsOffset',
        limit: 'featuredInterviewsLimit',
        sort: 'featuredInterviewsSort',
        defaultFilter: defaultPublicInterviewsFilter,
        defaultSort: {key: null, value: 0},
        defaultLimit: 9,
    },
};

export const defaultInterviewersFilter = {
    interview: '',
    role: UserRoles.respondent,
    keywords: '',
    country: '',
    rating: {lower: 0, upper: 5},
    cost: {lower: 0, upper: 1000},
    age: {lower: 16, upper: 120},
    experience: [],
    employment: [],
    gender: [],
    relationship: [],
    subSpeciality: [],
    speciality: [],
    medicalExperience: {lower: 0, upper: 100},
    healthCareFacility: [],
    b2bIndustry: [],
    idVerified: false,
};

export const defaultResourcesFilter = {
    ...defaultInterviewersFilter,
    ids: [],
};

export const defaultApplicantsFilter = {
    ...defaultResourcesFilter,
    session: '',
    screener: {
        [UserRoles.respondent]: {},
        [UserRoles.moderator]: {},
        [UserRoles.translator]: {},
    },
    finishedSuccessfully: false,
};

export const interviewersLists = {
    inviteInterviewers: {
        result: 'interviewers',
        processing: 'interviewersProcessing',
        error: 'interviewersError',
        filter: 'interviewersFilter',
        offset: 'interviewersOffset',
        limit: 'interviewersLimit',
        sort: 'interviewersSort',
        defaultFilter: defaultInterviewersFilter,
        defaultSort: {key: 'applicationDate', value: 1},
        defaultLimit: 8,
    },
    applications: {
        result: 'applications',
        processing: 'applicationsProcessing',
        error: 'applicationsError',
        filter: 'applicationsFilter',
        offset: 'applicationsOffset',
        limit: 'applicationsLimit',
        sort: 'applicationsSort',
        defaultFilter: defaultApplicantsFilter,
        defaultSort: {key: 'applicationDate', value: 1},
        defaultLimit: 8,
    },
    invitations: {
        result: 'invitations',
        processing: 'invitationsProcessing',
        error: 'invitationsError',
        filter: 'invitationsFilter',
        offset: 'invitationsOffset',
        sort: 'invitationsSort',
        limit: 'invitationsLimit',
        defaultFilter: defaultResourcesFilter,
        defaultSort: {key: 'applicationDate', value: 1},
        defaultLimit: 8,
    },
};

export const updateStatuses = {
    apply: 'apply',
};

export const getInitialStateForList = list => ({
    [list.result]: {list: [], totalCount: 0},
    [list.processing]: false,
    [list.error]: null,
    [list.filter]: list.defaultFilter,
    [list.offset]: 0,
    [list.limit]: list.defaultLimit,
    [list.sort]: list.defaultSort,
});
