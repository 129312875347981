import React, { createContext, Component } from 'react';
import makeRequest from 'makeRequest';
import uploadFile from 'uploadFile';
import { createRequestHelper } from 'helpers/requestHelper';
import { getToken } from 'helpers/getToken';
import _ from 'lodash';

export const InterviewActionContext = createContext({});

const saveInterviewRequestHelper = createRequestHelper('saveInterview');
const inviteToInterviewRequestHelper = createRequestHelper('inviteToInterview');
const cancelInvitationRequestHelper = createRequestHelper('cancelInvitation');
const inviteExternalRequestHelper = createRequestHelper('inviteExternal');
const acceptInvitationRequestHelper = createRequestHelper('acceptInvitation');
const applyToInterviewRequestHelper = createRequestHelper('applyToInterview');
const cancelApplicationRequestHelper = createRequestHelper('cancelApplication');
const declineInvitationRequestHelper = createRequestHelper('declineInvitation');
const declineApplicationRequestHelper = createRequestHelper('declineApplication');
const declineApplicationsRequestHelper = createRequestHelper('declineApplications');
const respondentsExportFileRequestHelper = createRequestHelper('respondentsExportFile');

class InterviewActionsContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...saveInterviewRequestHelper.initialState,
            ...inviteToInterviewRequestHelper.initialState,
            ...cancelInvitationRequestHelper.initialState,
            ...inviteToInterviewRequestHelper.initialState,
            ...acceptInvitationRequestHelper.initialState,
            ...applyToInterviewRequestHelper.initialState,
            ...cancelApplicationRequestHelper.initialState,
            ...declineApplicationsRequestHelper.initialState,
            ...declineInvitationRequestHelper.initialState,
            ...declineApplicationRequestHelper.initialState,
            ...respondentsExportFileRequestHelper.initialState,
            scheduledMarkApplications: [],
        };
    }

    clearScheduledMarkApplications = () => this.setState({ scheduledMarkApplications: [] });

    addScheduledMarkApplications = ({ users }) => {
        const ids = users.map((user) => user._id);

        this.setState({
            scheduledMarkApplications: _.uniq([...this.state.scheduledMarkApplications, ...ids]),
        });
    };

    saveInterview = async (data, beforePay = false) => {
        const path = !beforePay ? 'interviews/save' : 'interviews/save-before-pay';
        this.setState(saveInterviewRequestHelper.processing());
        try {
            const result = await uploadFile({ path, data });
            this.setState(saveInterviewRequestHelper.result(result));
            return result;
        } catch (e) {
            this.setState(saveInterviewRequestHelper.error(e));
        }
    };

    inviteToInterview = async ({ userId, interviewId }) => {
        const path = 'interviews/invite';
        const authToken = getToken();
        const data = { userId, interviewId, authToken };
        this.setState(inviteToInterviewRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(inviteToInterviewRequestHelper.result(result));
        } catch (e) {
            this.setState(inviteToInterviewRequestHelper.error(e));
        }
    };

    cancelInvitation = async ({ userId, interviewId }) => {
        const path = 'interviews/cancelInvitation';
        const authToken = getToken();
        const data = { userId, interviewId, authToken };
        this.setState(cancelInvitationRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(cancelInvitationRequestHelper.result(result));
        } catch (e) {
            this.setState(cancelInvitationRequestHelper.error(e));
        }
    };

    respondentsExportFile = async ({ interviewId }, cb) => {
        const path = 'interviews/respondentsExportFile';
        const authToken = getToken();

        try {
            const result = await makeRequest({ path, data: { interviewId, authToken } });
            cb && cb(null, result.path);
        } catch (e) {
            cb && cb(e);
        }
    };

    inviteExternal = async (data) => {
        const path = 'interviews/inviteExternal';
        const authToken = getToken();

        this.setState(inviteExternalRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data: { authToken, ...data } });
            this.setState(inviteExternalRequestHelper.result(result));
        } catch (e) {
            this.setState(inviteExternalRequestHelper.error(e));
        }
    };
    clearInviteExternal = () => this.setState(inviteExternalRequestHelper.clear());

    acceptInvitation = async (data) => {
        const path = 'interviews/acceptInvitation';
        data.authToken = getToken();

        this.setState(acceptInvitationRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(acceptInvitationRequestHelper.result(result));
        } catch (e) {
            this.setState(acceptInvitationRequestHelper.error(e));
        }
    };

    applyToInterview = async (data) => {
        const path = 'interviews/apply';
        data.authToken = getToken();

        this.setState(applyToInterviewRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(acceptInvitationRequestHelper.result(result));
        } catch (e) {
            this.setState(acceptInvitationRequestHelper.error(e));
        }
    };

    cancelApplication = async (data) => {
        const path = 'interviews/cancelApplication';
        data.authToken = getToken();

        this.setState(cancelApplicationRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(cancelApplicationRequestHelper.result(result));
        } catch (e) {
            this.setState(cancelApplicationRequestHelper.error(e));
        }
    };

    declineInvitation = async (data) => {
        const path = 'interviews/declineInvitation';
        data.authToken = getToken();

        this.setState(declineInvitationRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(declineInvitationRequestHelper.result(result));
        } catch (e) {
            this.setState(declineInvitationRequestHelper.error(e));
        }
    };
    declineApplication = async (data) => {
        const path = 'interviews/declineApplication';
        data.authToken = getToken();

        this.setState(declineApplicationRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(declineApplicationRequestHelper.result(result));
        } catch (e) {
            this.setState(declineApplicationRequestHelper.error(e));
        }
    };

    declineApplications = async (data) => {
        const path = 'interviews/declineApplications';
        data.authToken = getToken();

        this.setState(declineApplicationsRequestHelper.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(declineApplicationsRequestHelper.result(result));
        } catch (e) {
            this.setState(declineApplicationsRequestHelper.error(e));
        }
    };

    getApplicationsExportFile = async (data, cb) => {
        const path = 'interviews/getApplicationsExportFile';
        const authToken = getToken();
        try {
            const result = await makeRequest({ path, data: { ...data, authToken } });
            cb && cb(null, result.path);
        } catch (e) {
            cb && cb(e);
        }
    };

    markApplications = async (data) => {
        const path = 'interviews/markApplications';
        const authToken = getToken();
        try {
            await makeRequest({ path, data: { ...data, authToken } });
        } catch (e) {
            console.log(e);
        }
    };

    rescheduleSessions = async (data) => {
        const path = 'interviews/rescheduleSessions';
        const authToken = getToken();
        try {
            await makeRequest({ path, data: { ...data, authToken } });
        } catch (e) {
            console.log(e);
        }
    };

    changeHiredSession = async (data) => {
        const path = 'interviews/changeHiredSession';
        const authToken = getToken();
        try {
            await makeRequest({ path, data: { ...data, authToken } });
        } catch (e) {
            console.log(e);
        }
    };

    uploadReport = async ({ filesToUpload, filesToRemove, interviewId }) => {
        const path = 'interviews/uploadReport';
        const authToken = getToken();
        const data = new FormData();
        data.append('authToken', authToken);
        data.append('interviewId', interviewId);
        data.append('filesToRemove', JSON.stringify(filesToRemove));
        for (let file of filesToUpload) {
            data.append('files', file);
        }
        try {
            await uploadFile({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    uploadHomework = async ({ filesToUpload, filesToRemove, interviewId }) => {
        const path = 'interviews/uploadHomework';
        this.setState({ uploadHomeworkProcessing: true });
        const authToken = getToken();
        const data = new FormData();
        data.append('authToken', authToken);
        data.append('interviewId', interviewId);
        data.append('filesToRemove', JSON.stringify(filesToRemove));
        for (let file of filesToUpload) {
            data.append('files', file);
        }
        try {
            await uploadFile({ path, data });
            this.setState({ uploadHomeworkProcessing: false });
        } catch (e) {
            console.log(e);
            this.setState({ uploadHomeworkProcessing: false });
        }
    };

    editConfirmationEmail = async ({ interviewId, resourceId, emailText, attachments, oldAttachments }) => {
        const path = 'interviews/editConfirmationEmail';
        const data = new FormData();
        data.append('oldAttachments', JSON.stringify(oldAttachments));
        data.append('authToken', getToken());
        data.append('interviewId', interviewId);
        data.append('resourceId', resourceId);
        data.append('emailText', emailText);
        for (let file of attachments) {
            data.append('attachments', file);
        }
        try {
            return await uploadFile({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    sendConfirmationEmail = async ({ interviewId, resourceId }) => {
        const path = 'interviews/sendConfirmationEmail';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId };
        try {
            await makeRequest({ path, data });
            return { error: null };
        } catch (e) {
            return { error: e };
        }
    };

    sendConfirmationBatch = async ({ interviewId, resourcesIds }) => {
        const path = 'interviews/sendConfirmationBatch';
        const authToken = getToken();
        const data = { authToken, interviewId, resourcesIds };
        try {
            await makeRequest({ path, data });
            return { error: null };
        } catch (e) {
            return { error: e };
        }
    };

    editConfirmationSms = async ({ interviewId, resourceId, text }) => {
        const path = 'interviews/editConfirmationSms';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId, text };
        try {
            return await makeRequest({ path, data });
        } catch (e) {
            console.log(e);
        }
    };

    sendConfirmationSms = async ({ interviewId, resourceId }) => {
        const path = 'interviews/sendConfirmationSms';
        const authToken = getToken();
        const data = { authToken, interviewId, resourceId };
        try {
            await makeRequest({ path, data });
            return { error: null };
        } catch (e) {
            return { error: e };
        }
    };

    createCampaign = async ({ text, interviewId, filter }) => {
        const path = 'interviews/createCampaign';
        const authToken = getToken();
        const data = { authToken, interviewId, text, filter };
        try {
            await makeRequest({ path, data });
            return { error: null };
        } catch (e) {
            return { error: e };
        }
    };

    deleteInterview = async ({ interviewId }) => {
        const path = 'interviews/deleteInterview';
        const authToken = getToken();
        const data = { authToken, interviewId };
        try {
            await makeRequest({ path, data });
            return { error: null };
        } catch (e) {
            return { error: e };
        }
    };

    increaseShareCounter = async ({ interviewId, destination }) => {
        const path = 'interviews/increaseShareCounter';
        const data = { interviewId, destination };
        try {
            await makeRequest({ path, data });
            return { error: null };
        } catch (e) {
            return { error: e };
        }
    };

    render() {
        const actions = {
            saveInterview: this.saveInterview,
            inviteToInterview: this.inviteToInterview,
            cancelInvitation: this.cancelInvitation,
            inviteExternal: this.inviteExternal,
            clearInviteExternal: this.clearInviteExternal,
            acceptInvitation: this.acceptInvitation,
            applyToInterview: this.applyToInterview,
            cancelApplication: this.cancelApplication,
            declineInvitation: this.declineInvitation,
            getApplicationsExportFile: this.getApplicationsExportFile,
            declineApplication: this.declineApplication,
            declineApplications: this.declineApplications,
            markApplications: this.markApplications,
            rescheduleSessions: this.rescheduleSessions,
            changeHiredSession: this.changeHiredSession,
            uploadReport: this.uploadReport,
            uploadHomework: this.uploadHomework,
            editConfirmationEmail: this.editConfirmationEmail,
            sendConfirmationEmail: this.sendConfirmationEmail,
            createCampaign: this.createCampaign,
            deleteInterview: this.deleteInterview,
            increaseShareCounter: this.increaseShareCounter,
            sendConfirmationBatch: this.sendConfirmationBatch,
            respondentsExportFile: this.respondentsExportFile,
            clearScheduledMarkApplications: this.clearScheduledMarkApplications,
            addScheduledMarkApplications: this.addScheduledMarkApplications,
            editConfirmationSms: this.editConfirmationSms,
            sendConfirmationSms: this.sendConfirmationSms,
        };

        return (
            <InterviewActionContext.Provider value={{ ...this.state, ...actions }}>
                {this.props.children}
            </InterviewActionContext.Provider>
        );
    }
}

export default InterviewActionsContextProvider;
