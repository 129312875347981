import React, { createContext, Component } from 'react';
import makeRequest from 'makeRequest';
import { noop, debounce } from 'lodash';

import { UserContextConsumer } from './UserContext';
import { createRequestHelper, requestHelperWithInitialState } from 'helpers/requestHelper';
import BackendSocketController from 'controllers/BackendSocket';
import uploadFile from '../uploadFile';
import { getToken } from 'helpers/getToken';

const fetchScreenersRequestHelper = requestHelperWithInitialState('fetchScreeners', { list: [], totalCount: 0 });
const duplicateScreenerRequestHelper = createRequestHelper('duplicateScreener');
const deleteScreenerRequestHelper = createRequestHelper('deleteScreener');
const fetchScreenerByIdRequestHelper = createRequestHelper('fetchScreenerById');
const fetchAnswerToScreenerByIdRequestHelper = createRequestHelper('fetchAnswerToScreenerById');
const saveAnswerToScreener = createRequestHelper('saveAnswerToScreener');
const getScreenersStatuses = requestHelperWithInitialState('getScreenersStatuses', {});

export const ScreenerContext = createContext({});

class ScreenerContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...fetchScreenersRequestHelper.initialState,
            ...duplicateScreenerRequestHelper.initialState,
            ...deleteScreenerRequestHelper.initialState,
            ...fetchScreenerByIdRequestHelper.initialState,
            ...fetchAnswerToScreenerByIdRequestHelper.initialState,
            ...getScreenersStatuses.initialState,
            uploadScreenerResult: null,
        };

        BackendSocketController.on('screener::videoUploaded', this.videoScreenerUploadedHandler);
        BackendSocketController.on('screener::newScreener', this.fetchScreeners);
        window.scc = this;
    }

    componentDidMount() {
        this.fetchScreeners();
    }

    videoScreenerUploadedHandler = async (uploadScreenerResult) => this.setState({ uploadScreenerResult });

    clearUploadScreenerResult = async () => this.setState({ uploadScreenerResult: null });

    fetchScreeners = async (
        filter = { isDeleted: false },
        fields = 'title _id user isDraft',
        offset = 0,
        limit = 100,
    ) => {
        const path = 'screener/list';
        const data = {
            authToken: getToken(),
            filter,
            fields,
            offset,
            limit,
        };
        if (!data.authToken) return;
        this.setState(fetchScreenersRequestHelper.processing());

        try {
            const result = await makeRequest({ path, data });
            this.setState(fetchScreenersRequestHelper.result(result));
        } catch (err) {
            this.setState(fetchScreenersRequestHelper.error(err));
        }
    };

    clearFetchScreeners = () => this.setState(fetchScreenersRequestHelper.clear());

    duplicateScreener = async (screenerId) => {
        const path = 'screener/duplicate';
        const data = { authToken: getToken(), screenerId };

        this.setState(duplicateScreenerRequestHelper.processing());

        try {
            const result = await makeRequest({ path, data });
            this.setState(duplicateScreenerRequestHelper.result(result));
        } catch (err) {
            this.setState(duplicateScreenerRequestHelper.error(err));
        }
    };

    deleteScreener = async (screenerId) => {
        const path = 'screener/delete';
        const data = { authToken: getToken(), screenerId };

        this.setState(deleteScreenerRequestHelper.processing());

        try {
            const result = await makeRequest({ path, data });
            this.setState(deleteScreenerRequestHelper.result(result));
        } catch (err) {
            this.setState(deleteScreenerRequestHelper.error(err));
        }
    };

    fetchScreenerById = async (screenerId) => {
        const path = 'screener/fetchScreenerById';
        const data = { authToken: getToken(), screenerId };

        this.setState(fetchScreenerByIdRequestHelper.processing());

        try {
            const result = await makeRequest({ path, data });
            this.setState(fetchScreenerByIdRequestHelper.result(result));
            return result;
        } catch (err) {
            this.setState(fetchScreenerByIdRequestHelper.error(err));
        }
    };

    clearFetchScreenerById = () => this.setState(fetchScreenerByIdRequestHelper.clear());

    fetchAnswerToScreenerById = async ({ screenerId, userId, interviewId }) => {
        const path = 'screener/fetchAnswerToScreenerById';
        const data = { authToken: getToken(), screenerId, userId, interviewId };

        this.setState(fetchAnswerToScreenerByIdRequestHelper.processing());

        try {
            const result = await makeRequest({ path, data });
            this.setState(fetchAnswerToScreenerByIdRequestHelper.result(result));
            return result;
        } catch (err) {
            this.setState(fetchAnswerToScreenerByIdRequestHelper.error(err));
        }
    };

    clearFetchAnswerToScreenerById = () => this.setState(fetchAnswerToScreenerByIdRequestHelper.clear());

    saveAnswerToScreener = async (data) => {
        const path = 'screener/saveAnswerToScreener';
        data.authToken = getToken();

        this.setState(saveAnswerToScreener.processing());

        try {
            await makeRequest({ path, data });
            this.setState(saveAnswerToScreener.result());
        } catch (err) {
            this.setState(saveAnswerToScreener.error(err));
            throw err;
        }
    };

    uploadVideoResponse = async (video, cb = noop) => {
        const path = 'screener/uploadVideoToScreener';
        const data = new FormData();
        data.append('authToken', getToken());
        data.append('video', video);

        try {
            const result = uploadFile({ path, data });
            cb(null, result);
        } catch (e) {
            cb(e);
        }
    };

    autoSaveScreener = async (data) => {
        const path = 'screener/autosave';
        data.authToken = getToken();

        try {
            const { screenerId } = await makeRequest({ path, data });
            return screenerId;
        } catch (e) {
            console.error('Error autosaving screener');
            console.log(e);
        }
    };

    getScreenersStatuses = async (data) => {
        const path = 'screener/getScreenersStatuses';
        data.authToken = getToken();
        this.setState(getScreenersStatuses.processing());
        try {
            const result = await makeRequest({ path, data });
            this.setState(getScreenersStatuses.result(result));
        } catch (e) {
            this.setState(getScreenersStatuses.error(e));
        }
    };

    render() {
        const actions = {
            createScreener: this.createScreener,
            editScreener: this.editScreener,
            fetchScreeners: this.fetchScreeners,
            debouncedFetchScreeners: debounce(this.fetchScreeners, 200),
            clearFetchScreeners: this.clearFetchScreeners,
            duplicateScreener: this.duplicateScreener,
            deleteScreener: this.deleteScreener,
            fetchScreenerById: this.fetchScreenerById,
            clearFetchScreenerById: this.clearFetchScreenerById,
            fetchAnswerToScreenerById: this.fetchAnswerToScreenerById,
            clearFetchAnswerToScreenerById: this.clearFetchAnswerToScreenerById,
            saveAnswerToScreener: this.saveAnswerToScreener,
            clearUploadScreenerResult: this.clearUploadScreenerResult,
            uploadVideoResponse: this.uploadVideoResponse,
            autoSaveScreener: this.autoSaveScreener,
            getScreenersStatuses: this.getScreenersStatuses,
        };

        return (
            <ScreenerContext.Provider value={{ ...this.state, ...actions }}>
                {this.props.children}
            </ScreenerContext.Provider>
        );
    }
}

export default UserContextConsumer(ScreenerContextProvider);
