import io from 'socket.io-client';

export default class SocketController {
    constructor(name) {
        this.socket = null;
        if (name) window[name] = this;
    }

    async getSocket() {
        return this.socket;
    }

    async connect(url, query, path = '/socket.io') {
        this.socket = io(url, {
            transports: ['websocket'],
            query,
            forceNew: true,
            path,
        });
    }

    async broadcast(method, data) {
        return new Promise(resolve => {
            this.socket.broadcast.emit(method, data, (error, response) => resolve({error, response}));
        });
    }

    async emit(method, data) {
        return new Promise(resolve => {
            this.socket.emit(method, data, (error, response) => resolve({error, response}));
        });
    }

    on(method, listener) {
        this.socket.on(method, listener);
    }

    once(method, listener) {
        this.socket.on(method, (...args) => {
            console.log(method);
            console.log('receiving');
            listener(...args);
            this.socket.off(method);
        });
    }

    off(method, listener) {
        this.socket.off(method, listener);
    }

    removeAllListeners(method) {
        this.socket.removeAllListeners(method);
    }

    disconnect() {
        if (!this.socket) return;
        this.socket.disconnect();
        this.socket.removeAllListeners();
    }

    isConnected() {
        return this.socket && this.socket.connected;
    }

    async uploadFile(connectionInfo, method, files, form) {
        const {token, connectionUrl} = connectionInfo;
        const url = `${connectionUrl}/signal/v1${method}?token=${token}`;
        const data = new FormData();
        data.append('data', JSON.stringify(form));
        Object.keys(files).forEach(f => data.append(f, files[f]));
        const response = await fetch(url, {method: 'post', body: data});
        return {
            error: response.status !== 200,
            response: await response.json(),
        };
    }
}
