import React, { createContext, Component } from 'react';
import makeRequest from 'makeRequest';
import uploadFile from 'uploadFile';
import mangoPay from 'mangopay-cardregistration-js-kit';

import { UserContextConsumer } from 'context/UserContext';
import { isAdmin, isObserver } from 'constants/UserRoles';
import { insertStateAndMethods } from 'context/Payment/PaymentContextHelper';
import { createRequestHelper } from 'helpers/requestHelper';
import config from 'config';
import BackendSocketController from 'controllers/BackendSocket';
import collectBrowserData from 'helpers/collectBrowserData';
import { getToken } from 'helpers/getToken';

const getPayInRequestHelper = createRequestHelper('getPayIn');
const addCardRequestHelper = createRequestHelper('addCard');
const payInWebRequestHelper = createRequestHelper('payInWeb');
const sendKycRequestHelper = createRequestHelper('sendKyc');

export const PaymentContext = createContext({});

class PaymentContextProvider extends Component {
    constructor(props) {
        super(props);
        insertStateAndMethods.call(this);
        window.ppc = this;
        BackendSocketController.on('payment::ubo', this.updateUboSocketHandler);
        BackendSocketController.on('payment::kyc', this.updateKycSocketHandler);
        BackendSocketController.on('payment::kyc_outdated', this.updateOutDatedKycSocketHandler);
        BackendSocketController.on('payment::balance', this.updateBalanceSocketHandler);
        BackendSocketController.on('payment::wallet', this.updateWalletHistorySocketHandler);
        BackendSocketController.on('payment::payout', this.updatePayoutHistorySocketHandler);
        BackendSocketController.on('payment::payment', this.updatePaymentHistorySocketHandler);
    }
    updateUboSocketHandler = (declaration) => this.props.userContext.updateDeclarationState(declaration);
    updateWalletHistorySocketHandler = (payment) => {
        const { paymentHistory } = this.state;
        if (paymentHistory.type !== 'wallet') return;
        paymentHistory.count++;
        paymentHistory.list.unshift(payment);
        this.setState({ paymentHistory });
    };
    updateKycSocketHandler = (kyc) => {
        this.props.userContext.updateKycState(kyc);
        this.setState(sendKycRequestHelper.initialState);
    };
    updateOutDatedKycSocketHandler = (kyc) => {
        this.props.userContext.updateKycState(kyc);
        this.setState(sendKycRequestHelper.initialState);
    };
    updateBalanceSocketHandler = () => this.getBalance();
    updatePayoutHistorySocketHandler = (payment) => {
        const { paymentHistory } = this.state;
        if (paymentHistory.type !== 'payout') return;
        paymentHistory.count++;
        paymentHistory.list.unshift(payment);
        this.setState({ paymentHistory });
    };
    updatePaymentHistorySocketHandler = (payment) => {
        const { paymentHistory } = this.state;
        if (paymentHistory.type !== 'payin') return;
        paymentHistory.count++;
        paymentHistory.list.unshift(payment);
        this.setState({ paymentHistory });
    };

    getInitInfo = () => {
        const { user } = this.props.userContext;

        if (!user || isObserver(user.role)) return;

        this.getBalance();
        this.getBankAccounts();
        this.getCards();
        this.getPlans();
    };

    componentDidMount() {
        this.getInitInfo();
    }

    componentDidUpdate(prevProps) {
        const prevUser = prevProps.userContext.user;
        const currentUser = this.props.userContext.user;

        const isUserChanged = this.props.userContext.isUserChanged(prevUser, currentUser);

        if (isUserChanged) {
            this.getInitInfo();
        }
    }

    setCreditsToBuy = (creditsToBuy) => this.setState({ creditsToBuy });

    buyCredits = async ({ cardId, returnPath }) => {
        const path = 'payment/payInWeb';
        const data = {
            authToken: getToken(),
            amount: this.state.creditsToBuy,
            cardId,
            returnPath,
            BrowserData: collectBrowserData(),
        };

        try {
            this.setState(payInWebRequestHelper.processing());
            const result = await makeRequest({ path, data });

            if (result.Status === 'FAILED') throw Error(result.ResultMessage);

            if (result.Status === 'SUCCEEDED') {
                this.setState(payInWebRequestHelper.result(true));
            } else {
                this.setState(payInWebRequestHelper.clear());
                localStorage.setItem('lastPayment', JSON.stringify(result));
                if (result.SecureModeRedirectURL || result.RedirectURL) {
                    window.location.replace(cardId ? result.SecureModeRedirectURL : result.RedirectURL);
                }
            }
        } catch (e) {
            this.setState(payInWebRequestHelper.error(e.message));
        }
    };

    payInWebClear = () => this.setState(payInWebRequestHelper.clear());

    clearBuyCreditsError = () => this.setState({ buyCreditsError: null });

    getPayIn = async (payInId) => {
        if (!payInId) {
            this.setState(getPayInRequestHelper.error('PayIn id required'));
            return;
        }

        const path = 'payment/getPayIn';
        const data = {
            authToken: getToken(),
            payInId,
        };

        try {
            const result = await makeRequest({ path, data });
            this.setState(getPayInRequestHelper.result(result), this.getBalance);
        } catch (e) {
            this.setState(getPayInRequestHelper.error(e.message));
        }
    };

    clearGetPayIn = () => this.setState(getPayInRequestHelper.initialState);

    getBalance = async () => {
        const { user } = this.props.userContext;
        if (!user || isAdmin(user.role)) return;

        const path = 'payment/getBalance';
        const data = { authToken: getToken() };

        try {
            const result = await makeRequest({ path, data });
            this.setState({ balance: result.balance, frozen: result.frozen, locked: result.locked });
        } catch (e) {
            console.log('getBalance, Error', { e });
        }
    };

    getBalanceById = async (userId) => {
        const path = 'payment/getBalanceById';
        const data = { authToken: getToken(), userId };

        try {
            const result = await makeRequest({ path, data });
            this.setState({ balanceById: result.balance });
        } catch (e) {
            console.log('getBalanceById, Error', e);
        }
    };

    sendKycDocuments = async ({ files }) => {
        const path = 'payment/sendKycDocuments';

        const data = new FormData();
        data.append('authToken', getToken());
        const appendPage = (documentType, file) => data.append(documentType, file);
        const appendDocument = (documentType) => files[documentType].forEach((file) => appendPage(documentType, file));
        Object.keys(files).forEach((documentType) => appendDocument(documentType));

        try {
            this.setState(sendKycRequestHelper.processing());
            const { status, result, message } = await uploadFile({ path, data });
            if (!status) throw Error(message);
            this.setState(sendKycRequestHelper.result(true));
            this.props.userContext.updateKycState(result);
        } catch (e) {
            this.setState(sendKycRequestHelper.error(e.message));
        }
    };

    clearSendKycDocuments = () => this.setState(sendKycRequestHelper.initialState);

    clearGetBalanceById = () => {
        this.setState({
            balanceById: 0,
        });
    };

    addCard = async (data) => {
        const path = 'payment/registerCard';
        const authToken = getToken();
        try {
            this.setState(addCardRequestHelper.processing());
            const { status, message, result } = await makeRequest({ path, data: { authToken, data, stage: 0 } });
            if (!status) throw Error(message);
            const { AccessKey, PreregistrationData, CardRegistrationURL, Id, CardType } = result;
            const cardData = {
                cardNumber: data.CardNumber,
                cardExpirationDate: data.ExpirationDate,
                cardCvx: data.CVV,
                cardType: CardType,
            };
            const onSuccess = async (res) => {
                const regData = {
                    authToken,
                    stage: 1,
                    data: {
                        Id: res.Id,
                        RegistrationData: res.RegistrationData,
                        UserId: res.UserId,
                        CardId: res.CardId,
                    },
                };
                const { status, message, result } = await makeRequest({ path, data: regData });
                if (!status) throw Error(message);
                this.setState(addCardRequestHelper.result(result));
                this.getCards();
            };
            const onError = (error) => {
                this.setState(addCardRequestHelper.error(error.ResultMessage || error.message));
            };
            mangoPay.cardRegistration.baseURL = config.mangoPayUrl;
            mangoPay.cardRegistration.clientId = config.mangoPayUserName;
            mangoPay.cardRegistration.init({
                cardRegistrationURL: CardRegistrationURL,
                preregistrationData: PreregistrationData,
                accessKey: AccessKey,
                Id,
            });
            mangoPay.cardRegistration.registerCard(cardData, onSuccess, onError);
        } catch (e) {
            this.setState(addCardRequestHelper.error(e.message));
        }
    };

    clearAddCard = () => this.setState(addCardRequestHelper.clear());

    getBankAccounts = async () => {
        const path = 'payment/getAccounts';
        const data = { authToken: getToken() };

        try {
            const { status, list } = await makeRequest({ path, data });
            if (status) {
                this.setState({ bankAccounts: list });
            }
        } catch (e) {
            console.log(e);
        }
    };

    clearHistory = () => this.setState({ paymentHistory: { count: 0, list: [], type: null } });

    render() {
        const actions = {
            setCreditsToBuy: this.setCreditsToBuy,
            buyCredits: this.buyCredits,
            clearBuyCreditsError: this.clearBuyCreditsError,
            getPayIn: this.getPayIn,
            clearGetPayIn: this.clearGetPayIn,
            getBalance: this.getBalance,
            getBalanceById: this.getBalanceById,
            clearGetBalanceById: this.clearGetBalanceById,
            sendKycDocuments: this.sendKycDocuments,
            getBankAccounts: this.getBankAccounts,
            addCard: this.addCard,
            clearAddCard: this.clearAddCard,
            clearHistory: this.clearHistory,
            clearSendKycDocuments: this.clearSendKycDocuments,
            payInWebClear: this.payInWebClear,
        };
        return (
            <PaymentContext.Provider value={{ ...this.state, ...this.factoryActions, ...actions }}>
                {this.props.children}
            </PaymentContext.Provider>
        );
    }
}

export default UserContextConsumer(PaymentContextProvider);
