const ResourceStatusInformation = {
    invitation: 'invitation',
    application: 'application',
    hired: 'hired',
    cancelled: 'cancelled',
    past: 'past',
    paid: 'paid',
    declined: 'declined',
    invitationCancelled: 'invitation_cancelled',
    applicationCancelled: 'application_cancelled',
    hiredCancelled: 'hired_cancelled',
    isInvitation: function(status) {
        return status === this.invitation;
    },
    isApplication: function(status) {
        return status === this.application;
    },
    isApplicationCancelled: function(status) {
        return status === this.applicationCancelled;
    },
    isHired: function(status) {
        return status === this.hired;
    },
    isCancelled: function(status) {
        return status === this.cancelled;
    },
    isPast: function(status) {
        return status === this.past;
    },
    isCancelledAny: function(status) {
        return [this.invitationCancelled, this.applicationCancelled, this.hiredCancelled].includes(status);
    },
    isHiredCancelled: function(status) {
        return status === this.hiredCancelled;
    },
    isInvitationDeclined: function(status) {
        return status === this.invitationCancelled;
    },
};

export default ResourceStatusInformation;
