export const OrderStatuses = {
    closed: 'closed',
    opened: 'opened',
};

export const isOrderClosed = status => status === OrderStatuses.closed;
export const isOrderOpened = status => status === OrderStatuses.opened;
export const isOrderHasStatus = status => isOrderClosed(status) || isOrderOpened(status);

export const OrderStatusesOptions = {
    all: 'All statuses',
    [OrderStatuses.closed]: 'Closed',
    [OrderStatuses.opened]: 'Opened',
};
