import React, {Component, createContext} from 'react';
import {createRequestHelper, requestHelperWithInitialState} from 'helpers/requestHelper';
import makeRequest from 'makeRequest';
import {UserContextConsumer} from './UserContext';
import {ChatContextConsumer} from './ChatContext';
import NotificationsEmitter from 'emitters/NotificationsEmitter';
import BackendSocketController from 'controllers/BackendSocket';
import {getToken} from 'helpers/getToken';

export const NotificationsContext = createContext(null);

const getNotificationsRequestHelper = requestHelperWithInitialState('getNotifications', {list: [], count: 0});
const getPageNotificationsRequestHelper = requestHelperWithInitialState('getPageNotifications', {list: [], count: 0});
const markAsReadRequestHelper = createRequestHelper('markAsRead');
const deleteAlLRequestHelper = createRequestHelper('deleteAll');

class NotificationsContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...getNotificationsRequestHelper.initialState,
            ...getPageNotificationsRequestHelper.initialState,
            ...deleteAlLRequestHelper.initialState,
            loadMoreNotificationsProcessing: false,
            loadMorePageNotificationsProcessing: false,
            initialNotificationsLoaded: false,
            initialPageNotificationsLoaded: false,
            notificationsPopUpOpen: false,
        };
    }

    componentDidMount() {
        const user = this.props.userContext.user;
        if (user) {
            this.getNotifications({limit: 10, offset: 0});
            this.applyListeners();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const user = this.props.userContext.user;
        const prevUser = prevProps.userContext.user;
        if (!user) return;
        if (!prevUser) {
            this.setState({initialNotificationsLoaded: false});
            this.applyListeners();
            this.getNotifications({limit: 10, offset: 0});
            return;
        }
        if (prevUser.id !== user.id) {
            this.setState({initialNotificationsLoaded: false});
            this.applyListeners();
            this.getNotifications({limit: 10, offset: 0});
        }
    }

    getNotifications = async data => {
        const path = 'notifications/getNotifications';
        const authToken = getToken();

        this.setState(getNotificationsRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState({
                ...getNotificationsRequestHelper.result(result),
                initialNotificationsLoaded: true,
            });
        } catch (e) {
            this.setState(getNotificationsRequestHelper.error(e));
        }
    };
    clearNotifications = () => this.setState(getNotificationsRequestHelper.initialState);

    loadMoreNotifications = async data => {
        const path = 'notifications/getNotifications';
        const authToken = getToken();

        this.setState({
            ...getNotificationsRequestHelper.processing(),
            loadMoreNotificationsProcessing: true,
        });
        try {
            const result = await makeRequest({path, data: {authToken, ...data, markAsRead: true}});
            const updatedList = this.state.getNotificationsResult.list.concat(result.list);
            this.setState({
                ...getNotificationsRequestHelper.result({
                    list: updatedList,
                    count: this.state.getNotificationsResult.count,
                }),
                loadMoreNotificationsProcessing: false,
            });
        } catch (e) {
            this.setState({
                ...getNotificationsRequestHelper.error(e),
                loadMoreNotificationsProcessing: false,
            });
        }
    };

    markNotificationsAsRead = async data => {
        const path = 'notifications/markAsRead';
        const authToken = getToken();

        this.setState(markAsReadRequestHelper.processing());
        try {
            await makeRequest({path, data: {authToken, ...data}});
            const {list: currentNotifications, count} = this.state.getNotificationsResult;
            const {notificationsIds} = data;
            const newNotifications = currentNotifications.map(n => {
                if (notificationsIds.includes(n._id)) {
                    n.read = true;
                }
                return n;
            });

            this.setState({
                ...markAsReadRequestHelper.result(),
                ...getNotificationsRequestHelper.result({list: newNotifications, count}),
            });
        } catch (e) {
            this.setState(markAsReadRequestHelper.error(e));
        }
    };

    markAllAsRead = async () => {
        const path = 'notifications/markAllAsRead';
        const authToken = getToken();

        await makeRequest({path, data: {authToken}});
        this.setState({
            getNotificationsResult: {
                list: [],
                count: 0,
            },
        });
    };

    getPageNotifications = async data => {
        const path = 'notifications/getNotifications';
        const authToken = getToken();

        this.setState(getPageNotificationsRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState({
                ...getPageNotificationsRequestHelper.result(result),
                initialPageNotificationsLoaded: true,
            });
        } catch (e) {
            this.setState({
                ...getPageNotificationsRequestHelper.error(e),
                initialPageNotificationsLoaded: true,
            });
        }
    };

    loadMorePageNotifications = async data => {
        const path = 'notifications/getNotifications';
        const authToken = getToken();

        this.setState({
            ...getPageNotificationsRequestHelper.processing(),
            loadMorePageNotificationsProcessing: true,
        });
        try {
            const result = await makeRequest({path, data: {authToken, ...data, markAsRead: true}});
            const updatedList = this.state.getPageNotificationsResult.list.concat(result.list);
            this.setState({
                ...getPageNotificationsRequestHelper.result({
                    list: updatedList,
                    count: this.state.getPageNotificationsResult.count,
                }),
                loadMorePageNotificationsProcessing: false,
            });
        } catch (e) {
            this.setState({
                ...getPageNotificationsRequestHelper.error(e),
                loadMorePageNotificationsProcessing: false,
            });
        }
    };

    deleteAll = async () => {
        const path = 'notifications/deleteAll';
        const authToken = getToken();

        this.setState(deleteAlLRequestHelper.processing());
        try {
            await makeRequest({path, data: {authToken}});
            this.setState({
                ...deleteAlLRequestHelper.result({}),
                getPageNotificationsResult: {list: [], count: 0},
                getNotificationsResult: {list: [], count: 0},
            });
        } catch (e) {
            this.setState(deleteAlLRequestHelper.error(e));
        }
    };

    setNotificationsPopUp = status => this.setState({notificationsPopUpOpen: status});

    applyListeners() {
        BackendSocketController.off('notification::new');
        BackendSocketController.off('popup::new');
        BackendSocketController.on('notification::new', data => {
            const shouldAddNotification = !data.data.some(
                el => el.label === 'dialogId' && el.value === this.props.chatContext.openedDialog.dialog?.id
            );
            const isChatsPage = document.location.pathname.includes('chat');
            if (shouldAddNotification || !isChatsPage) {
                const newPopUpState = {
                    list: [data, ...this.state.getNotificationsResult.list],
                    count: this.state.getNotificationsResult.count + 1,
                };

                const newPageState = {
                    list: [data, ...this.state.getPageNotificationsResult.list],
                    count: this.state.getPageNotificationsResult.count + 1,
                };

                this.setState({
                    getNotificationsResult: newPopUpState,
                    getPageNotificationsResult: newPageState,
                });
                NotificationsEmitter.emit('notification::new', data);
            }
        });
        BackendSocketController.on('popup::new', data => {
            const shouldAddNotification = !data.data.some(
                el => el.label === 'dialogId' && el.value === this.props.chatContext.openedDialog.dialog?.id
            );
            const isChatsPage = document.location.pathname.includes('chat');
            if (shouldAddNotification || !isChatsPage) {
                NotificationsEmitter.emit('popup::new', data);
            }
        });
    }

    render() {
        const actions = {
            getNotifications: this.getNotifications,
            loadMoreNotifications: this.loadMoreNotifications,
            markNotificationsAsRead: this.markNotificationsAsRead,
            clearNotifications: this.clearNotifications,
            markAllAsRead: this.markAllAsRead,
            getPageNotifications: this.getPageNotifications,
            deleteAll: this.deleteAll,
            loadMorePageNotifications: this.loadMorePageNotifications,
            setNotificationsPopUp: this.setNotificationsPopUp,
        };

        return (
            <NotificationsContext.Provider value={{...this.state, ...actions}}>
                {this.props.children}
            </NotificationsContext.Provider>
        );
    }
}

export default UserContextConsumer(ChatContextConsumer(NotificationsContextProvider));
