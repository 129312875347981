import React, { Component, createContext } from 'react';
import _ from 'lodash';

import { OnlineInterviewContextConsumer } from './OnlineInterviewContext';
import { InterviewContextConsumer } from 'context/Interview/InterviewContext';
import { UserContextConsumer } from '../UserContext';

import WaitingRoomSocket from 'controllers/WaitingRoomSocket';

import { getBackendSignalURL, getVideoServerURL, getWaitingRoomSignalPath } from 'components/common/apiPrefix';
import { withConsumers } from 'helpers/contexts';
import makeRequest from 'makeRequest';
import NotificationsEmitter from 'emitters/NotificationsEmitter';
import { InterviewStorageContextConsumer } from 'context/Interview/storage/InterviewStorageContext';
import { getToken } from 'helpers/getToken';
import VideoSocketController from 'controllers/VideoSocket';
import routes from '../../constants/Routes';

export const WaitingRoomContext = createContext({});

const DEFAULT_STATE = {
    waitingRoomState: null,
    waitingRoomUser: null,
    connected: false,
    connecting: false,
    error: null,
    isFinished: false,
    connectionIssues: false,
    isWaitingModerator: false,
    ownerGone: false,
    awaitingReplacement: false,
    instanceNotReady: false,
    connectionError: false,
    pendingUsers: [],
    editingName: {
        user: null,
        value: '',
    },
};

class WaitingRoomContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
        window.wrc = this;
    }

    componentDidUpdate(prevProps) {
        const {
            isOwner,
            isConnected,
            videoServer,
            videoServerPort,
            interviewUser,
            usersState,
        } = this.props.onlineInterviewContext;
        const { connected, connecting } = this.state;
        const isSuperAdmin = interviewUser && interviewUser.isSuperAdmin;
        if (isOwner && isConnected && !connected && !connecting) {
            this.joinOwner(videoServer, videoServerPort);
            return;
        }
        if (isSuperAdmin && isConnected && !connected && !connecting) this.joinAdmin(videoServer, videoServerPort);
        if (this.isUsersStateChanged(prevProps)) {
            this.setState({
                pendingUsers: this.state.pendingUsers.filter((id) => {
                    return !usersState.users.some((u) => u.id === id);
                }),
            });
        }
    }

    isUsersStateChanged(prevProps) {
        const noPrevUsersState = !prevProps.onlineInterviewContext.usersState;
        if (noPrevUsersState && this.props.onlineInterviewContext.usersState) return true;
        if (noPrevUsersState || !this.props.onlineInterviewContext.usersState) return false;

        const prevIds = prevProps.onlineInterviewContext.usersState.users.map((u) => u.id);
        const currentIds = this.props.onlineInterviewContext.usersState.users.map((u) => u.id);
        return !!_.xor(prevIds, currentIds).length;
    }

    joinOwner = async (videoServer, videoServerPort) => {
        this.setState({ connecting: true });
        const authToken = getToken();
        const query = {
            videoServer,
            videoServerPort,
            authToken,
        };
        const videoUrl = getVideoServerURL();
        await WaitingRoomSocket.connect(getVideoServerURL(), query, '/waitingRoom');
        this.listenToEvents();
        const {
            user: {
                id: userId,
                team: { memberId },
            },
        } = this.props.userContext;
        const { response, error } = await WaitingRoomSocket.emit('waitingRoom:owner', { userId: memberId || userId });
        if (error) return this.setState({ error });

        this.setState({
            waitingRoomState: response,
            connected: true,
            connecting: false,
        });
    };

    joinAdmin = async (videoServer, videoServerPort) => {
        this.setState({ connecting: true });
        const authToken = getToken();
        const query = {
            videoServer,
            videoServerPort,
            authToken,
        };
        await WaitingRoomSocket.connect(getVideoServerURL(), query, '/waitingRoom');
        this.listenToEvents();

        const {
            user: { id: userId },
        } = this.props.userContext;
        const { response, error } = await WaitingRoomSocket.emit('waitingRoom:admin', { userId });
        if (error) return this.setState({ error });

        this.setState({
            waitingRoomState: response,
            connected: true,
            connecting: false,
        });
    };

    joinWaitingRoom = async ({ interviewId, sessionId }) => {
        const authToken = getToken();
        const {
            user: { id: userId },
        } = this.props.userContext;

        const path = 'interviews/waitingRoom';
        const data = {
            authToken,
            interviewId,
            sessionId,
        };

        try {
            const { videoServerUrl, videoServerPort, serverStatus } = await makeRequest({
                path,
                data,
            });
            const query = {
                token: authToken,
                videoServer: videoServerUrl,
                videoServerPort: videoServerPort,
            };
            if (serverStatus !== 'ready') {
                this.setState({ instanceNotReady: true });
                return;
            }

            await WaitingRoomSocket.connect(getVideoServerURL(), query, getWaitingRoomSignalPath());
            this.listenToEvents();

            const { response, error } = await WaitingRoomSocket.emit('waitingRoom:join', { userId });
            if (response && response.kicked) {
                this.leaveWaitingRoom();
                document.location.href = routes.home.path;
                return;
            }
            if (error) return this.setState({ error });

            const { user, waitingRoom } = response;
            this.setState({
                waitingRoomState: waitingRoom,
                waitingRoomUser: user,
                connected: true,
            });
        } catch (e) {
            this.setState({ error: e });
        }
    };

    joinModeratorWaitingRoom = async ({ interviewId, sessionId }) => {
        const authToken = getToken();
        const {
            user: { id: userId },
        } = this.props.userContext;

        const path = 'interviews/waitingRoom';
        const data = {
            authToken,
            interviewId,
            sessionId,
        };

        try {
            const { videoServerUrl, videoServerPort } = await makeRequest({
                path,
                data,
            });
            const query = {
                token: authToken,
                videoServer: videoServerUrl,
                videoServerPort: videoServerPort,
            };
            await WaitingRoomSocket.connect(getBackendSignalURL(), query, getWaitingRoomSignalPath());
            this.listenToEvents();

            const { error } = await WaitingRoomSocket.emit('waitingRoom:moderatorWaiting', { userId });
            if (error) return this.setState({ error });

            this.setState({ isWaitingModerator: true });
        } catch (e) {
            this.setState({ error: e });
        }
    };

    leaveWaitingRoom = () => {
        WaitingRoomSocket.disconnect();
        this.setState(DEFAULT_STATE);
    };

    setReady = (status, connectionTest, devices) => {
        const {
            user: { id: userId },
        } = this.props.userContext;

        WaitingRoomSocket.emit('waitingRoom:ready', {
            status,
            connectionTest,
            devices,
            userId,
        });
    };

    admit = (userId) => {
        WaitingRoomSocket.emit('waitingRoom:admit', { userId });
        this.setState({ pendingUsers: [...this.state.pendingUsers, userId] });
        setTimeout(() => {
            this.setState({ pendingUsers: this.state.pendingUsers.filter((id) => id !== userId) });
        }, 2000);
    };

    replaceOwner = () => {
        const {
            user: { id: userId },
        } = this.props.userContext;
        WaitingRoomSocket.emit('waitingRoom:replaceOwner', { userId });
    };

    setAwaitingReplacement = (state) => this.setState({ awaitingReplacement: state });

    setEditingName = (userId, value) => {
        this.setState({ editingName: { user: userId, value: value } });
    };

    setName = async (userId, value) => {
        const { waitingRoomState } = this.state;
        const oldUserState = { [userId]: waitingRoomState[userId] };
        oldUserState[userId].displayName = value;

        const oldRoomState = { ...waitingRoomState };
        delete oldRoomState[userId];

        this.setState(
            { editingName: { user: null, value: '' }, waitingRoomState: { ...oldRoomState, ...oldUserState } },
            () => {
                WaitingRoomSocket.emit('waitingRoom:setName', { userId, value });
                VideoSocketController.emit('users:setName', { userId, value });
            },
        );
    };

    kickUser = async (userId) => {
        await WaitingRoomSocket.emit('waitingRoom:kick', { userId });
    };

    listenToEvents = () => {
        WaitingRoomSocket.on('waitingRoom:state', (data) => this.setState({ waitingRoomState: data }));
        WaitingRoomSocket.on('waitingRoom:kicked', () => {
            this.leaveWaitingRoom();
            document.location.href = routes.home.path;
        });
        WaitingRoomSocket.on('waitingRoom:userState', (data) => this.setState({ waitingRoomUser: data }));
        WaitingRoomSocket.on('waitingRoom:finished', () => this.setState({ isFinished: true }));
        WaitingRoomSocket.on('disconnect', () => this.setState({ connectionIssues: true }));
        WaitingRoomSocket.on('reconnect', () => this.setState({ connectionIssues: false }));
        WaitingRoomSocket.on('notifications:ready', ({ notification }) =>
            NotificationsEmitter.emit('notification::new', notification),
        );
        WaitingRoomSocket.on('waitingRoom:replaceOwner', () => {
            localStorage.setItem('replacedInInterview', 'true');
            this.props.onlineInterviewContext.setKicked(true);
        });
        WaitingRoomSocket.on('waitingRoom:ownerGone', () => {
            this.setState({ ownerGone: true });
        });
        WaitingRoomSocket.on('waitingRoom:ownerReconnected', () => {
            this.setState({ ownerGone: false });
        });
        WaitingRoomSocket.on('connect_error', () => this.setState({ connectionError: true }));
        WaitingRoomSocket.on('reconnect_attempt', () => this.setState({ connectionError: true }));
    };

    render() {
        const actions = {
            joinWaitingRoom: this.joinWaitingRoom,
            leaveWaitingRoom: this.leaveWaitingRoom,
            setReady: this.setReady,
            admit: this.admit,
            joinModeratorWaitingRoom: this.joinModeratorWaitingRoom,
            replaceOwner: this.replaceOwner,
            setAwaitingReplacement: this.setAwaitingReplacement,
            setName: this.setName,
            setEditingName: this.setEditingName,
            kickUser: this.kickUser,
        };

        return (
            <WaitingRoomContext.Provider value={{ ...this.state, ...actions }}>
                {this.props.children}
            </WaitingRoomContext.Provider>
        );
    }
}

export default withConsumers(WaitingRoomContextProvider, [
    OnlineInterviewContextConsumer,
    InterviewContextConsumer,
    UserContextConsumer,
    InterviewStorageContextConsumer,
]);
