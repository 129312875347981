import { PageLayout } from "../../PageLayout/PageLayout";
import Skeleton from "../../Skeleton";
import styles from "./NewProjectPage.module.scss"

const NewProjectPage = () => {
    return (
        <PageLayout>
            <div className={styles.container}>
                <Skeleton style={{ height: '2.5rem', width: '10rem', display: 'flex' }} />
                <div className={styles.content}>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Skeleton style={{ height: '1rem', width: '3rem' }} />
                            <Skeleton style={{ height: '13rem', width: 'calc(100% - 4rem)' }} />
                        </div>
                        <div className={styles.column}>
                            <Skeleton style={{ height: '1rem', width: '6rem' }} />
                            <Skeleton style={{ height: '3rem', width: 'calc(100%)' }} />
                            <Skeleton style={{ height: '3rem', width: 'calc(100%)' }} />
                        </div>
                    </div>
                    <div className={styles.column}>
                        <Skeleton style={{ height: '1rem', width: '10rem' }} />
                        <Skeleton style={{ height: '200px', width: 'calc(100%)' }} />
                    </div>
                    <div style={{marginTop: '2rem'}} className={styles.column}>
                        <Skeleton style={{ height: '1rem', width: '8rem' }} />
                        <Skeleton style={{ height: '3rem', width: 'calc(100%)', marginTop: '1rem' }} />
                        <Skeleton style={{ height: '200px', width: 'calc(100%)' }} />
                    </div>
                </div>
            </div>
        </PageLayout>
    )
}

export default NewProjectPage;