import React, {createContext, Component} from 'react';
import {createRequestHelper, requestHelperWithInitialState} from 'helpers/requestHelper';
import makeRequest from 'makeRequest';
import {noop} from 'lodash';
import {getToken} from 'helpers/getToken';

export const PromoCodesContext = createContext({});

const createPromoCodeRequestHelper = createRequestHelper('createPromoCode');
const getPromoCodesRequestHelper = requestHelperWithInitialState('getPromoCodes', {list: [], count: 0});
const deletePromoCodeRequestHelper = createRequestHelper('deletePromoCode');
const checkPromoCodeRequestHelper = createRequestHelper('checkPromoCode');

class PromoCodesContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...createPromoCodeRequestHelper.initialState,
            ...getPromoCodesRequestHelper.initialState,
            ...deletePromoCodeRequestHelper.initialState,
            attemptsTimestamp: 0,
        };
    }

    createPromoCode = async (data, cb = noop) => {
        const authToken = getToken();
        const path = 'promoCodes/create';

        this.setState(createPromoCodeRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState(createPromoCodeRequestHelper.result(result));
            cb(null, result);
        } catch (e) {
            this.setState(createPromoCodeRequestHelper.error(JSON.parse(e)));
            cb(JSON.parse(e));
        }
    };

    deletePromoCode = async data => {
        const authToken = getToken();
        const path = 'promoCodes/delete';

        this.setState(deletePromoCodeRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState(deletePromoCodeRequestHelper.result(result));
            return result;
        } catch (e) {
            this.setState(deletePromoCodeRequestHelper.error(e));
            return e;
        }
    };

    getPromoCodes = async data => {
        const authToken = getToken();
        const path = 'promoCodes/get';

        this.setState(getPromoCodesRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState(getPromoCodesRequestHelper.result(result));
            return result;
        } catch (e) {
            this.setState(getPromoCodesRequestHelper.error(e));
            return e;
        }
    };

    checkPromoCode = async (data, cb = noop) => {
        const authToken = getToken();
        const path = 'promoCodes/check';

        this.setState(checkPromoCodeRequestHelper.processing());
        try {
            const result = await makeRequest({path, data: {authToken, ...data}});
            this.setState(checkPromoCodeRequestHelper.result(result));
            cb(null, result);
        } catch (e) {
            this.setState(checkPromoCodeRequestHelper.error(e));
            cb(e);
        }
    };
    clearCheckPromoCode = () => this.setState(checkPromoCodeRequestHelper.clear());

    setAttemptsTimestamp = attemptsTimestamp => this.setState({attemptsTimestamp});

    render() {
        const actions = {
            createPromoCode: this.createPromoCode,
            deletePromoCode: this.deletePromoCode,
            getPromoCodes: this.getPromoCodes,
            checkPromoCode: this.checkPromoCode,
            setAttemptsTimestamp: this.setAttemptsTimestamp,
            clearCheckPromoCode: this.clearCheckPromoCode,
        };

        return (
            <PromoCodesContext.Provider value={{...this.state, ...actions}}>
                {this.props.children}
            </PromoCodesContext.Provider>
        );
    }
}

export default PromoCodesContextProvider;
