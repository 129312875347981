import React from 'react';
import dayjs from 'dayjs';

import { createRequestHelper } from 'helpers/requestHelper';
import methodFactory from 'helpers/methodFactory';
import { capitalizeFirstLetter as capitalize } from './formatter';
import { isAdmin, isCompanyAdmin, isModerator, isRespondent } from 'constants/UserRoles';
import { DISPUTE_STATES } from '../constants/Disputes';
import BusinessMedical from 'constants/UserBusinessMedical';
import { CurrencySigns } from 'constants/Finances';
import CurrencyRow from 'components/common/CurrencyRow';
import { interviewTypesValues } from 'components/Interview/interviewConstants';

export function initDisputesStateAndMethods() {
    const helpers = {
        openDisputeRequestHelper: createRequestHelper('openDispute'),
        getDisputesListRequestHelper: createRequestHelper('disputesList'),
        getDisputesFilterOptionsRequestHelper: createRequestHelper('disputesFilterOptions'),
        getDisputeDetailsRequestHelper: createRequestHelper('getDisputeDetails'),
        cancelDisputeRequestHelper: createRequestHelper('cancelDispute'),
    };

    const defaultDisputesState = {
        ...Object.values(helpers).reduce((acc, el) => ({ ...acc, ...el.initialState }), {}),
    };

    const methods = [
        { name: 'getDisputesList', path: 'disputes/getDisputesList', helper: helpers.getDisputesListRequestHelper },
        {
            name: 'getDisputesFilterOptions',
            path: 'disputes/getFilterOptions',
            helper: helpers.getDisputesFilterOptionsRequestHelper,
        },
        {
            name: 'getDisputeDetails',
            path: 'disputes/getDisputeDetails',
            helper: helpers.getDisputeDetailsRequestHelper,
        },
    ];

    this.factoryActions = {};

    for (let method of methods) {
        let methodVar, clearMethodVar;
        [methodVar, clearMethodVar] = methodFactory.call(this, method);
        const clearMethodName = `clear${capitalize(method.name)}`;

        this[method.name] = methodVar;
        this[clearMethodName] = clearMethodVar;
        this.factoryActions[method.name] = methodVar;
        this.factoryActions[clearMethodName] = clearMethodVar;
    }

    this.state = { ...this.state, ...defaultDisputesState };
}

export const formatInterviewDate = (t, date, dispute) => {
    const isOnlineDiary = dispute ? dispute?.interview?.type === interviewTypesValues.online : false;

    const parsedDate = new Date(date);
    const DMY = dayjs(parsedDate).format('DD MMM YYYY');
    const time = dayjs(parsedDate).format('HH:mm');

    if (isOnlineDiary || dispute?.interview?.endDate) {
        const parsedEndDate = new Date(dispute.interview?.endDate);
        const startDate = dayjs(parsedDate).format('HH:mm DD MMM YYYY');
        const endDate = dayjs(parsedEndDate).format('HH:mm DD MMM YYYY');

        return <div>{`${startDate} - ${endDate}`}</div>;
    }

    return (
        <>
            <div>{DMY}</div>
            <div style={{ opacity: '0.2' }}>{time}</div>
        </>
    );
};

export const formatDisputeDate = (t, date) => {
    const parsedDate = new Date(date);
    return dayjs(parsedDate).format('D MMM, YYYY');
};

export const formatResourceTypeForClient = (t, role, business, medical) => {
    if (!isRespondent(role)) return t(`roles.${role.toLowerCase()}`);

    const isBusiness = business === BusinessMedical.approved;
    const isMedical = medical === BusinessMedical.approved;

    let profileType = '';
    if (!isBusiness && !isMedical) profileType = 'Consumer';
    if (isBusiness) profileType += 'B2B';
    if (isMedical) profileType += isBusiness ? ', Medical' : 'Medical';
    return (
        <>
            <div>Respondent</div>
            <div>({profileType})</div>
        </>
    );
};

export const formatName = (t, firstName, lastName) => {
    return `${firstName} ${lastName}`;
};

export const formatStateForClient = (t, state) => {
    switch (state) {
        case DISPUTE_STATES.waitingClient:
            return <span style={{ color: '#f55a5a' }}>{t('disputes:text.actionRequired')}</span>;
        case DISPUTE_STATES.waitingResource:
        case DISPUTE_STATES.open:
            return t('disputes:text.waitingForResource');
        case DISPUTE_STATES.supportRequired:
            return t('disputes:text.waitingForTeam');
        default:
            return '';
    }
};

export const formatStateForResource = (t, state) => {
    switch (state) {
        case DISPUTE_STATES.waitingClient:
            return t('disputes:text.waitingForClient');
        case DISPUTE_STATES.waitingResource:
        case DISPUTE_STATES.open:
            return <span style={{ color: '#f55a5a' }}>{t('disputes:text.actionRequired')}</span>;
        case DISPUTE_STATES.supportRequired:
            return t('disputes:text.waitingForTeam');
        default:
            return '';
    }
};
export const formatResourceForSuperAdmin = (t, firstName, lastName, role, business, medical) => {
    if (!isRespondent(role)) return `${firstName} ${lastName} (${role})`;

    const isBusiness = business === BusinessMedical.approved;
    const isMedical = medical === BusinessMedical.approved;

    let profileType = '';
    if (!isBusiness && !isMedical) profileType = 'Consumer';
    if (isBusiness) profileType += 'B2B';
    if (isMedical) profileType += isBusiness ? ', Medical' : 'Medical';

    return `${firstName} ${lastName} (${profileType})`;
};

export const formatDetailsDate = (date) => {
    const parsedDate = new Date(date);
    return dayjs(parsedDate).format('D MMM, YYYY');
};

export const getReservedAmountString = (resource) => {
    return `${CurrencySigns[resource.price.currency.toLowerCase()]}${(resource.price.forResource / 100).toFixed(2)}`;
};

export const getDisplayedContractAmountForUser = (user, resource, type = 'open', interview) => {
    const isClientOrAdmin = isCompanyAdmin(user.role) || isAdmin(user.role);
    const isReportRequired = interview.reportType;
    const isReportRewardDispute = type === 'report';
    const course = resource.price.forResource / resource.price.forClient;

    if (isClientOrAdmin && (!isReportRequired || !isModerator(resource.role))) {
        return <CurrencyRow amount={resource.price.forClient / 100} />;
    }

    if (isClientOrAdmin && !isReportRewardDispute && isReportRequired && isModerator(resource.role)) {
        return <CurrencyRow amount={(resource.price.forClient - resource.price.reportPrice / course) / 100} />;
    }

    if (isClientOrAdmin && isReportRewardDispute) {
        return <CurrencyRow amount={resource.price.reportPrice / course / 100} />;
    }

    if (!isClientOrAdmin && isModerator(resource.role) && isReportRequired && isReportRewardDispute) {
        return <CurrencyRow amount={resource.price.reportPrice / 100} />;
    }

    if (!isClientOrAdmin && isModerator(resource.role) && isReportRequired && !isReportRewardDispute) {
        return <CurrencyRow amount={(resource.price.forResource - resource.price.reportPrice) / 100} />;
    }

    return <CurrencyRow amount={resource.price.forResource / course / 100} />;
};
