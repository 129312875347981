// sync interviewTypesValues with Interview/interviewConstants.js
const interviewTypesValues = {
    videoInterview: 'video-interview',
    videoIdi: 'video-idi',
    vfgd: 'vfgd',
    offlineFocusGroup: 'offline-focus-group',
    offlineInterview: 'offline-interview',
    offlineHome: 'offline-home',
    onlineExternal: 'online-external',
    online: 'online',
};
export const steps = {
    interviewType: 'interviewType',
    mainSettings: 'mainSettings',
    additionalSettings: 'additionalSettings',
    dateAndLocation: 'dateAndLocation',
    resourceReminders: 'resourceReminders',
    payment: 'payment',
};

export const interviewFormats = {
    online: 'online',
    offline: 'offline',
};

export const isOnline = (format) => format === interviewFormats.online;
export const isOffline = (format) => format === interviewFormats.offline;

export const formTypes = {
    oneOnOne: 'oneOnOne',
    focusGroup: 'focusGroup',
    homeOffice: 'homeOffice',
    online: 'online',
};

export const defaultImagesPath = `${process.env.REACT_APP_S3ROOT}/interview/default_images/`;
export const defaultImagePath = (imageName) => defaultImagesPath + imageName;
export const defaultInterviewImages = {
    [interviewTypesValues.videoIdi]: defaultImagePath('online_oneToOne.png'),
    [interviewTypesValues.videoInterview]: defaultImagePath('focusGroup.png'),
    [interviewTypesValues.vfgd]: defaultImagePath('focusGroup.png'),
    [interviewTypesValues.online]: defaultImagePath('online_externalHire.png'),
    [interviewTypesValues.offlineInterview]: defaultImagePath('offline_oneToOne.png'),
    [interviewTypesValues.offlineFocusGroup]: defaultImagePath('focusGroup.png'),
    [interviewTypesValues.offlineHome]: defaultImagePath('offline_homeVisit.png'),
};

export const isOneToOne = (type) => type === formTypes.oneOnOne;
export const isFocusGroup = (type) => type === formTypes.focusGroup;
export const isHomeVisit = (type) => type === formTypes.homeOffice;
export const isInOffice = (type) => type === formTypes.oneOnOne;
export const isOnlineCommunity = (type) => type === formTypes.online;

export const interviewTypeToFormType = {
    [interviewTypesValues.videoIdi]: formTypes.oneOnOne,
    [interviewTypesValues.vfgd]: formTypes.focusGroup,
    [interviewTypesValues.offlineFocusGroup]: formTypes.focusGroup,
    [interviewTypesValues.offlineInterview]: formTypes.oneOnOne,
    [interviewTypesValues.offlineHome]: formTypes.homeOffice,
    [interviewTypesValues.online]: formTypes.online,
};

export const defaultHomework = {
    required: false,
    mandatory: false,
    reward: '',
};

export const defaultRecordingTranslation = {
    machine: {
        translation: false,
        transcripts: false,
        proofReadTranscripts: false,
        foreignTranscripts: false,
        proofReadForeignTranscripts: false,
        foreignLanguage: '',
    },
    human: {
        translation: false,
        transcripts: false,
        foreignTranscripts: false,
        foreignLanguage: '',
    },
};

export const defaultTranslation = {
    realTime: {
        translation: false,
        transcripts: false,
        language: '',
    },
    recording: {
        machine: {
            translation: false,
            transcripts: false,
            proofReadTranscripts: false,
            foreignTranscripts: false,
            proofReadForeignTranscripts: false,
            foreignLanguage: '',
        },
        human: {
            translation: false,
            transcripts: false,
            foreignTranscripts: false,
            foreignLanguage: '',
        },
    },
};

// NEW VARS

export const INTERVIEW_TYPE = {
    VIDEO_INTERVIEW: 'video-interview',
    VIDEO_IDI: 'video-idi',
    VFGD: 'vfgd',
    ONLINE_EXTERNAL: 'online-external',
    ONLINE_DIARY: 'online',
    OFFLINE: 'offline-interview',
    OFFLINE_FOCUS: 'offline-focus-group',
    OFFLINE_HOME: 'offline-home',
};

export const RESPONDENTS_TYPE = {
    WITH_RESPONDENTS: 'withRespondents',
    WITHOUT_RESPONDENTS: 'withoutRespondents',
};

export const ONLINE_FORMAT_TIPE = {
    ONE_TO_ONE: formTypes.oneOnOne,
    FOCUS_GROUP: formTypes.focusGroup,
    ONLINE: formTypes.online,
};

export const OFFLINE_FORMAT_TIPE = {
    ONE_TO_ONE: formTypes.oneOnOne,
    FOCUS_GROUP: formTypes.focusGroup,
    HOME_OFICE: formTypes.homeOffice,
};

export const MODERATOR_TYPES = {
    MODERATOR_NEEDED: 'moderatorNeeded',
    TEAM_MEMBER_MODERATOR: 'teamMemberModerator',
    EXTERNAL_MODERATOR: 'externalModerator',
    NOT_REQUIRED: 'notRequired',
};

export const MODERATOR_TYPE_WITHOUT_RECRUITMENT = {
    TEAM_MEMBER_MODERATOR: MODERATOR_TYPES.TEAM_MEMBER_MODERATOR,
};

export const MODERATOR_TYPE_WITH_RECRUITMENT = {
    MODERATOR_NEEDED: MODERATOR_TYPES.MODERATOR_NEEDED,
    TEAM_MEMBER_MODERATOR: MODERATOR_TYPES.TEAM_MEMBER_MODERATOR,
};

export const INTERPRETER_TYPE_WITHOUT_RECRUITMENT = {
    INVITE_INTERPRETER: 'inviteInterpreter',
    NO_INTERPRETATION_NEEDED: 'noInterpretationNeeded',
};

export const INTERPRETER_TYPE_WITH_RECRUITMENT = {
    HIRE_INTERPRETER: 'hireInterpreter',
    NO_INTERPRETATION_NEEDED: 'noInterpretationNeeded',
};

export const TRANSCRIPTION_TYPE = {
    HUMAN_TRANSCRIPT: 'humanTranscript',
    MACHINE_TRANSCRIPT: 'machineTranscript',
    NO_TRANSCRIPT: 'noTranscript',
};

export const RECORDING_TRANSCRIPTION_TYPE = {
    MACHINE: 'machine',
    HUMAN: 'human',
};

export const FORM_STEPS = {
    interviewType: 'interviewType',
    mainSettings: 'mainSettings',
    numberOfSession: 'numberOfSession',
    sessionDetails: 'sessionDetails',
    participantsRecruitment: 'participantsRecruitment',
    onlineDiary: 'onlineDiary',
    resourceReminders: 'resourceReminders',
    moderator: 'moderator',
    interpreter: 'interpreter',
    transcript: 'transcript',
    advertisement: 'advertisement',
    location: 'location',
    payment: 'payment',
};

export const MODER_HIRING_REPORT_TYPE = {
    SUMMARY_REPORT: 'summaryReport',
    FULL_REPORT: 'fullReport',
    NOT_REQUIRED: '',
};

export const MODER_HIRING_SCREANERS_TYPE = {
    MY_SCREANER: 'myScreeners',
    ORDER_SCREANER: 'orderScreener',
    NOT_REQUIRED: 'notRequired',
};

export const SCREANERS_TYPE = {
    MY_SCREANER: 'myScreeners',
    //TODO: Hidden temporarily
    // ORDER_SCREANER: 'orderScreener',
    NOT_REQUIRED: 'notRequired',
};

export const ADVERTISMENT_TYPE = {
    PUBLIC: 'public',
    PRIVATE: 'private',
};

export const MAX_SMS_TEXT_LENGTH = 320;

export const CONFIRM_EMAIL_REQUIRED_VARS = {
    [interviewTypesValues.offlineFocusGroup]: ['{first_name}', '{interview_title}'],
    [interviewTypesValues.offlineInterview]: ['{first_name}', '{interview_title}'],
    [interviewTypesValues.offlineHome]: ['{first_name}', '{interview_title}'],
    [interviewTypesValues.vfgd]: [
        '{first_name}',
        '{duration}',
        '{interview_title}',
        '{interview_link}',
        '{date}',
        '{time_zone}',
    ],
    [interviewTypesValues.onlineExternal]: ['{first_name}', '{interview_title}'],
    [interviewTypesValues.online]: ['{first_name}', '{interview_title}'],
    [interviewTypesValues.videoIdi]: [
        '{first_name}',
        '{duration}',
        '{interview_title}',
        '{interview_link}',
        '{date}',
        '{time_zone}',
    ],
};

export const CONFIRM_SMS_REQUIRED_VARS = {
    [interviewTypesValues.offlineFocusGroup]: ['{interview_link}', '{date}', '{time}', '{interview_title}'],
    [interviewTypesValues.offlineInterview]: ['{interview_link}', '{date}', '{time}', '{interview_title}'],
    [interviewTypesValues.offlineHome]: ['{interview_link}', '{date}', '{time}', '{interview_title}'],
    [interviewTypesValues.vfgd]: ['{interview_link}', '{date}', '{time}', '{time_zone}', '{interview_title}'],
    [interviewTypesValues.onlineExternal]: ['{interview_link}', '{date}', '{time}', '{interview_title}'],
    [interviewTypesValues.online]: ['{interview_link}', '{date}', '{time}', '{interview_title}'],
    [interviewTypesValues.videoIdi]: ['{interview_link}', '{date}', '{time}', '{time_zone}', '{interview_title}'],
};
