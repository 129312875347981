import React, { createContext, Component } from 'react';
import { noop } from 'lodash';

import { UserContextConsumer } from './UserContext';
import { getChatSignalPath, getChatSignalURL } from 'components/common/apiPrefix';
import ChatSocketController from 'controllers/ChatSocket';
import BackendSocketController from 'controllers/BackendSocket';

import { getToken } from 'helpers/getToken';

export const ChatContext = createContext({});

const initialState = {
    comeFromHeaderIcon: true,
    allUnreadMessages: null,
    dialogs: null,
    openedDialog: {
        contact: null,
        dialog: null,
        messages: null,
        newMessageForScroll: null,
        members: [],
        projectId: null,
    },
    projects: [],
    activeProjectId: null,
};

class ChatContextProvider extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;
    }

    componentDidMount() {
        this.joinChat();
    }

    componentWillUnmount() {
        ChatSocketController.disconnect();
    }

    asyncSetState = async (state) => new Promise((resolve) => this.setState(state, resolve));

    setComeFromHeaderIcon = async (data) => await this.asyncSetState({ comeFromHeaderIcon: data });

    listenEvents() {
        ChatSocketController.on(
            'chat::newData',
            async ({ dialog: newOrUpdatedDialog, message: newMessage, filteredProjects }) => {
                const {
                    dialogs,
                    openedDialog,
                    openedDialog: { dialog, messages, members, projectId },
                    projects,
                } = this.state;
                const needToUpdateDialog = dialog && (dialog.id === newOrUpdatedDialog.id || dialog.new);
                const needToUpdateMessages = dialog && (dialog.id === newMessage.dialogId || dialog.new);
                const dialogsToArray = dialogs || [];
                const messagesToArray = messages || [];
                const isThisProject = newOrUpdatedDialog.projectId === projectId;
                const isCompanyAdmin = this.props.userContext.user.role === 'CompanyAdmin';

                const sortedDialogs = [
                    newOrUpdatedDialog,
                    ...dialogsToArray.filter((dialog) => dialog.id !== newOrUpdatedDialog.id),
                ];

                const unsortedDialogs = [...dialogsToArray];
                const dialogToReplace = unsortedDialogs.find((d) => d.id === newOrUpdatedDialog.id);
                const dialogToReplaceIndex = unsortedDialogs.indexOf(dialogToReplace);
                if (dialogToReplaceIndex !== -1) {
                    unsortedDialogs[dialogToReplaceIndex] = newOrUpdatedDialog;
                }

                const newStateDialogs = !isThisProject && isCompanyAdmin ? unsortedDialogs : sortedDialogs;
                const resultDialogs = newStateDialogs.length ? newStateDialogs : dialogs;
                await this.asyncSetState({
                    dialogs: resultDialogs,
                    openedDialog: {
                        ...openedDialog,
                        ...(needToUpdateDialog && { dialog: newOrUpdatedDialog }),
                        ...(needToUpdateMessages && {
                            messages: [...messagesToArray, newMessage],
                            newMessageForScroll: newMessage,
                        }),
                    },
                });
                await this.countAllUnreadMessages();
                await this.loadProjects();
                const needToReadDialog =
                    needToUpdateDialog && needToUpdateMessages && this.props.userContext.user.id !== newMessage.from;
                needToReadDialog &&
                    (await this.readDialogAndNotifyContact({
                        dialogId: newOrUpdatedDialog.id,
                        userId: this.props.userContext.user.id,
                    }));
            },
        );

        ChatSocketController.on('chat::newProject', async (newProject) => {
            const { projects } = this.state;
            await this.asyncSetState({
                projects: newProject ? [newProject, ...projects] : projects,
            });
        });

        ChatSocketController.on('chat::userConnected', (connectedUserId) => {
            if (!this.state.openedDialog) return;
            const {
                openedDialog,
                openedDialog: { dialog },
            } = this.state;
            if (!dialog) return;
            const {
                user: { id: openedDialogUserId },
            } = dialog;

            if (openedDialogUserId === connectedUserId) {
                this.setState({
                    openedDialog: {
                        ...openedDialog,
                        dialog: {
                            ...dialog,
                            isOnline: true,
                        },
                    },
                });
            }
        });
        ChatSocketController.on('chat::updateMembers', (members) => {
            const {
                openedDialog,
                openedDialog: { dialog },
            } = this.state;
            this.setState({
                openedDialog: {
                    ...openedDialog,
                    members: members,
                },
            });
        });
        ChatSocketController.on('chat::userDisconnected', (disconnectedUserId) => {
            if (!this.state.openedDialog) return;
            const {
                openedDialog,
                openedDialog: { dialog },
            } = this.state;
            if (!dialog) return;
            const {
                user: { id: openedDialogUserId },
            } = dialog;

            if (openedDialogUserId === disconnectedUserId) {
                this.setState({
                    openedDialog: {
                        ...openedDialog,
                        dialog: {
                            ...dialog,
                            isOnline: false,
                        },
                    },
                });
            }
        });

        ChatSocketController.on('chat::userReadDialog', async ({ dialogId, userId }) => {
            const {
                dialogs,
                openedDialog,
                openedDialog: { dialog, messages },
            } = this.state;
            if (!dialog || dialog.id !== dialogId) return;
            await this.asyncSetState({
                dialogs: dialogs.map((dialog) =>
                    dialog.id === dialogId ? { ...dialog, unreadMessageCount: 0 } : dialog,
                ),
                openedDialog: {
                    ...openedDialog,
                    messages: messages.map((message) => ({ ...message, isUnread: false })),
                },
            });
            await this.countAllUnreadMessages();
        });

        ChatSocketController.on('reconnect', async () => {
            await this.registerUser();
        });

        BackendSocketController.on('chat::chatUnavailable', (contactId) => {
            const { dialogs, openedDialog } = this.state;
            if (!openedDialog.dialog || !openedDialog.contact) return;

            const newDialogs =
                dialogs &&
                dialogs.map((dialog) =>
                    dialog.members.includes(contactId) ? { ...dialog, available: false } : dialog,
                );
            const newOpenedDialog = {
                ...openedDialog,
                dialog: {
                    ...openedDialog.dialog,
                    available: openedDialog.contact.id !== contactId,
                },
            };

            this.setState({
                dialogs: newDialogs,
                openedDialog: newOpenedDialog,
            });
        });

        BackendSocketController.on('chat::chatAvailable', (contactId) => {
            const { dialogs, openedDialog } = this.state;
            if (!openedDialog.dialog || !openedDialog.contact) return;

            const newDialogs =
                dialogs &&
                dialogs.map((dialog) => (dialog.members.includes(contactId) ? { ...dialog, available: true } : dialog));
            const newOpenedDialog = {
                ...openedDialog,
                dialog: {
                    ...openedDialog.dialog,
                    available: openedDialog.contact.id === contactId,
                },
            };

            this.setState({
                dialogs: newDialogs,
                openedDialog: newOpenedDialog,
            });
        });
    }

    joinChat = async () => {
        const authToken = getToken();

        try {
            await ChatSocketController.connect(getChatSignalURL(), { token: authToken }, getChatSignalPath());
            await this.registerUser();
            this.listenEvents();
            this.loadDialogs({});

            this.setState({
                isConnected: true,
            });
        } catch (e) {
            console.log(e);
        }
    };

    registerUser = async () => {
        const method = 'chat::registerUser';
        const data = { userId: this.props.userContext.user.id };
        await ChatSocketController.emit(method, data);
    };

    getReceiversId = () => {
        const {
            openedDialog: { members },
        } = this.state;
        const { id: authUserId } = this.props.userContext.user;
        return members.filter((user) => user.id !== authUserId).map((m) => m.id);
    };

    //Client or ProjectMember can get dialogs only if project selected
    loadClientDialogs = async ({ projectId }) => {
        const method = 'chat::loadDialogs';
        await this.loadDialogs({ projectId, method });
        //TODO: logic for count unreads
    };

    loadDialogs = async ({ projectId } = {}) => {
        try {
            if (!this.props.userContext.user.team) {
                return;
            }
            const method = 'chat::loadDialogs';
            const authToken = getToken();
            const data = {
                authToken,
                userId: this.props.userContext.user.id,
                projectId,
                teamRole: this.props.userContext.user.team.role,
                teamId: this.props.userContext.user.team._id,
                role: this.props.userContext.user.role,
            };
            const {
                response: { dialogs },
            } = await ChatSocketController.emit(method, data);
            await this.asyncSetState({ dialogs });
            await this.countAllUnreadMessages();
        } catch (err) {
            console.log(err);
        }
    };

    loadProjects = async () => {
        try {
            const method = 'chat::loadProjects';
            const authToken = getToken();
            const data = {
                authToken,
                userIdMember: this.props.userContext.user.id,
                teamRole: this.props.userContext.user.team.role,
                teamId: this.props.userContext.user.team._id,
                role: this.props.userContext.user.role,
            };
            const {
                response: { projects },
            } = await ChatSocketController.emit(method, data);
            await this.asyncSetState({ projects });
        } catch (err) {
            console.log(err);
        }
    };

    toggleNotification = async (dialogId) => {
        try {
            const {
                openedDialog: { dialog },
            } = this.state;
            const userId = this.props.userContext.user.id;
            const method = 'chat::toggleNotification';
            const data = { dialogId, userId };

            const {
                response: { status, newAllowNotifyUsers },
            } = await ChatSocketController.emit(method, data);

            if (status && dialog) {
                await this.asyncSetState({
                    openedDialog: {
                        ...this.state.openedDialog,
                        dialog: { ...dialog, allowNotify: newAllowNotifyUsers },
                    },
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    setProjects = (newProjects) => this.setState({ projects: newProjects });
    setActiveProject = (activeProjectId) => this.setState({ activeProjectId });

    clearChat = async () => this.setState(initialState);
    clearOpenedDialog = () => this.setState({ openedDialog: initialState.openedDialog });

    openDialog = async (contactId, fromProfile, projectId) => {
        const method = 'chat::openDialog';
        const data = {
            userId: this.props.userContext.user.id,
            projectId,
            contactId,
            teamRole: this.props.userContext.user.team.role,
            teamId: this.props.userContext.user.team._id,
            role: this.props.userContext.user.role,
        };
        try {
            const {
                response: { user: contact, dialog, messages, members },
            } = await ChatSocketController.emit(method, data);
            BackendSocketController.emit('chat::checkIfCanChat', {
                contactId,
                projectId,
                userId: this.props.userContext.user.id,
                role: this.props.userContext.user.role,
            });
            const { dialogs } = this.state;
            dialog.members = members;
            this.setState({
                ...(fromProfile && { comeFromHeaderIcon: false }),
                dialogs:
                    dialogs &&
                    dialogs.map((dialogElement) => (dialogElement.id === dialog.id ? dialog : dialogElement)),
                openedDialog: {
                    contact,
                    dialog,
                    messages,
                    members,
                    projectId,
                },
            });
        } catch (err) {
            console.log(err);
        }
    };

    sendMessage = async ({ contactId, receiverIds, dialogId, message, file, echo, projectId }) => {
        const method = 'chat::sendMessage';
        const data = {
            userId: this.props.userContext.user.id,
            receiverIds,
            dialogId,
            message,
            file,
            echo,
            projectId,
            contactId,
            authToken: getToken(),
            teamId: this.props.userContext.user.team._id,
        };

        try {
            await ChatSocketController.emit(method, data);
            await this.loadProjects();
        } catch (err) {
            console.log(err);
        }
    };

    onFileReady = (fileId, path) => {
        const {
            openedDialog,
            openedDialog: { messages },
        } = this.state;
        if (!messages) return;

        const updatedMessages = messages.map((message) => {
            if (message.file && message.file.id === fileId) {
                message.file.path = path;
            }
            return message;
        });

        this.setState({
            openedDialog: {
                ...openedDialog,
                messages: updatedMessages,
            },
        });
    };

    addCreatedMessage = (createdMessage, cb = noop) => {
        const {
            openedDialog,
            openedDialog: { messages },
        } = this.state;

        this.setState(
            {
                openedDialog: {
                    ...openedDialog,
                    messages: [...messages, createdMessage],
                },
            },
            () => cb(),
        );
    };

    readDialogAndNotifyContact = async ({ dialogId, userId }) => {
        const method = 'chat::readDialogAndNotifyContact';
        const data = { dialogId, userId };
        await ChatSocketController.emit(method, data);
    };

    countAllUnreadMessages = async () => {
        const { dialogs } = this.state;
        const allUnreadMessages =
            (dialogs && dialogs.reduce((accumulator, current) => accumulator + current.unreadMessageCount, 0)) || 0;
        await this.asyncSetState({ allUnreadMessages });
    };

    render() {
        const actions = {
            joinChat: this.joinChat,
            loadDialogs: this.loadDialogs,
            loadProjects: this.loadProjects,
            clearChat: this.clearChat,
            openDialog: this.openDialog,
            sendMessage: this.sendMessage,
            addCreatedMessage: this.addCreatedMessage,
            setComeFromHeaderIcon: this.setComeFromHeaderIcon,
            onFileReady: this.onFileReady,
            clearOpenedDialog: this.clearOpenedDialog,
            setActiveProject: this.setActiveProject,
            setProjects: this.setProjects,
            getReceiversId: this.getReceiversId,
            toggleNotification: this.toggleNotification,
            createDialog: this.createDialog,
        };

        return <ChatContext.Provider value={{ ...this.state, ...actions }}>{this.props.children}</ChatContext.Provider>;
    }
}

ChatContextProvider.displayName = 'ChatContextProvider';

export default UserContextConsumer(ChatContextProvider);

export const ChatContextConsumer = function(WrappedComponent) {
    return function(props) {
        return (
            <ChatContext.Consumer>
                {(context) => <WrappedComponent chatContext={context} {...props} />}
            </ChatContext.Consumer>
        );
    };
};
