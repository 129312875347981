import { getApiPrefix } from 'components/common/apiPrefix';

const uploadFile = ({ path, data }, cb) => {
    const fullPath = `${getApiPrefix()}/${path}`;

    const params = {
        method: 'POST',
        body: data,
    };

    return fetch(fullPath, params)
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                cb && cb(res.error);
                throw res.error;
            }

            cb && cb(null, res);

            return res;
        });
};

export default uploadFile;
